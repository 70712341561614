import { useContext } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { theme } from '../styles';

import { App } from './App';
import { useColorModeStorage } from './hooks';
import { AddRelease } from './pages/AddRelease';
import { AddTrack } from './pages/AddTrack';
import { Home } from './pages/Home';
import { PageNotFound } from './pages/PageNotFound';
import { Release } from './pages/Release';
import { Track } from './pages/Track';
import { EnvVarsContext } from './providers';
import { isDevOrLocalEnv } from './utils/isDevOrLocalEnv';

const createRouter = (environment: string) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="/tracks" element={<Track />} />
        <Route path="/tracks/add" element={<AddTrack />} />
        {/* Releases are not ready on the BE-side so we hide it for now */}
        {/* See more https://utopia-music.atlassian.net/browse/RMS-372 */}
        {isDevOrLocalEnv(environment) && (
          <>
            <Route path="/releases" element={<Release />} />
            <Route path="/releases/add" element={<AddRelease />} />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    )
  );

export const AppRoutes = () => {
  const {
    variables: { repertoireEnvironment },
  } = useContext(EnvVarsContext);
  const colorModeManager = useColorModeStorage();
  return (
    <ChakraProvider resetCSS theme={theme} colorModeManager={colorModeManager}>
      <RouterProvider router={createRouter(repertoireEnvironment)} />
    </ChakraProvider>
  );
};
