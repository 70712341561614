import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReleaseFooter } from '../../components';
import { StepHeading } from '../../components/StepHeading';
import { useReleaseForm } from '../../context/ReleaseWizardContext';

import { FieldDiscs } from './FieldDiscs';
import { ReleaseTracks } from './types';

export const Step3 = ({ goToStep }: { goToStep: React.Dispatch<React.SetStateAction<number>> }) => {
  const {
    step3,
    step1: {
      genre: genreFromStep1,
      subGenre: subGenreFromStep1,
      language: languageFromStep1,
      artistDetails: artistDetailFromStep1,
      primaryArtist: primaryArtistFromStep1,
      variousArtist: variousArtistFromStep1,
    },
  } = useReleaseForm();

  // if isrc exist then user is coming back so set values which are saved
  // if not then insert 1 track as default value
  const defaultValues = step3.discs[0].tracks[0]?.isrc
    ? { ...step3 }
    : {
        discs: [
          {
            tracks: [
              {
                instrumental: false,
                reRecord: false,
                titleLanguages: [{ language: languageFromStep1 || '', titleVersion: '', trackTitle: '' }],
                genre: genreFromStep1 || '',
                subGenre: subGenreFromStep1 || '',
                pLine: '',
                pLineOwner: '',
                pLinePercentage: '',
                ownershipType: '',
                isrc: '',
                artistDetails: variousArtistFromStep1 ? [] : [...artistDetailFromStep1],
                primaryArtist: variousArtistFromStep1 ? '' : primaryArtistFromStep1,
                primaryArtistRole: 'MAIN_ARTIST',
              },
            ],
          },
        ],
      };

  const methods = useForm<ReleaseTracks>({ defaultValues, mode: 'all' });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isUpdateMode = Boolean(searchParams.get('updateMode'));

  const { saveStepInformation } = useReleaseForm();
  const onSubmit = (data: ReleaseTracks) => {
    saveStepInformation('step3', data);
    if (isUpdateMode) {
      navigate({
        search: '',
      });
    }
    goToStep(3);
  };

  return (
    <>
      <StepHeading text="Tracks" />

      <FormProvider {...methods}>
        {/* eslint-disable-next-line no-void */}
        <form onSubmit={(event) => void methods.handleSubmit(onSubmit)(event)} noValidate>
          <FieldDiscs />
          <Box>
            <ReleaseFooter isDirty={methods.formState.isDirty} goToStep={goToStep} handleBack={() => goToStep(1)} />
          </Box>
        </form>
      </FormProvider>
    </>
  );
};
