import { Skeleton } from '@chakra-ui/react';

export interface TracksSkeletonProps {
  rows?: number;
}

export const TracksSkeleton = (props: TracksSkeletonProps) => {
  const { rows = 10 } = props;
  return (
    <>
      {/* No margin-top for the first one */}
      <Skeleton height="53px" />
      {Array(rows - 1)
        .fill('')
        .map((_, i) => {
          return <Skeleton key={i} height="52px" mt="1px" />;
        })}
    </>
  );
};
