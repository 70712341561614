import { Catalog } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/catalog_pb';
import { ListCatalogsResponse } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/catalog_service_pb';
import { CatalogService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/catalog_service_connect';
import { PromiseClient } from '@connectrpc/connect';

import { CatalogDto } from '../../../types';
import { getDefaultCatalog } from '../../utils/getDefaultCatalog';

export const getCatalog = async (
  token: string,
  tenantId: string,
  catalogClient?: PromiseClient<typeof CatalogService>
): Promise<CatalogDto> => {
  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  const headers = new Headers();
  if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);
  const catalogsData: ListCatalogsResponse | undefined = await catalogClient?.listCatalogs(
    { parent: tenantId },
    { headers }
  );
  const { catalogs = [] } = catalogsData ?? {};
  const { name: id, displayName: name }: Catalog = getDefaultCatalog(catalogs);
  return {
    id,
    name,
  };
};
