import { Box, Button, Flex, Icon, IconButton, Text, useColorModeValue } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'remixicon/icons/System/add-line.svg';
import { ReactComponent as CloseIcon } from 'remixicon/icons/System/close-line.svg';

import { FieldTracks } from './FieldTracks';
import { ReleaseTracks } from './types';

export const FieldDiscs = () => {
  const { control } = useFormContext<ReleaseTracks>();
  const { fields: fieldsDiscs, append: appendDiscs, remove: removeDiscs } = useFieldArray({ name: 'discs', control });
  const textColor = useColorModeValue('neutral.600', 'neutral.300');

  return (
    <Box marginTop={8}>
      {fieldsDiscs.map((disc, index) => (
        <Box key={disc.id} marginBottom={6}>
          <Flex pb={2}>
            <Text textTransform="uppercase" color={textColor} marginRight={2}>
              Disc {index + 1}
            </Text>
            {index >= 1 && (
              <IconButton
                aria-label="Delete"
                variant="unstyled"
                icon={<CloseIcon fill="currentcolor" height="18px" width="18px" />}
                boxSize={6}
                onClick={() => removeDiscs(index)}
              />
            )}
          </Flex>
          <Box
            borderWidth="1px"
            borderStyle="dashed"
            borderColor="gray.500"
            p="4"
            display="flex"
            flexDirection="column"
          >
            <FieldTracks discIndex={index} key={index} />
          </Box>
        </Box>
      ))}
      <Button
        variant="ghost"
        colorScheme="blue"
        size="sm"
        leftIcon={<Icon as={AddIcon} fill="currentcolor" />}
        onClick={() => {
          appendDiscs({
            tracks: [
              {
                titleLanguages: [{ titleVersion: '', trackTitle: '', language: '' }],
                instrumental: false,
                reRecord: false,
                genre: '',
                subGenre: '',
                isrc: '',
                pLine: '',
                pLineOwner: '',
                pLinePercentage: '',
                ownershipType: '',
                territories: [],
                artistDetails: [{ artist: '', artistRole: '' }],
                primaryArtist: '',
                primaryArtistRole: '',
              },
            ],
          });
        }}
      >
        Add a disc
      </Button>
    </Box>
  );
};
