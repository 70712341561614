import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  FormErrorMessage,
  HStack,
  Switch,
} from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Territories, TerritoryGroup } from '../../../types';
import { MultiTerritorySelect } from '../../components/MultiTerritorySelect/MultiTerritorySelect';
import { ReleaseFooter } from '../../components/ReleaseFooter';
import { StepHeading } from '../../components/StepHeading';
import { useReleaseForm } from '../../context/ReleaseWizardContext';
import { getTerritories } from '../../services';

import { ReleaseInformation } from './types';

export const Step2 = ({ goToStep }: { goToStep: React.Dispatch<React.SetStateAction<number>> }) => {
  const { step2 } = useReleaseForm();
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
  } = useForm<ReleaseInformation>({ defaultValues: { ...step2 }, mode: 'onBlur' });
  const [territoriesAvailable, setTerritoriesAvailable] = useState<Territories>({});
  const { territories: territoriesSelectedDefault, rerelease } = getValues();
  const [showRerelease, setShowRerelease] = useState<boolean>(rerelease);
  const [territoriesSelected, setTerritoriesSelected] = useState<Set<string>>(
    new Set<string>(territoriesSelectedDefault)
  );
  const fetchTerritories = useCallback(async () => {
    const territoriesNew = await getTerritories();
    setTerritoriesAvailable(territoriesNew);
  }, []);

  useEffect(() => {
    fetchTerritories().catch((error: Error) => {
      datadogRum.addError(error);
    });
  }, [fetchTerritories]);

  const [territoryToCountry, setTerritoryToCountry] = useState<Record<TerritoryGroup, Array<string>>>();
  useEffect(() => {
    const territoryToCountryNew = Object.keys(territoriesAvailable).reduce((acc, ter) => {
      const countriesLabels = territoriesAvailable[ter as TerritoryGroup].map((count) => {
        return count.text;
      });
      return {
        ...acc,
        [ter]: [...countriesLabels],
      };
    }, {});
    setTerritoryToCountry(territoryToCountryNew as Record<TerritoryGroup, Array<string>>);
  }, [territoriesAvailable]);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isUpdateMode = Boolean(searchParams.get('updateMode'));

  const { saveStepInformation } = useReleaseForm();

  const onSubmit = (data: ReleaseInformation) => {
    saveStepInformation('step2', {
      ...data,
      // The territories data is not a part of the React Hook Form API
      territories: Array.from(territoriesSelected),
      // if rerelease then set original release date else set empty value
      originalReleaseDate: data.rerelease ? data.originalReleaseDate : '',
    });
    // when update mode then empty query params
    if (isUpdateMode) {
      navigate({
        search: '',
      });
    }

    goToStep(isUpdateMode ? 3 : 2);
  };

  const handleBack = () => {
    saveStepInformation('step2', {
      ...getValues(),
      // The territories data is not a part of the React Hook Form API
      territories: Array.from(territoriesSelected),
    });
    goToStep(0);
  };

  const onRereleaseChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setShowRerelease(checked);
  }, []);

  return (
    <>
      <StepHeading text="Release" />
      <Box mt="20px">
        {/* eslint-disable-next-line no-void */}
        <form onSubmit={(event) => void handleSubmit(onSubmit)(event)} noValidate>
          <Box display="flex" flexDirection="column">
            <Grid templateColumns="repeat(5, 25%)" gap={3}>
              <GridItem colSpan={4}>
                <HStack spacing="6px">
                  <Box marginBottom="10px">
                    <Switch
                      {...register('rerelease')}
                      colorScheme="blue"
                      id="rerelease"
                      size="lg"
                      onChange={onRereleaseChange}
                    />
                  </Box>
                  <Box>
                    <FormLabel htmlFor="rerelease">Re-release</FormLabel>
                  </Box>
                </HStack>
              </GridItem>
              <GridItem gridColumn={1}>
                <FormControl isRequired isInvalid={Boolean(errors?.releaseDate)}>
                  {/* SOMEDAY: Fix the a11y violations: labels and inputs must be linked by the id/htmlFor attrs */}
                  {/* It's supposed to be done for all stepper components */}
                  <FormLabel fontSize="16px">Release Date</FormLabel>
                  <Input
                    placeholder="Date this product came out"
                    // TODO: Probably we wanna use a third-party solution for managing dates
                    type="date"
                    {...register('releaseDate', {
                      required: 'Please select Release Date',
                    })}
                    min="1000-01-01"
                    max="2999-12-31"
                  />
                  {errors.releaseDate && <FormErrorMessage>{errors.releaseDate.message}</FormErrorMessage>}
                </FormControl>
              </GridItem>
              {showRerelease && (
                <GridItem gridColumn={2}>
                  <FormControl>
                    <FormLabel fontSize="16px">Original Release Date</FormLabel>
                    <Input
                      placeholder="If re-release, date that the original came out"
                      // TODO: Probably we wanna use a third-party solution for managing dates
                      type="date"
                      {...register('originalReleaseDate', {})}
                      min="1000-01-01"
                      max="2999-12-31"
                    />
                    {errors.originalReleaseDate && (
                      <FormErrorMessage>{errors.originalReleaseDate.message}</FormErrorMessage>
                    )}
                  </FormControl>
                </GridItem>
              )}
              <GridItem gridRow={3} colSpan={2}>
                <FormControl>
                  <FormLabel fontSize="16px">P Line</FormLabel>
                  <Input
                    placeholder="Year, owners"
                    {...register('pLine', {
                      maxLength: { value: 1000, message: 'PLine should not be greater than 1000 characters' },
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem gridRow={3} colSpan={2}>
                <FormControl>
                  <FormLabel fontSize="16px">C Line</FormLabel>
                  <Input
                    placeholder="Year, owners"
                    {...register('cLine', {
                      maxLength: { value: 1000, message: 'CLine should not be greater than 1000 characters' },
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem gridColumn={1} gridColumnEnd={5}>
                <FormControl>
                  <Text fontSize="16px">Territories</Text>
                  <MultiTerritorySelect
                    territories={territoriesAvailable}
                    territoryToCountry={territoryToCountry}
                    countriesIdsSelected={territoriesSelected}
                    setCountriesIdsSelected={setTerritoriesSelected}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <ReleaseFooter handleBack={handleBack} isDirty={isDirty} goToStep={goToStep} />
          </Box>
        </form>
      </Box>
    </>
  );
};
