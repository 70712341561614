import { useCallback, useContext } from 'react';

import { SuggestionArtist, SuggestionTrack } from '../../../types';
import { BuffContext, BuffContextValue, useAuth, useTenantContext } from '../../providers';
import { useErrorHandler } from '../useErrorHandler';

export const useSearch = () => {
  const auth = useAuth();
  const { trackClient } = useContext<BuffContextValue>(BuffContext);
  const { tenantName } = useTenantContext();
  const { handleInternalServerError } = useErrorHandler();

  const searchTracks = useCallback(
    async (searchValue: string): Promise<SuggestionTrack[]> => {
      if (searchValue === '') {
        return Promise.resolve([]);
      }

      // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
      const headers = new Headers();
      if (auth.legacyGetActiveAccessToken) {
        const token = await auth.legacyGetActiveAccessToken();
        headers.set('Authorization', `Bearer ${token}`);
      }
      const searchTrackRequest = {
        parent: tenantName,
        pageSize: 5,
        filter: `isrcs: "${searchValue.replaceAll('"', '')}" OR search: "${searchValue.replaceAll('"', '')}"`,
      };

      const searchResult = await trackClient?.searchTracks(searchTrackRequest, { headers }).catch((err) => {
        handleInternalServerError(err, 'Something went wrong', 'Failed to search tracks', searchTrackRequest);
      });

      if (searchResult) {
        const tracks = searchResult.tracks.map((track) => {
          return {
            trackTitle: track.displayName,
            artistName: track.contributors[0].displayName,
            name: track.name,
            isrc: track.isrcs[0],
          };
        });

        return tracks;
      }

      return [];
    },
    [auth, tenantName, trackClient, handleInternalServerError]
  );

  const searchArtists = useCallback(
    async (searchValue: string): Promise<SuggestionArtist[]> => {
      if (searchValue === '') {
        return Promise.resolve([]);
      }

      // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
      const headers = new Headers();
      if (auth.legacyGetActiveAccessToken) {
        const token = await auth.legacyGetActiveAccessToken();
        headers.set('Authorization', `Bearer ${token}`);
      }

      const searchArtistRequest = {
        parent: tenantName,
        pageSize: 5,
        filter: `display_name: "${searchValue.replaceAll('"', '')}" AND role=MAIN_ARTIST`,
      };
      const searchResult = await trackClient?.searchTrackContributors(searchArtistRequest, { headers }).catch((err) => {
        handleInternalServerError(err, 'Something went wrong', 'Failed to fetch artist', searchArtistRequest);
      });

      if (searchResult) {
        const artists = searchResult.contributors.map((contributor) => {
          return { artistName: contributor.displayName };
        });

        return artists;
      }

      return [];
    },
    [auth, tenantName, trackClient, handleInternalServerError]
  );

  return {
    searchTracks,
    searchArtists,
  };
};
