import { useCallback, useRef } from 'react';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const CancelWithConfirmation = ({ variant }: { variant: 'release' | 'track' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    onClose();
    navigate(variant === 'release' ? '/releases' : '/tracks');
  }, [navigate, onClose, variant]);

  return (
    <>
      <Button onClick={onOpen}>Cancel</Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="18px">Are you sure you want to cancel?</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody fontSize="16px">
              {variant === 'release'
                ? 'If you proceed, this release will be deleted'
                : 'If you proceed, this track will be deleted'}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button w="100%" ref={cancelRef} onClick={onClose} backgroundColor="neutral.600" color="neutral.200">
                Go back
              </Button>
              <Button size="md" w="100%" backgroundColor="red.400" color="neutral.900" onClick={goBack} ml={4}>
                {variant === 'release' ? 'Delete Release ' : 'Delete Track'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
