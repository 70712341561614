import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Select,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'remixicon/icons/System/add-line.svg';
import { ReactComponent as CloseLine } from 'remixicon/icons/System/close-line.svg';

import { ReleaseTracks } from './types';

export const FieldArtist = ({ discIndex, trackIndex }: { discIndex: number; trackIndex: number }) => {
  const {
    control,
    register,
    formState: { errors },
    getValues,
  } = useFormContext<ReleaseTracks>();
  const {
    fields,
    append: addArtist,
    remove: removeArtist,
  } = useFieldArray({
    control,
    name: `discs.${discIndex}.tracks.${trackIndex}.artistDetails`,
  });

  const primaryArtistValue = getValues(`discs.${discIndex}.tracks.${trackIndex}.primaryArtist`);

  return (
    <>
      {primaryArtistValue && (
        <Grid templateColumns="1fr 1fr 4%" gap={3} marginTop="16px">
          <GridItem>
            <FormControl>
              <Input {...register(`discs.${discIndex}.tracks.${trackIndex}.primaryArtist`)} isDisabled />
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <Select
                {...register(`discs.${discIndex}.tracks.${trackIndex}.primaryArtistRole`)}
                key={trackIndex}
                isDisabled
              >
                <option value="MAIN_ARTIST">Main Artist</option>
              </Select>
            </FormControl>
          </GridItem>
        </Grid>
      )}
      {fields.map((artistDetail, artistDetailIndex) => {
        return (
          <Box mt={4} key={artistDetailIndex}>
            <Grid templateColumns="1fr 1fr 4%" gap={3} marginTop="16px">
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.artistDetails?.[artistDetailIndex]?.artist
                  )}
                >
                  <Input
                    placeholder="Artist"
                    {...register(
                      `discs.${discIndex}.tracks.${trackIndex}.artistDetails.${artistDetailIndex}.artist` as const,
                      {
                        required: 'Please enter artist name or remove the field',
                        maxLength: { value: 1000, message: 'Artist name should not be greater than 1000 characters' },
                      }
                    )}
                  />
                  {errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.artistDetails?.[artistDetailIndex] && (
                    <FormErrorMessage>
                      {
                        errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.artistDetails?.[artistDetailIndex]?.artist
                          ?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.artistDetails?.[artistDetailIndex]?.artistRole
                  )}
                >
                  <Select
                    {...register(
                      `discs.${discIndex}.tracks.${trackIndex}.artistDetails.${artistDetailIndex}.artistRole` as const,
                      { required: 'Please enter artist role or remove the field' }
                    )}
                    key={artistDetailIndex}
                    placeholder="Artist Role"
                  >
                    <option value="MAIN_ARTIST">Main Artist</option>
                    <option value="FEATURED">Featured</option>
                    <option value="SOLOIST">Soloist</option>
                    <option value="WITH">With</option>
                  </Select>
                  {errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.artistDetails?.[artistDetailIndex] && (
                    <FormErrorMessage>
                      {
                        errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.artistDetails?.[artistDetailIndex]?.artistRole
                          ?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <IconButton
                    aria-label="removeArtist"
                    icon={<CloseLine height={24} width={24} />}
                    fill="neutral.400"
                    onClick={() => removeArtist(artistDetailIndex)}
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        );
      })}
      <Box display="flex" alignItems="center" mt={5}>
        <Button
          variant="ghost"
          size="md"
          colorScheme="blue"
          leftIcon={<Icon as={AddIcon} fill="currentcolor" />}
          onClick={() =>
            addArtist({
              artist: '',
              artistRole: '',
            })
          }
        >
          Add an artist
        </Button>
      </Box>
    </>
  );
};
