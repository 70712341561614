import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { PromiseClient } from '@connectrpc/connect';

import { TrackMetadata } from '../../../types';

export const createTrack = async (
  token: string,
  track: TrackMetadata,
  tenantId: string,
  trackClient?: PromiseClient<typeof TrackService>
): Promise<string> => {
  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  const headers = new Headers();
  if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);

  const result = await trackClient?.createTrack(
    {
      parent: tenantId,
      track: {
        displayName: track.trackTitle,
        isrcs: [track.isrc],
        contributors: [
          {
            displayName: track.primaryArtist,
            // this is not clear what to send since client expect it to be enum and enum is not exposed
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            role: 'MAIN_ARTIST',
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ...track.artistDetails.map((artist) => {
            return {
              displayName: artist.artist,
              role: artist.artistRole,
            };
          }),
        ],
      },
    },
    { headers }
  );

  return result?.name ?? '';
};
