import { Track } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/track_pb';

import { ArtistsDetail, TracksData } from '../../types';

export const tracksMapper = (trackData: Array<Track> = []): Array<TracksData> => {
  const tracksMetadata: Array<TracksData> =
    trackData?.map((data) => {
      const { name, displayName: trackTitle = '', isrcs = [], contributors = [] } = data;
      const artistDetails: Array<ArtistsDetail> = contributors.map((contributor) => {
        const { displayName: artist = '' } = contributor;
        return {
          artist,
          artistRole: '', // The source of the data is not clear
        };
      });
      return {
        name,
        trackTitle,
        isrcs,
        // We have a story for the proper mapping https://utopia-music.atlassian.net/browse/RMS-130
        primaryArtist: '', // The source of the data is not clear
        artistDetails,
        label: '', // The source of the data is not clear
        date: '', // The source of the data is not clear
        // audioFile: undefined,
      };
    }) ?? [];
  return tracksMetadata;
};
