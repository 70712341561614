import { Track } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/track_pb';
import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { PromiseClient } from '@connectrpc/connect';

import { TrackDTO } from '../../../types';

export const completeTrack = async (
  token: string,
  name: string,
  trackClient?: PromiseClient<typeof TrackService>
): Promise<TrackDTO | undefined> => {
  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  const headers = new Headers();
  if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);
  const track: Track | undefined = await trackClient?.completeTrack({ name }, { headers });
  return track;
};
