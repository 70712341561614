import { Button, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react';
import { ReactComponent as ArrowDown } from 'remixicon/icons/Arrows/arrow-down-s-line.svg';
import { ReactComponent as CheckLine } from 'remixicon/icons/System/check-line.svg';

export interface ApplicationMenuItem {
  label: string;
  action: () => void;
  selected?: boolean;
}

export interface ApplicationMenuProps {
  applicationName: string;
  menuItems: ApplicationMenuItem[];
}

export const ApplicationMenu = ({ applicationName, menuItems }: ApplicationMenuProps) => {
  return (
    <Menu>
      <MenuButton
        sx={{
          '&:hover': {
            textDecoration: 'none',
            textColor: 'brand.200',
          },
        }}
        as={Button}
        rightIcon={<ArrowDown fill="currentcolor" height={16} width={16} />}
        variant="link"
      >
        <Heading size="xs" noOfLines={1}>
          {applicationName}
        </Heading>
      </MenuButton>
      <MenuList>
        {menuItems.map((item) => (
          <MenuItem key={item.label} onClick={item.action}>
            <Stack flex="1" direction="row" justify="space-between">
              <Text mr="2">{item.label}</Text>
              {item.selected && <CheckLine fill="currentcolor" height={25} width={25} />}
            </Stack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
