import { languages } from '../../mocks/languages';

export const selectOptionsLanguage = (): Array<{ label: string; value: string }> => {
  return languages
    .sort((a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    })
    .map((language) => {
      return {
        label: language.text,
        value: language.text,
      };
    });
};
