import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import Cookies, { CookieChangeListener, CookieChangeOptions } from 'universal-cookie';

import { EnvVarsContext } from '../EnvVarsProvider';

import { Auth, Organization, User } from './auth.types';

const COOKIE_ORG = 'current_org';
const COOKIE_AUTH = 'authenticated';

export const AuthContext = createContext<Auth | undefined>(undefined);

export interface Auth0AuthenticationProviderProps {
  children: ReactNode;
}

const cookies = new Cookies();

export const Auth0AuthenticationProvider = ({ children }: Auth0AuthenticationProviderProps) => {
  const {
    variables: { bffApiUrl },
  } = useContext(EnvVarsContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [user, setUser] = useState<User>();
  const [orgs] = useState<Organization[]>();

  const authBaseUrl = `${bffApiUrl}/auth`;

  const login = useCallback(
    (orgId?: string) => {
      let url = `${authBaseUrl}/login`;
      const defaultOrgId = orgId ?? cookies.get(COOKIE_ORG);

      if (defaultOrgId) {
        url += `?organization=${defaultOrgId}`;
      }
      window.location.replace(url);
    },
    [authBaseUrl]
  );

  const logout = useCallback(() => {
    window.location.replace(`${authBaseUrl}/logout`);
  }, [authBaseUrl]);

  const getUser = useCallback(async () => {
    await fetch(`${authBaseUrl}/profile`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((userResponse: User) => {
        if (cookies.get(COOKIE_AUTH) === false) {
          logout();
        } else if (cookies.get(COOKIE_ORG) && userResponse.org_id !== cookies.get(COOKIE_ORG)) {
          login(cookies.get(COOKIE_ORG));
        } else {
          setUser(userResponse);
          setIsAuthenticated(true);
        }
      })
      .catch(() => {
        setIsAuthenticated(false);
        setUser(undefined);
        login(cookies.get(COOKIE_ORG));
      })
      .finally(() => {
        setIsAuthReady(true);
      });
  }, [authBaseUrl, login, logout]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    const cookieChangeListener: CookieChangeListener = (options: CookieChangeOptions) => {
      if (options.name === COOKIE_AUTH && options.value === false) {
        logout();
      }
    };
    cookies.addChangeListener(cookieChangeListener);

    return () => {
      cookies.removeChangeListener(cookieChangeListener);
    };
  }, [logout]);

  const providerValue = useMemo(
    () => ({ isAuthenticated: () => isAuthenticated, user, orgs, login, logout, isAuthReady } as Auth),
    [isAuthenticated, login, logout, orgs, user, isAuthReady]
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export const useAuth0 = (): Auth => {
  return useContext(AuthContext) as Auth;
};
