import { useCallback, useContext } from 'react';

import { BuffContext, BuffContextValue } from '../../providers';
import { useErrorHandler } from '../useErrorHandler';

export const useTrack = () => {
  const { trackClient } = useContext<BuffContextValue>(BuffContext);
  const { handleInternalServerError } = useErrorHandler();

  const deleteTrack = useCallback(
    async ({ trackName, token }: { trackName: string; token: string }) => {
      // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
      const headers = new Headers();
      if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);
      const deleteTrackRequest = {
        name: trackName,
      };
      await trackClient?.deleteTrack(deleteTrackRequest, { headers }).catch((err) => {
        handleInternalServerError(err, 'Something went wrong', 'Failed to delete a track.', deleteTrackRequest);
      });
    },
    [handleInternalServerError, trackClient]
  );

  return { deleteTrack };
};
