import { useEffect, useRef, useState } from 'react';

import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { PromiseClient } from '@connectrpc/connect';

import { GetTracksData, SortOrder, TracksData, TrackOrderBy } from '../../../types';
import { Auth } from '../../providers/Auth0AuthenticationProvider';
import { getTracks } from '../../services/getTracks';
import { useErrorHandler } from '../useErrorHandler';

export const useTracks = (
  tenantName: string,
  auth: Auth,
  pageSize: number,
  orderBy: TrackOrderBy,
  sortOrder: SortOrder,
  filter: string,
  trackClient: PromiseClient<typeof TrackService> | undefined
) => {
  const [nextPageToken, setNextPageToken] = useState('');
  const [tracks, setTracks] = useState<Array<TracksData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const previousRequestPageTokenRef = useRef('');
  const previousFilterRef = useRef('');
  const { handleInternalServerError } = useErrorHandler();

  useEffect(() => {
    let ignore = false;
    const getTrackRequest = {
      pageToken: previousFilterRef.current !== filter ? undefined : nextPageToken,
      pageSize,
      orderBy,
      sortOrder,
      filter,
    };
    if (tenantName) {
      // Since we use an infinite scroll approach, we send an empty string here (returns the very first page)
      (async () => {
        // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
        const token = auth.legacyGetActiveAccessToken ? await auth.legacyGetActiveAccessToken() : '';
        const { tracks: tracksMetaData, nextPageToken: nextPageTokenNew }: GetTracksData = await getTracks(
          token,
          tenantName,
          trackClient,
          getTrackRequest
        );
        if (!ignore) {
          setTracks((prevTracks) => [...prevTracks, ...tracksMetaData]);
          previousRequestPageTokenRef.current = nextPageTokenNew;
          previousFilterRef.current = filter;
        }
      })()
        .catch((err) => {
          handleInternalServerError(err, 'Something went wrong', 'Failed to fetch tracks', getTrackRequest);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return () => {
      ignore = true;
    };
  }, [auth, handleInternalServerError, nextPageToken, orderBy, pageSize, sortOrder, tenantName, trackClient, filter]);

  const reset = () => {
    setNextPageToken('');
    previousRequestPageTokenRef.current = '';
    setIsLoading(true);
    setTracks([]);
  };

  useEffect(() => {
    // reset everything when tenant changes
    reset();
  }, [tenantName]);

  useEffect(() => {
    // reset everything when filter changes
    reset();
  }, [filter]);

  return { previousRequestPageToken: previousRequestPageTokenRef.current, tracks, isLoading, setNextPageToken, reset };
};
