import { extendTheme } from '@chakra-ui/react';
import { theme as utopiaTheme } from '@utopia-internal/ds-chakra';
import { MultiSelectTheme } from 'chakra-multiselect';

// Probably we will need this as some point
// See more https://utopia-music.atlassian.net/wiki/spaces/DSVS/pages/2756608085/Theme
export const theme = extendTheme(utopiaTheme, {
  components: {
    MultiSelect: MultiSelectTheme,
  },
});
