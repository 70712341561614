import { createContext, useContext, useEffect, useState } from 'react';

import { CatalogDto } from '../../../types';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import { getCatalog } from '../../services/getCatalog';
import { useAuth } from '../AuthenticationProvider';
import { BuffContext, BuffContextValue } from '../BuffProvider';
import { useTenantContext } from '../TenantProvider';

export const CATALOG_DEFAULT: CatalogDto = {
  id: '',
  name: '',
};

export const CatalogContext = createContext<CatalogDto>(CATALOG_DEFAULT);

export interface CatalogProviderProps {
  children: React.ReactNode;
}

const CatalogProvider = (props: CatalogProviderProps) => {
  const { children } = props;
  const auth = useAuth();
  const { handleInternalServerError } = useErrorHandler();
  const [catalog, setCatalog] = useState<CatalogDto>(CATALOG_DEFAULT);
  const { catalogClient } = useContext<BuffContextValue>(BuffContext);
  const { tenantName } = useTenantContext();

  useEffect(() => {
    let ignore = false;
    (async () => {
      // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
      const token = auth.legacyGetActiveAccessToken ? await auth.legacyGetActiveAccessToken() : '';
      if (tenantName) {
        const catalogUpdated: CatalogDto = await getCatalog(token, tenantName, catalogClient);
        if (!ignore) {
          setCatalog(catalogUpdated);
        }
      }
    })().catch((err) => {
      handleInternalServerError(err, 'Something went wrong', 'Failed to fetch catalog', {
        tenantName,
      });
    });
    return () => {
      ignore = true;
    };
  }, [auth, catalogClient, handleInternalServerError, tenantName]);

  return <CatalogContext.Provider value={catalog}>{children}</CatalogContext.Provider>;
};

export default CatalogProvider;
