import { useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

export const useDebounce = <T = unknown>(value: T, delay = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedSet = useMemo(() => debounce(setDebouncedValue, delay), [delay]);

  debouncedSet(value);

  return debouncedValue;
};
