import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { PingService } from '@buf/utopia_repertoire.connectrpc_es/utopia/ping/v1/ping_service_connect';
import { CatalogService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/catalog_service_connect';
import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { TenantService } from '@buf/utopia_repertoire.connectrpc_es/utopia/tenant/v1alpha/tenant_service_connect';
import { PromiseClient, Transport, createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';

import { EnvVarsContext, EnvVarsData } from '../EnvVarsProvider';

export interface BuffProviderProps {
  children: React.ReactNode;
}

export interface BuffContextValue {
  transport?: Transport;
  pingClient?: PromiseClient<typeof PingService>;
  trackClient?: PromiseClient<typeof TrackService>;
  tenantClient?: PromiseClient<typeof TenantService>;
  catalogClient?: PromiseClient<typeof CatalogService>;
}

export const BUFF_CONTEXT_VALUE_DEFAULT: BuffContextValue = {
  transport: undefined,
  pingClient: undefined,
  trackClient: undefined,
  tenantClient: undefined,
  catalogClient: undefined,
};

export const BuffContext = createContext<BuffContextValue>(BUFF_CONTEXT_VALUE_DEFAULT);

export const BuffProvider = (props: BuffProviderProps) => {
  const { children } = props;
  const [transport, setTransport] = useState<Transport>();
  const [pingClient, setPingClient] = useState<PromiseClient<typeof PingService>>();
  const [trackClient, setTrackClient] = useState<PromiseClient<typeof TrackService>>();
  const [tenantClient, setTenantClient] = useState<PromiseClient<typeof TenantService>>();
  const [catalogClient, setCatalogClient] = useState<PromiseClient<typeof CatalogService>>();

  const { variables } = useContext<EnvVarsData>(EnvVarsContext);
  const { bffApiUrl: baseUrl, authMode } = variables ?? {};

  useEffect(() => {
    const transportNew = createConnectTransport({
      baseUrl,
      // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
      credentials: authMode === 'auth0' ? 'include' : 'same-origin',
      jsonOptions: {
        // Attention ⚠️
        // Proto3 JSON parser rejects unknown fields by default.
        // Which means updating Proto definitions on the server side breaks the client.
        // It fires the following error: "ConnectError: [invalid_argument] cannot decode message ***"
        // See https://github.com/bufbuild/protobuf-es/blob/a1a77e47f882a7756fda969e64656bea8e379945/packages/protobuf/src/json-format.ts#L79-L95
        ignoreUnknownFields: true,
      },
    });
    setTransport(transportNew);
    const pingClientNew = createPromiseClient(PingService, transportNew);
    setPingClient(pingClientNew);
    const trackClientNew = createPromiseClient(TrackService, transportNew);
    setTrackClient(trackClientNew);
    const tenantClientNew = createPromiseClient(TenantService, transportNew);
    setTenantClient(tenantClientNew);
    const catalogClientNew = createPromiseClient(CatalogService, transportNew);
    setCatalogClient(catalogClientNew);
  }, [baseUrl, authMode]);

  const contextValue = useMemo(() => {
    return { transport, pingClient, trackClient, tenantClient, catalogClient };
  }, [catalogClient, pingClient, tenantClient, trackClient, transport]);

  return <BuffContext.Provider value={contextValue}>{children}</BuffContext.Provider>;
};
