import { createContext, useContext, useMemo, useState } from 'react';

export interface TracksSearchProviderProps {
  children: React.ReactNode;
}

export const TracksSearchContext = createContext<{
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}>({ filter: '', setFilter: () => {} });

export const TracksSearchProvider = (props: TracksSearchProviderProps) => {
  const { children } = props;
  const [filter, setFilter] = useState<string>('');

  const cacheFilter = useMemo(() => {
    return {
      filter,
      setFilter,
    };
  }, [filter]);

  return <TracksSearchContext.Provider value={cacheFilter}>{children}</TracksSearchContext.Provider>;
};

export const useTracksSearchContext = () => {
  const searchContext = useContext(TracksSearchContext);
  return searchContext;
};
