import { Territories, Territory } from '../../../types';
import { toTerritories, toTerritoryDto } from '../../mappers';

// The backend is actively developing the territories feature, but it's not a part
// of our buff client yet. For now, we use the JSON file data, but we have to switch
// to the buff endpoint as soon as possible
// TODO: Use the future buff territory client here
export const getTerritories = async (): Promise<Territories> => {
  // SOMEDAY: In a perfect world we need to use the BE endpoint here, but not JSON
  const response: Response = await fetch('territories.json');
  const data = (await response.json()) as Array<Territory>;
  const territories: Territories = data.map(toTerritoryDto).reduce(toTerritories, {});
  return territories;
};
