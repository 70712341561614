import { genres } from '../../mocks/genres';
import { Genre } from '../../types';

export const getGenre = (): Array<{ label: string }> => {
  const uniqueGenre = [...new Set(genres.map((group) => group.grouping))].sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return uniqueGenre.map((item) => {
    return { label: item };
  });
};

export const getSubGenre = (genre: string): Array<Genre> => {
  return genres.filter((group) => group.text !== undefined && group.grouping === genre);
};
