import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';

import type {
  ReleaseBasicData,
  ReleaseInformation,
  ReleaseTracks,
  ReleaseWizardContextType,
  Step,
} from '../pages/releases/types';

export interface InitialReleaseFormData {
  step1: ReleaseBasicData;
  step2: ReleaseInformation;
  step3: ReleaseTracks;
}

const initialReleaseFormData: InitialReleaseFormData = {
  step1: {
    releaseTitle: '',
    titleVersion: '',
    genre: '',
    language: '',
    subGenre: '',
    variousArtist: false,
    artistDetails: [],
    primaryArtist: '',
    albumArtSrc: '',
    primaryArtistRole: '',
  },
  step2: {
    rerelease: false,
    releaseDate: '',
    originalReleaseDate: '',
    territories: [],
    pLine: '',
    cLine: '',
  },
  step3: {
    discs: [
      {
        tracks: [],
      },
    ],
    territories: [],
  },
};

export const ReleaseWizardContext = createContext<ReleaseWizardContextType>({
  step1: initialReleaseFormData.step1,
  step2: initialReleaseFormData.step2,
  step3: initialReleaseFormData.step3,
  saveStepInformation: () => {},
});

export const useReleaseForm = (): ReleaseWizardContextType => useContext(ReleaseWizardContext);

export const ReleaseWizardProvider = ({ children }: PropsWithChildren) => {
  const [releaseFormData, setReleaseFormData] = useState(initialReleaseFormData);

  const saveStepInformation = useCallback(
    (step: Step, payload: ReleaseBasicData | ReleaseInformation | ReleaseTracks) => {
      setReleaseFormData({
        ...releaseFormData,
        [step]: payload,
      });
    },
    [releaseFormData]
  );

  const contextValue = useMemo(
    () => ({ ...releaseFormData, saveStepInformation }),
    [releaseFormData, saveStepInformation]
  );
  return <ReleaseWizardContext.Provider value={contextValue}>{children}</ReleaseWizardContext.Provider>;
};

export default ReleaseWizardProvider;
