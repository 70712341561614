import { Button, Grid, GridItem, Heading, Spacer, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddFill } from 'remixicon/icons/System/add-fill.svg';

import { Tracks } from '../components';
import { SearchBar } from '../components/SearchBar';
import { TracksSearchProvider } from '../providers/TracksSearchProvider';

export const Track = () => {
  const navigate = useNavigate();
  const handleNewTrack = () => {
    navigate('/tracks/add');
  };

  const iconTextColor = useColorModeValue('white', 'neutral.900');

  return (
    <>
      <Heading>Tracks</Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem>
          <Spacer />
        </GridItem>
        <GridItem justifyContent="flex-end" display="flex">
          <Button
            onClick={handleNewTrack}
            backgroundColor="brand.300"
            className="new-track-btn"
            leftIcon={<AddFill fill={iconTextColor} height={16} width={16} />}
            colorScheme={iconTextColor}
          >
            Add Track
          </Button>
        </GridItem>
      </Grid>
      {/* SOMEDAY: There may be a separate page for tracks, but for now it's a part of the home page */}
      {/* Show when api is ready to use */}
      <TracksSearchProvider>
        <SearchBar />
        <Tracks />
      </TracksSearchProvider>
    </>
  );
};
