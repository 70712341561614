import { useMemo } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'remixicon/icons/System/add-line.svg';
import { ReactComponent as CloseLine } from 'remixicon/icons/System/close-line.svg';

import { selectOptionsLanguage } from '../../utils/selectedOptionsLanguage';

import { ReleaseTracks } from './types';

export const FieldTitleLanguages = ({ discIndex, trackIndex }: { discIndex: number; trackIndex: number }) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<ReleaseTracks>();
  const {
    fields,
    append: addTitleLanguage,
    remove: removeTitleLanguage,
  } = useFieldArray({
    control,
    name: `discs.${discIndex}.tracks.${trackIndex}.titleLanguages`,
  });
  const languages = useMemo(() => selectOptionsLanguage(), []);

  return (
    <>
      {fields.map((titleLanguage, titleLanguageIndex) => {
        return (
          <Box mt={4} key={titleLanguageIndex}>
            <Grid templateColumns="repeat(1, 45% 25% 20% 4%)" gap={4}>
              <GridItem>
                <FormControl
                  isRequired
                  isInvalid={Boolean(
                    errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.titleLanguages?.[titleLanguageIndex]?.trackTitle
                  )}
                >
                  <FormLabel fontSize="16px">Track Title</FormLabel>
                  <Input
                    placeholder="The name of the track"
                    {...register(
                      `discs.${discIndex}.tracks.${trackIndex}.titleLanguages.${titleLanguageIndex}.trackTitle` as const,
                      {
                        required: 'Please enter track title',
                        maxLength: { value: 1000, message: 'Track title should not be greater than 1000 characters' },
                      }
                    )}
                  />
                  {errors?.discs?.[discIndex]?.tracks?.[trackIndex] && (
                    <FormErrorMessage>
                      {
                        errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.titleLanguages?.[titleLanguageIndex]
                          ?.trackTitle?.message
                      }
                    </FormErrorMessage>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel fontSize="16px">Title Version</FormLabel>
                  <Input
                    placeholder={`"Live", "Acoustic", etc.`}
                    {...register(
                      `discs.${discIndex}.tracks.${trackIndex}.titleLanguages.${titleLanguageIndex}.titleVersion`,
                      {
                        maxLength: { value: 1000, message: 'Title version should not be greater than 1000 characters' },
                      }
                    )}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel fontSize="16px">Language</FormLabel>
                  <Select
                    {...register(
                      `discs.${discIndex}.tracks.${trackIndex}.titleLanguages.${titleLanguageIndex}.language`
                    )}
                    key={titleLanguageIndex}
                  >
                    {languages.map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
              {titleLanguageIndex >= 1 ? (
                <GridItem alignSelf="center" pt={8}>
                  <Button variant="ghost" onClick={() => removeTitleLanguage(titleLanguageIndex)}>
                    <Icon as={CloseLine} fill="neutral.400" />
                  </Button>
                </GridItem>
              ) : null}
            </Grid>
          </Box>
        );
      })}
      <Box display="flex" alignItems="center" mt={5}>
        <Grid>
          <GridItem>
            <Button
              variant="ghost"
              onClick={() =>
                addTitleLanguage({
                  trackTitle: '',
                  titleVersion: '',
                  language: '',
                })
              }
            >
              <Icon as={AddIcon} fill="blue.300" marginRight={1} />
              <Text color="blue.300">add title language</Text>
            </Button>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};
