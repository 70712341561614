import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum';

import { useAuth } from '../AuthenticationProvider';

// TODO: The better place for it 🤔💭
export type DatadogConfig = {
  clientToken?: string;
  datadogSite?: string;
  service?: string;
  version?: string;
  env?: string;
};

interface RumSettings {
  isEnabled: boolean;
  config: DatadogConfig;
  applicationId?: string;
}

interface SessionRecorderProps {
  isEnabled: boolean;
  children: React.ReactNode;
}

const ROLE = {
  UTOPIAN: 'utopian',
  EXTERNAL: 'external',
};

/**
 * `RUM` stands for `Real User Monitoring` in DataDog
 */
export const initDatadogRum = ({ isEnabled, applicationId, config }: RumSettings): void => {
  const isConfigured = applicationId != null && config.clientToken != null && config.datadogSite != null;

  if (!isEnabled || !isConfigured) {
    return;
  }

  datadogRum.init({
    applicationId,
    clientToken: config.clientToken ?? '',
    site: config.datadogSite,
    service: config.service,
    version: config.version,
    env: config.env,
    sampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    // SOMEDAY: Better solution for that 🤔💭
    // Prevent the "DD_RUM is already initialized" error
    silentMultipleInit: true,
  });
};

export const SessionRecorderDatadog = ({ children, isEnabled }: SessionRecorderProps) => {
  const auth = useAuth();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
    const role = auth.legacyHasRole ? (auth.legacyHasRole(ROLE.UTOPIAN) ? ROLE.UTOPIAN : ROLE.EXTERNAL) : '';

    datadogRum.setUser({
      name: auth.user?.name,
      email: auth.user?.email,
      id: auth.user?.id,
      role,
    });

    datadogRum.startSessionReplayRecording();

    // eslint-disable-next-line consistent-return
    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, [isEnabled, auth]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
