import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { PromiseClient } from '@connectrpc/connect';

import { UploadAudioFileMetadata } from '../../../types';

export const uploadAudioTrack = async (
  token: string,
  fileMetadata: UploadAudioFileMetadata,
  audioTrackFile: File,
  trackClient?: PromiseClient<typeof TrackService>
): Promise<Response> => {
  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  const headers = new Headers();
  if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);
  const result = await trackClient?.uploadTrackAudio(fileMetadata, { headers });
  return fetch(result?.uploadUri ?? '', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/octet-stream', ...result?.requiredHeaders },
    body: audioTrackFile,
  });
};
