import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Tenant } from '../../../types';
import { useListTenants } from '../../hooks/useListTenants';
import { useAuth } from '../AuthenticationProvider';
import { EnvVarsContext, EnvVarsData } from '../EnvVarsProvider';

export const TenantContext = createContext<{
  tenantName: string;
  setTenantName: React.Dispatch<React.SetStateAction<string>>;
  tenantDisplayName: string;
  setTenantDisplayName: React.Dispatch<React.SetStateAction<string>>;
  tenants: Array<Tenant>;
  hasError: boolean;
}>({
  tenantName: '',
  setTenantName: () => {},
  tenantDisplayName: '',
  setTenantDisplayName: () => {},
  tenants: [],
  hasError: false,
});

export interface TenantProviderProps {
  children: React.ReactNode;
}

const TenantProvider = (props: TenantProviderProps) => {
  const { children } = props;
  const { tenants, hasError } = useListTenants();
  const auth = useAuth();
  const {
    variables: { authMode },
  } = useContext<EnvVarsData>(EnvVarsContext);

  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  //
  // For auth0 the user will always have exactly an org_id / tenant so we don't need to use local storage so we can
  // remove it once we've migrated to auth0.
  const defaultTenantId = authMode === 'auth0' ? auth.user?.org_id : localStorage.getItem('tenantName');
  const [tenantName, setTenantName] = useState<string>(defaultTenantId ?? '');

  const defaultTenantDisplayName = authMode === 'auth0' ? '' : localStorage.getItem('tenantDisplayName');
  const [tenantDisplayName, setTenantDisplayName] = useState<string>(defaultTenantDisplayName ?? '');

  const tenantContextValue = useMemo(
    () => ({ tenantName, setTenantName, tenantDisplayName, setTenantDisplayName, tenants, hasError }),
    [tenantName, tenantDisplayName, tenants, hasError]
  );

  useEffect(() => {
    if (tenants.length > 0) {
      const name = tenantName || defaultTenantId;
      let tenant = tenants.find((t) => t.name === name || t.auth0Organization === name);
      // For legacy keycloak we need to select a tenant since there is no default tenant.
      if (!tenant && authMode !== 'auth0') {
        // setting first tenant from the list as a default selected tenant
        [tenant] = tenants;
      }
      // SOMEDAY For auth0 there should always be a selected organization for a token, but probably want to handle the
      // case where there isn't one anyway.
      if (tenant) {
        setTenantName(tenant.name);
        setTenantDisplayName(tenant.displayName);
      }
    }
  }, [authMode, defaultTenantId, tenantName, tenants]);

  return <TenantContext.Provider value={tenantContextValue}>{children}</TenantContext.Provider>;
};

export const useTenantContext = () => {
  const tenantContext = useContext(TenantContext);
  return tenantContext;
};

export default TenantProvider;
