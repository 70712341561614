import { KeyboardEvent, ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import { ReactComponent as MusicFill } from 'remixicon/icons/Media/music-fill.svg';
import { ReactComponent as CloseLine } from 'remixicon/icons/System/close-line.svg';
import { ReactComponent as SearchLine } from 'remixicon/icons/System/search-line.svg';
import { ReactComponent as UserFill } from 'remixicon/icons/User & Faces/user-3-fill.svg';

import { SuggestionArtist, SuggestionTrack } from '../../../types';
import { useSearch, useDebounce } from '../../hooks';
import { useTenantContext, useTracksSearchContext } from '../../providers';

// Searchbar component is not a table component and so it is difficult to used the TruncatedText component
// to calcuate the width of data.

const Truncate = ({ data, noOfCharsToTruncate }: { data: string; noOfCharsToTruncate: number }) => {
  return (
    <Tooltip
      isDisabled={data.length < noOfCharsToTruncate}
      label={data}
      aria-label={data}
      hasArrow
      placement="bottom-start"
    >
      {data.length < noOfCharsToTruncate ? data : `${data.substring(0, noOfCharsToTruncate)}...`}
    </Tooltip>
  );
};

export const SearchBar = () => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const debouncedSearchInputValue = useDebounce(searchInputValue);
  const { tenantName } = useTenantContext();
  const [suggestionTracks, setSuggestionTracks] = useState<SuggestionTrack[]>([]);
  const [suggestionArtists, setSuggestionArtists] = useState<SuggestionArtist[]>([]);
  const { setFilter } = useTracksSearchContext();
  const [isTracksLoading, setIsTracksLoading] = useState(false);
  const [isArtistsLoading, setIsArtistsLoading] = useState(false);
  const { searchTracks, searchArtists } = useSearch();
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const popoverRef = useRef(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const prevSearchRef = useRef('');

  useOutsideClick({
    ref: popoverRef,
    handler: () => {
      setIsSuggestionsOpen(false);
      setSelectedItemIndex(0);
    },
  });

  const handleClearSearch = useCallback(() => {
    setIsSuggestionsOpen(false);
    setFilter('');
    setSearchInputValue('');
    setSelectedItemIndex(0);
  }, [setFilter]);

  const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(event.target.value);
  }, []);

  useEffect(() => {
    handleClearSearch();
  }, [tenantName, handleClearSearch]);

  useEffect(() => {
    const searchValue = debouncedSearchInputValue.trim();
    setIsSuggestionsOpen(searchValue.length > 0);

    const searchTracksOnChange = async () => {
      setIsTracksLoading(true);
      const suggestionTracksResult = await searchTracks(searchValue);
      setSuggestionTracks(suggestionTracksResult);
      setIsTracksLoading(false);
    };

    const searchArtistsOnChange = async () => {
      setIsArtistsLoading(true);
      const suggestionArtistsResult = await searchArtists(searchValue);
      setSuggestionArtists(suggestionArtistsResult);
      setIsArtistsLoading(false);
    };

    if (prevSearchRef.current !== searchValue) {
      searchTracksOnChange();
      searchArtistsOnChange();
      prevSearchRef.current = searchValue;
    }
  }, [debouncedSearchInputValue, searchArtists, searchTracks]);

  // reset the selected item index to 0 if the second time search result is less than previous search result
  useEffect(() => {
    if (suggestionArtists.length > 0 || suggestionTracks.length > 0) {
      if (selectedItemIndex > suggestionArtists.length + suggestionTracks.length) setSelectedItemIndex(0);
    }
  }, [selectedItemIndex, suggestionArtists, suggestionTracks]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        ['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key) &&
        isSuggestionsOpen &&
        !isArtistsLoading &&
        !isTracksLoading &&
        (suggestionArtists.length > 0 || suggestionTracks.length > 0)
      ) {
        event.preventDefault();
        setSelectedItemIndex((currentIndex) => {
          if (event.key === 'ArrowDown') {
            if (currentIndex === suggestionTracks.length + suggestionArtists.length - 1) return currentIndex;
            return currentIndex + 1;
          }

          if (event.key === 'ArrowUp') {
            if (currentIndex === 0) return currentIndex;
            return currentIndex - 1;
          }

          if (event.key === 'Enter') {
            if (suggestionArtists.length > 0 && selectedItemIndex >= suggestionTracks.length) {
              setFilter(
                `contributors.main_artist='${
                  suggestionArtists[selectedItemIndex - suggestionTracks.length].artistName
                }'`
              );
            }
            if (suggestionTracks.length > 0 && selectedItemIndex <= suggestionTracks.length - 1) {
              setFilter(`display_name='${suggestionTracks[selectedItemIndex].trackTitle}'`);
            }
            setIsSuggestionsOpen(false);
            return 0;
          }

          return currentIndex;
        });
      }
    },
    [
      isArtistsLoading,
      isSuggestionsOpen,
      isTracksLoading,
      selectedItemIndex,
      setFilter,
      suggestionArtists,
      suggestionTracks,
    ]
  );

  return (
    <Box position="relative" ref={popoverRef}>
      <Popover
        isOpen={isSuggestionsOpen && searchInputValue.length > 0}
        placement="bottom-start"
        initialFocusRef={searchInputRef}
        matchWidth
        closeOnBlur
        autoFocus={false}
      >
        <PopoverTrigger>
          <InputGroup borderRadius="5px" size="lg" width="35%" zIndex={10}>
            <InputLeftElement pointerEvents="none">
              <SearchLine fill="currentcolor" height={16} width={16} />
            </InputLeftElement>
            <Input
              placeholder="Search ...."
              autoFocus
              borderRadius="25px"
              onChange={handleOnChange}
              value={searchInputValue}
              ref={searchInputRef}
              onKeyDown={onKeyDown}
            />
            <InputRightElement>
              <CloseLine fill="currentcolor" height={16} width={16} onClick={handleClearSearch} />
            </InputRightElement>
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent width="100%">
          <PopoverBody>
            {suggestionTracks.length > 0 && (
              <Stack divider={<StackDivider />}>
                <Box>
                  <Text fontSize="xs" as="b" textTransform="uppercase">
                    Tracks
                  </Text>
                  <List>
                    {suggestionTracks.map((track, index) => {
                      return (
                        <ListItem
                          key={track.name}
                          onClick={() => {
                            setFilter(`display_name='${track.trackTitle}'`);
                            setIsSuggestionsOpen(false);
                          }}
                        >
                          <Button
                            p={1}
                            variant="ghost"
                            fontSize="md"
                            fontWeight="normal"
                            justifyContent="flex-start"
                            whiteSpace="pre-wrap"
                            w="full"
                            isActive={index === selectedItemIndex}
                          >
                            <ListIcon as={MusicFill} fill="currentcolor" />
                            <Box display="flex" justifyContent="space-between" width="100%" gap={1}>
                              <Truncate data={`${track.trackTitle} - ${track.artistName}`} noOfCharsToTruncate={25} />
                              <Text fontWeight="400px" fontSize="14px" color="neutral.400">
                                {track.isrc}
                              </Text>
                            </Box>
                          </Button>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Stack>
            )}
            {suggestionArtists.length > 0 && (
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Text fontSize="xs" fontWeight="400" textTransform="uppercase">
                    Artists
                  </Text>
                  <List>
                    {suggestionArtists.map((artist, index) => {
                      return (
                        <ListItem
                          onClick={() => {
                            setFilter(`contributors.main_artist='${artist.artistName}'`);
                            setIsSuggestionsOpen(false);
                          }}
                          key={artist.artistName}
                        >
                          <Button
                            p={1}
                            variant="ghost"
                            w="full"
                            fontSize="md"
                            fontWeight="normal"
                            justifyContent="flex-start"
                            isActive={index + suggestionTracks.length === selectedItemIndex}
                          >
                            <ListIcon as={UserFill} fill="currentcolor" />
                            <Truncate data={artist.artistName} noOfCharsToTruncate={25} />
                          </Button>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Stack>
            )}
            {searchInputValue.length > 0 &&
              debouncedSearchInputValue.length > 0 &&
              suggestionTracks.length === 0 &&
              suggestionArtists.length === 0 &&
              !isTracksLoading &&
              !isArtistsLoading && (
                <List>
                  <ListItem>No tracks or artists found for &quot;{searchInputValue}&quot;</ListItem>
                </List>
              )}
            {searchInputValue.length > 0 && (isTracksLoading || isArtistsLoading) && (
              <List display="flex" justifyContent="space-between">
                <Text>Searching for &quot;{searchInputValue}&quot;</Text>
                <Spinner />
              </List>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
