import { createContext, useCallback, useEffect, useState, useMemo } from 'react';

import { Spinner } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';

import { readConfigFromMeta } from './readConfigFromMeta';

export interface EnvVars {
  FE_KEYCLOAK_AUTH_URL: string;
  FE_KEYCLOAK_CLIENT_ID: string;
  FE_KEYCLOAK_REALM: string;
  FE_DATADOG_APPLICATION_ID: string;
  FE_DATADOG_CLIENT_TOKEN: string;
  FE_DATADOG_SITE: string;
  FE_DATADOG_SERVICE: string;
  FE_BFF_API_URL: string;
  FE_MUSIC_MONITORING_URL: string;
  FE_ZENDESK_WIDGET_URL: string;
  FE_REPERTOIRE_ENVIRONMENT: string;
  FE_AUTH_MODE: string;
  FE_BASE_DOMAIN: string;
}

export interface Vars {
  clientId: string;
  realm: string;
  authUrl: string;
  ddClientToken: string;
  ddApplicationId: string;
  ddSite: string;
  ddService: string;
  bffApiUrl: string;
  musicMonitoringUrl: string;
  zendeskWidgetUrl: string;
  repertoireEnvironment: string;
  authMode: string;
  baseDomain: string;
}

export const VARIABLES_DEFAULT: Vars = {
  authUrl: '',
  clientId: '',
  realm: '',
  ddClientToken: '',
  ddApplicationId: '',
  ddSite: '',
  ddService: '',
  bffApiUrl: '',
  musicMonitoringUrl: '',
  zendeskWidgetUrl: 'false',
  repertoireEnvironment: '',
  authMode: '',
  baseDomain: '',
};

export interface EnvVarsData {
  variables: Vars;
  loading: boolean;
}

export const ENV_VARS_DATA_DEFAULT: EnvVarsData = {
  variables: VARIABLES_DEFAULT,
  loading: true,
};

export const EnvVarsContext = createContext<EnvVarsData>(ENV_VARS_DATA_DEFAULT);

export interface EnvVarsProviderProps {
  children: React.ReactNode;
}

export const EnvVarsProvider = (props: EnvVarsProviderProps) => {
  const { children } = props;
  const [variables, setVariables] = useState<Vars>(VARIABLES_DEFAULT);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchRemoteEnvVars = useCallback(async () => {
    let config: EnvVars | Record<string, string> = {};

    if (APP_CONFIG.VITE_FE_LOCAL_CONFIG === 'true') {
      // eslint-disable-next-line no-console
      console.info('reading from local file');
      config = readConfigFromMeta(APP_CONFIG);
    } else {
      const VARIABLES_ENDPOINT = '/runtime/config.json';
      const response: Response = await fetch(VARIABLES_ENDPOINT);
      config = (await response.json()) as EnvVars;
    }

    const {
      FE_KEYCLOAK_AUTH_URL: authUrl,
      FE_KEYCLOAK_CLIENT_ID: clientId,
      FE_KEYCLOAK_REALM: realm,
      FE_DATADOG_APPLICATION_ID: ddApplicationId,
      FE_DATADOG_CLIENT_TOKEN: ddClientToken,
      FE_DATADOG_SITE: ddSite,
      FE_DATADOG_SERVICE: ddService,
      FE_BFF_API_URL: bffApiUrl,
      FE_MUSIC_MONITORING_URL: musicMonitoringUrl,
      FE_ZENDESK_WIDGET_URL: zendeskWidgetUrl,
      FE_REPERTOIRE_ENVIRONMENT: repertoireEnvironment,
      FE_AUTH_MODE: defaultAuthMode,
      FE_BASE_DOMAIN: baseDomain,
    } = config;

    // TODO https://utopia-music.atlassian.net/browse/RMS-728 Temporary "feature flag" for easy testing in production
    // while we support both keycloak and auth0. This can be removed once we have switched to auth0.
    const params = new URL(window.location.toString()).searchParams;
    const overrideAuthMode = params.get('auth_mode');
    const authMode = overrideAuthMode ?? defaultAuthMode;

    setVariables({
      authUrl,
      clientId,
      realm,
      ddClientToken,
      ddApplicationId,
      ddSite,
      ddService,
      bffApiUrl,
      musicMonitoringUrl,
      zendeskWidgetUrl,
      repertoireEnvironment,
      authMode,
      baseDomain,
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRemoteEnvVars().catch((error) => datadogRum.addError(error));
  }, [fetchRemoteEnvVars]);

  const envVarsData: EnvVarsData = useMemo(() => {
    return {
      variables,
      loading,
    };
  }, [loading, variables]);

  // If the secrets are fetching from the remote endpoint, we app must be blocked
  if (loading) {
    return <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />;
  }

  return <EnvVarsContext.Provider value={envVarsData}>{children}</EnvVarsContext.Provider>;
};
