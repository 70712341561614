import { CatalogService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/catalog_service_connect';
import { PromiseClient } from '@connectrpc/connect';

type CatalogItemRequest = {
  catalogId: string;
  trackName: string;
};

export const createCatalogItem = async (
  token: string,
  request: CatalogItemRequest,
  catalogClient?: PromiseClient<typeof CatalogService>
) => {
  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  const headers = new Headers();
  if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);
  const result = await catalogClient?.createCatalogItem(
    {
      parent: request.catalogId,
      catalogItem: {
        item: {
          case: 'track',
          value: request.trackName,
        },
      },
    },
    { headers }
  );
  return result;
};
