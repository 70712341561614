import { MouseEvent, useCallback, useState, useContext } from 'react';

import {
  Box,
  Button,
  ButtonProps,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as ArrowDownIcon } from 'remixicon/icons/Arrows/arrow-down-line.svg';
import { ReactComponent as ArrowUpIcon } from 'remixicon/icons/Arrows/arrow-up-line.svg';

import { SortOrder } from '../../../types';
import { useTracks } from '../../hooks/useTracks';
import { useAuth } from '../../providers';
import { BuffContext, BuffContextValue } from '../../providers/BuffProvider';
import { useTenantContext } from '../../providers/TenantProvider';
import { useTracksSearchContext } from '../../providers/TracksSearchProvider';
import { activeColorBtn } from '../../utils/activeColor';
import { NoTracks } from '../NoTracks';
import { TracksSkeleton } from '../TracksSkeleton';
import { TruncatedText } from '../TruncatedText';

export type SortField = {
  key: 'display_name' | 'contributors.main_artist';
  label: 'Title' | 'Artist';
};

export interface SortControlBtnProps extends ButtonProps {
  active?: boolean;
  order?: SortOrder;
  iconProps?: object;
}

// TODO: Move it to a separate file
export const SortControlBtn = forwardRef<SortControlBtnProps, 'button'>((props, ref) => {
  const { children, color: colorProp, active = false, order = 'desc', iconProps, ...other } = props;

  const color = activeColorBtn(active);
  const iconPropsDefault = { fill: 'currentcolor', width: 24 };
  let leftIcon =
    order === 'asc' ? (
      <ArrowUpIcon {...iconPropsDefault} {...iconProps} />
    ) : (
      <ArrowDownIcon {...iconPropsDefault} {...iconProps} />
    );
  // We don't render the arrow icon for the inactive control
  if (!active) {
    leftIcon = <Box width={6} height={6} />;
  }

  return (
    <Button ref={ref} variant="link" leftIcon={leftIcon} iconSpacing={0} size="sm" color={color} {...other}>
      {children}
    </Button>
  );
});

export const PAGE_SIZE_DEFAULT = 50;

export interface SortState {
  sortField: SortField;
  sortOrder: SortOrder;
}

const DEFAULT_SORT_ORDER: SortOrder = 'asc';

const SORT_FIELDS: SortField[] = [
  { key: 'display_name', label: 'Title' },
  { key: 'contributors.main_artist', label: 'Artist' },
];

export const Tracks = () => {
  const [sortState, setSortState] = useState<SortState>({ sortField: SORT_FIELDS[0], sortOrder: DEFAULT_SORT_ORDER });

  const auth = useAuth();
  const { tenantName } = useTenantContext();
  const { trackClient } = useContext<BuffContextValue>(BuffContext);
  const tableBorderColor = useColorModeValue('gray.200', 'neutral.700');
  const { filter } = useTracksSearchContext();

  // InfiniteScroll has functionality to track fetching the next portion of the data, but not the initial fetching
  // So we have a separate variable for tracking the initial data-fetching state
  // SOMEDAY: We need a better way of tracking the data loading state for our pages

  const {
    sortField: { key: orderBy },
    sortOrder,
  } = sortState;

  const { previousRequestPageToken, isLoading, tracks, setNextPageToken, reset } = useTracks(
    tenantName,
    auth,
    PAGE_SIZE_DEFAULT,
    orderBy,
    sortOrder,
    filter,
    trackClient
  );

  const onNextScroll = useCallback(() => {
    setNextPageToken(previousRequestPageToken);
  }, [previousRequestPageToken, setNextPageToken]);

  const onSortControlClick = useCallback(
    (_: MouseEvent<HTMLButtonElement>, sortField: SortField) => {
      setSortState((prevState) => {
        if (prevState.sortField.key === sortField.key) {
          return {
            ...prevState,
            sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
          };
        }

        return {
          sortField,
          sortOrder: DEFAULT_SORT_ORDER,
        };
      });
      reset();
    },
    [reset]
  );

  const getOnSortControlClick = useCallback(
    (sortField: SortField) => {
      return (e: MouseEvent<HTMLButtonElement>) => onSortControlClick(e, sortField);
    },
    [onSortControlClick]
  );

  // if loading is finished and tracks are still zero
  // that means we do not have any tracks
  if (!isLoading && tracks.length === 0 && previousRequestPageToken === '') {
    return <NoTracks />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginTop: 16,
      }}
    >
      <Box as="section" sx={{ alignSelf: 'flex-end', display: 'flex', gap: 1 }}>
        {SORT_FIELDS.map((sortField) => (
          <SortControlBtn
            key={sortField.key}
            onClick={getOnSortControlClick(sortField)}
            active={sortField.key === sortState.sortField.key}
            order={sortState.sortOrder}
          >
            {sortField.label}
          </SortControlBtn>
        ))}
      </Box>
      <TableContainer>
        <InfiniteScroll
          dataLength={tracks.length}
          next={onNextScroll}
          // Proper loading indicator https://utopia-music.atlassian.net/browse/RMS-325
          loader={<TracksSkeleton rows={PAGE_SIZE_DEFAULT} />}
          endMessage={!isLoading && <p style={{ textAlign: 'center' }}>You have reached the end of all tracks</p>}
          // Empty string as a nextPageToken returned from the BE means "it's the latest page token"
          hasMore={previousRequestPageToken !== ''}
        >
          <Table variant="simple" sx={{ tableLayout: 'fixed' }}>
            <Thead>
              <Tr>
                <Th sx={{ borderColor: tableBorderColor, width: '40%' }}>Track Title</Th>
                <Th sx={{ borderColor: tableBorderColor, width: '30%' }}>Artists</Th>
                <Th sx={{ textAlign: 'right', borderColor: tableBorderColor }}>ISRC</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tracks.map((track) => {
                const {
                  trackTitle: title,
                  artistDetails,
                  isrcs,
                  name,
                  // label,
                  // date,
                } = track;
                const [details] = artistDetails;
                const { artist } = details ?? {};
                return (
                  <Tr key={name}>
                    <Td sx={{ verticalAlign: 'top' }}>
                      <TruncatedText>{title}</TruncatedText>
                    </Td>
                    <Td sx={{ verticalAlign: 'top' }}>
                      <TruncatedText>{artist}</TruncatedText>
                    </Td>
                    <Td sx={{ textAlign: 'right', verticalAlign: 'top' }}>
                      <TruncatedText>{isrcs.join(', ')}</TruncatedText>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </InfiniteScroll>
      </TableContainer>
      {isLoading && <TracksSkeleton rows={PAGE_SIZE_DEFAULT} />}
    </Box>
  );
};
