import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Auth, Organization, User } from './auth.types';

export const MockedAuthContext = createContext<Auth | undefined>(undefined);

export interface MockedAuth0AuthenticationProviderProps {
  mockedUser: User;
  children: ReactNode;
}

export const MockedAuth0AuthenticationProvider = ({ mockedUser, children }: MockedAuth0AuthenticationProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [user, setUser] = useState<User>();
  const [orgs] = useState<Organization[]>();

  const login = useCallback(() => {
    setIsAuthenticated(true);
  }, []);

  const getUser = useCallback(async () => {
    setUser(mockedUser);
    setIsAuthenticated(true);
    setIsAuthReady(true);
  }, [mockedUser]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(undefined);
  }, []);

  const providerValue = useMemo(
    () => ({ isAuthenticated: () => isAuthenticated, user, orgs, login, logout, isAuthReady } as Auth),
    [isAuthenticated, login, logout, orgs, user, isAuthReady]
  );

  return <MockedAuthContext.Provider value={providerValue}>{children}</MockedAuthContext.Provider>;
};

export const useMockedAuth0 = (): Auth => {
  return useContext(MockedAuthContext) as Auth;
};
