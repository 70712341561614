import { Box, Collapse, useColorModeValue, useDisclosure } from '@chakra-ui/react';

type AccordionProps = {
  defaultIsOpen?: boolean;
  renderHeader: (isOpen: boolean, onToggle: () => void) => React.ReactNode;
  renderBody: (onToggle: () => void) => React.ReactNode;
};

export const Accordion = ({ defaultIsOpen = true, renderHeader, renderBody }: AccordionProps) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });
  const boxBgColor = useColorModeValue('neutral.50', 'neutral.700');

  return (
    <>
      <Box bgColor={boxBgColor} width="full" height="90px" roundedTop={4}>
        {renderHeader(isOpen, onToggle)}
      </Box>
      <Collapse in={isOpen} animateOpacity>
        {renderBody(onToggle)}
      </Collapse>
    </>
  );
};
