import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { Code, ConnectError, PromiseClient } from '@connectrpc/connect';

import { getTracks } from '../services/getTracks';

const removeHypensFromISRC = (value: string) => value.replace(/[^A-Za-z0-9]/g, '').slice(0, 12);

export const validateISRC = (value: string): boolean | string => {
  const formattedValue = removeHypensFromISRC(value);
  const isrcPattern = /^[A-Za-z]{2}\w{3}\d{2}\d{5}$/;
  return isrcPattern.test(formattedValue) || 'Invalid ISRC';
};

export const validateIsrcAsync = async (
  token: string,
  tenantName: string,
  trackClient: PromiseClient<typeof TrackService>,
  filter: string
) => {
  try {
    const { tracks } = await getTracks(token, tenantName, trackClient, {
      filter,
    });
    // If we find any tracks it means that the isrc is already presented in the DB
    return tracks.length === 0 || 'ISRC is already taken';
  } catch (error) {
    if (error instanceof ConnectError) {
      if (error.code === Code.InvalidArgument) {
        return 'Invalid ISRC';
      }
    }
  }
  return 'Unknown Error';
};
