import { AccordionPanel, Tag, useColorModeValue } from '@chakra-ui/react';

import { TerritoryDto } from '../../../types';

export type CountriesFiltered = {
  selected: Array<TerritoryDto>;
  unselected: Array<TerritoryDto>;
};

export type AccordionPanelReviewProps = {
  territories: Array<TerritoryDto>;
  countriesIdsSelected: Set<string>;
};

export const AccordionPanelReview = (props: AccordionPanelReviewProps) => {
  const { territories, countriesIdsSelected, ...other } = props;
  const { selected, unselected } = territories.reduce(
    (countriesFiltered: CountriesFiltered, country: TerritoryDto) => {
      const { text: label } = country;
      return countriesIdsSelected.has(label)
        ? { selected: [...countriesFiltered.selected, country], unselected: countriesFiltered.unselected }
        : { selected: countriesFiltered.selected, unselected: [...countriesFiltered.unselected, country] };
    },
    { selected: [], unselected: [] }
  );

  const tagBgColor = useColorModeValue('neutral.300', 'neutral.700');

  return (
    <AccordionPanel display="flex" flexWrap="wrap" gap={1} {...other}>
      {selected.map((country) => {
        const { text: label, id } = country;
        return (
          <Tag key={id} p={2} background="blue.300" color="blue.800">
            {label}
          </Tag>
        );
      })}
      {unselected.map((country) => {
        const { text: label, id } = country;
        return (
          <Tag key={id} p={2} background={tagBgColor} color="neutral.500">
            {label}
          </Tag>
        );
      })}
    </AccordionPanel>
  );
};
