import { useContext, useEffect } from 'react';

import { EnvVarsContext, EnvVarsData } from '../EnvVarsProvider';

import { SessionRecorderDatadog, initDatadogRum } from './rum';

export * from './rum';

export type DatadogConfig = {
  clientToken?: string;
  datadogSite?: string;
  service?: string;
  version?: string;
  env?: string;
};

interface DatadogProviderProps {
  isEnabled: boolean;
  children: React.ReactNode;
}

interface Settings extends DatadogConfig, Omit<DatadogProviderProps, 'children'> {
  applicationId?: string;
  context?: Record<string, unknown>;
}

const initDatadog = ({ isEnabled, applicationId, clientToken, datadogSite, service, version, env }: Settings): void => {
  const datadogConfig: DatadogConfig = {
    clientToken,
    datadogSite,
    service,
    version,
    env,
  };

  initDatadogRum({
    isEnabled,
    applicationId,
    config: datadogConfig,
  });
};

export const DatadogProvider = ({ isEnabled, children }: DatadogProviderProps) => {
  const { variables } = useContext<EnvVarsData>(EnvVarsContext);
  const { ddClientToken, ddApplicationId, ddSite, ddService, repertoireEnvironment } = variables;

  // VITE_COMMIT_SHA is available after the build only,
  // therefore it will be "undefined" for the development mode
  // It's not a big deal since we don't use Datadog in the development mode,
  // but for production and staging only
  const { VITE_COMMIT_SHA: commitSha = 'no_sha' } = import.meta.env;

  useEffect(() => {
    initDatadog({
      isEnabled,
      version: commitSha,
      clientToken: ddClientToken,
      applicationId: ddApplicationId,
      datadogSite: ddSite,
      service: ddService,
      env: repertoireEnvironment,
    });
  }, [commitSha, ddApplicationId, ddClientToken, ddService, ddSite, isEnabled, repertoireEnvironment]);

  return <SessionRecorderDatadog isEnabled={isEnabled}>{children}</SessionRecorderDatadog>;
};
