import React from 'react';

import { Box, useColorModeValue } from '@chakra-ui/react';

export const TrackSection = ({ children, ...rest }: React.ComponentProps<typeof Box>) => {
  const boxBgColor = useColorModeValue('neutral.50', 'neutral.700');
  const boxBorderColor = useColorModeValue('neutral.200', 'neutral.600');
  return (
    <Box
      pt={1}
      pl={12}
      pr={10}
      pb={5}
      bgColor={boxBgColor}
      borderTop="1px solid"
      borderTopColor={boxBorderColor}
      shadow="md"
      roundedBottom={4}
      {...rest}
    >
      {children}
    </Box>
  );
};
