import { ComponentProps, useMemo, useState, useRef, useCallback, useEffect, useContext } from 'react';

import {
  Box,
  Grid,
  GridItem,
  Text,
  IconButton,
  Stack,
  Switch,
  FormLabel,
  Button,
  FormControl,
  Select,
  FormErrorMessage,
  Input,
  HStack,
  Icon,
  VStack,
  Progress,
  FormHelperText,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { createDragDropManager } from 'dnd-core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as ArrowDown } from 'remixicon/icons/Arrows/arrow-down-s-line.svg';
import { ReactComponent as ArrowUp } from 'remixicon/icons/Arrows/arrow-up-s-line.svg';
import { ReactComponent as HeadphoneIcon } from 'remixicon/icons/Media/headphone-fill.svg';
import { ReactComponent as CloseIcon } from 'remixicon/icons/System/close-fill.svg';
import { ReactComponent as DeleteBin } from 'remixicon/icons/System/delete-bin-7-line.svg';

import { Territories, TerritoryGroup } from '../../../types';
import { FileUpload, MultiTerritorySelect } from '../../components';
import { Accordion } from '../../components/Accordion';
import { StepTitle } from '../../components/StepTitle';
import { TrackSection } from '../../components/TrackSection';
import { useIsrcMask } from '../../hooks';
import { useAuth } from '../../providers';
import { BuffContext, BuffContextValue } from '../../providers/BuffProvider';
import { useTenantContext } from '../../providers/TenantProvider';
import { getTerritories } from '../../services';
import { getGenre, getSubGenre } from '../../utils/getGenre';
import { validateISRC, validateIsrcAsync } from '../../utils/validateISRC';

import { FieldArtist } from './FieldArtist';
import { FieldTitleLanguages } from './FieldTitleLanguages';
import { ReleaseTracks } from './types';

const INVALID_FILE_NAME = 'Invalid file name.';
const REQUIRED_FILE_NAME = 'Please upload audio file for this track.';

const manager = createDragDropManager(HTML5Backend);

type FieldTrackProps = {
  trackIndex: number;
  discIndex: number;
  removeTrack: (index: number) => void;
};

const UploadBox = ({
  file,
  onClose,
  isLoading,
}: {
  file?: File;
  onClose: ComponentProps<typeof IconButton>['onClick'];
  isLoading: boolean;
}) => {
  return (
    <HStack width="50%" borderColor="neutral.600" border="1px" borderRadius="6px" px="4" py="3">
      <Icon as={HeadphoneIcon} fill="currentcolor" height="24px" width="24px" />
      <Box width="100%">
        <VStack display="flex" justifyContent="flex-start" alignItems="flex-start">
          <Box fontSize="sm">{file?.name || 'no file name'}</Box>
          {isLoading ? (
            <Progress hasStripe value={64} width="100%" height="10px" colorScheme="blue" borderRadius={0} />
          ) : (
            <Progress value={100} width="100%" height="10px" colorScheme="green" borderRadius={0} />
          )}
        </VStack>
      </Box>
      <IconButton
        aria-label="close"
        variant="unstyled"
        icon={<CloseIcon fill="currentcolor" height="24px" width="24px" />}
        onClick={onClose}
      />
    </HStack>
  );
};

export const FieldTrack = ({ trackIndex, discIndex, removeTrack }: FieldTrackProps) => {
  const {
    watch,
    register,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
    resetField,
  } = useFormContext<ReleaseTracks>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const audioFileRegister = register(`discs.${discIndex}.tracks.${trackIndex}.audioFile`, {
    validate: {
      validateRequireFile: (files: FileList | undefined) => (files && files.length > 0) || REQUIRED_FILE_NAME,
      validateFileName: (files: FileList | undefined) => {
        if (files) {
          // Check file name has only ASCII characters
          const pattern = /^[\x20-\x7F]*$/;
          return pattern.test(files[0]?.name) || INVALID_FILE_NAME;
        }
        return INVALID_FILE_NAME;
      },
    },
  });

  // SOMEDAY: Move the territories logic to a separate hook. "useTerritories" or something like that
  const [territoriesAvailable, setTerritoriesAvailable] = useState<Territories>({});
  const territoriesSelectedDefault = getValues(`discs.${discIndex}.tracks.${trackIndex}.territories`);
  const [territoriesSelected, setTerritoriesSelected] = useState<Set<string>>(
    new Set<string>(territoriesSelectedDefault)
  );
  const fetchTerritories = useCallback(async () => {
    const territoriesNew = await getTerritories();
    setTerritoriesAvailable(territoriesNew);
  }, []);

  useEffect(() => {
    fetchTerritories().catch((error: Error) => {
      datadogRum.addError(error);
    });
  }, [fetchTerritories]);

  const [territoryToCountry, setTerritoryToCountry] = useState<Record<TerritoryGroup, Array<string>>>();
  useEffect(() => {
    const territoryToCountryNew = Object.keys(territoriesAvailable).reduce((acc, ter) => {
      const countriesLabels = territoriesAvailable[ter as TerritoryGroup].map((count) => {
        return count.text;
      });
      return {
        ...acc,
        [ter]: [...countriesLabels],
      };
    }, {});
    setTerritoryToCountry(territoryToCountryNew as Record<TerritoryGroup, Array<string>>);
  }, [territoriesAvailable]);

  useEffect(() => {
    setValue('territories', Array.from(territoriesSelected));
  }, [setValue, territoriesSelected]);

  const buttonBgColor = useColorModeValue('neutral.300', 'neutral.600');
  const buttonColor = useColorModeValue('neutral.900', 'neutral.200');

  const onDrop = useCallback(
    (fileList: FileList) => {
      setValue(`discs.${discIndex}.tracks.${trackIndex}.audioFile`, fileList);
    },
    [discIndex, setValue, trackIndex]
  );

  const { ref: isrcRef } = useIsrcMask();

  const auth = useAuth();
  const { trackClient } = useContext<BuffContextValue>(BuffContext);
  const { tenantName } = useTenantContext();
  const isrcRegister = register(`discs.${discIndex}.tracks.${trackIndex}.isrc`, {
    required: 'Please enter ISRC',
    validate: {
      duplicate: async (isrc = '') => {
        if (trackClient) {
          // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
          const token = auth.legacyGetActiveAccessToken ? await auth.legacyGetActiveAccessToken() : '';
          const filterQuery = `isrcs:${isrc.replaceAll('-', '')}`;
          return validateIsrcAsync(token, tenantName, trackClient, filterQuery);
        }
        return 'Unknown error';
      },
      format: validateISRC,
    },
  });

  const listOfGenre = useMemo(() => getGenre(), []);
  const genreValue = watch(`discs.${discIndex}.tracks.${trackIndex}.genre`);

  const listofSubGenre = useMemo(() => getSubGenre(genreValue), [genreValue]);
  const primaryArtistValue = getValues(`discs.${discIndex}.tracks.${trackIndex}.primaryArtist`);

  return (
    <Box pb={2} key={trackIndex}>
      <Accordion
        renderHeader={(isOpen, onToggle) => {
          return (
            <Grid
              templateColumns="repeat(1,15% 40% 30% 5% 5%)"
              gap={2}
              pl={4}
              justifyContent="center"
              alignItems="center"
            >
              <GridItem fontSize="60px">{trackIndex + 1}</GridItem>
              <GridItem>
                <Box display="flex" flexDirection="column">
                  <Text fontSize="20px">
                    {watch(`discs.${discIndex}.tracks.${trackIndex}.titleLanguages.0.trackTitle`) || '-'}
                  </Text>
                  <Text fontSize="16px"> {primaryArtistValue || '-'}</Text>
                </Box>
              </GridItem>
              <GridItem>
                <Box display="flex" flexDirection="column">
                  <Text fontSize="12px">ISRC</Text>
                  <Text fontSize="16px">{watch(`discs.${discIndex}.tracks.${trackIndex}.isrc`) || '-'}</Text>
                </Box>
              </GridItem>
              <GridItem>
                <IconButton
                  aria-label="alert"
                  variant="unstyled"
                  icon={<DeleteBin fill="currentcolor" height="24px" width="24px" />}
                  onClick={() => {
                    removeTrack(trackIndex);
                  }}
                />
              </GridItem>
              <GridItem>
                <IconButton
                  aria-label="alert"
                  variant="unstyled"
                  icon={
                    isOpen ? (
                      <ArrowUp fill="currentcolor" height="24px" width="24px" />
                    ) : (
                      <ArrowDown fill="currentcolor" height="24px" width="24px" />
                    )
                  }
                  onClick={onToggle}
                />
              </GridItem>
            </Grid>
          );
        }}
        renderBody={(onToggle) => {
          return (
            <>
              <TrackSection>
                <StepTitle value="Audio File" />
                <FormControl isInvalid={Boolean(errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.audioFile)}>
                  <FormLabel fontSize="xl">Audio File</FormLabel>
                  {(watch(`discs.${discIndex}.tracks.${trackIndex}.audioFile`)?.length ?? 0) > 0 ? (
                    <UploadBox
                      file={watch(`discs.${discIndex}.tracks.${trackIndex}.audioFile`)?.[0]}
                      onClose={() => {
                        setValue(`discs.${discIndex}.tracks.${trackIndex}.audioFile`, undefined);
                        resetField(`discs.${discIndex}.tracks.${trackIndex}.audioFile`);
                      }}
                      isLoading={false}
                    />
                  ) : (
                    <DndProvider manager={manager}>
                      <FileUpload
                        onDrop={onDrop}
                        height="172px"
                        instructionsText="Drag and drop files here"
                        allowedTypes={['wav', 'mp3', 'flac']}
                      >
                        <Input
                          type="file"
                          {...audioFileRegister}
                          ref={(instanceRef) => {
                            audioFileRegister.ref(instanceRef);
                            fileInputRef.current = instanceRef;
                          }}
                          accept=".mp3,.wav,.flac"
                          display="none"
                        />
                        <Button
                          bgColor={buttonBgColor}
                          color={buttonColor}
                          borderRadius="20px"
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}
                        >
                          Choose File
                        </Button>
                      </FileUpload>
                    </DndProvider>
                  )}
                  {errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.audioFile && (
                    <FormErrorMessage>
                      {errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.audioFile?.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              </TrackSection>
              <TrackSection>
                <StepTitle value="Basic" />
                <Box>
                  <Stack direction="row" marginTop={5} marginBottom={5}>
                    <Switch
                      id="instrumental"
                      size="md"
                      colorScheme="blue"
                      {...register(`discs.${discIndex}.tracks.${trackIndex}.instrumental` as const)}
                    />
                    <FormLabel htmlFor="instrumental">Instrumental</FormLabel>
                    <Switch
                      id="reRecord"
                      size="md"
                      colorScheme="blue"
                      {...register(`discs.${discIndex}.tracks.${trackIndex}.reRecord` as const)}
                    />
                    <FormLabel htmlFor="reRecord">Re-record</FormLabel>
                  </Stack>
                  <Grid w="45%">
                    <GridItem>
                      <FormControl
                        isRequired
                        isInvalid={Boolean(errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.isrc)}
                      >
                        <FormLabel>ISRC</FormLabel>
                        <Input
                          placeholder="CC-XXX-YY-NNNNN (ex:USABC1234501)"
                          {...isrcRegister}
                          ref={(instanceRef) => {
                            isrcRegister.ref(instanceRef);
                            isrcRef.current = instanceRef;
                          }}
                        />
                        {errors?.discs?.[discIndex] && (
                          <FormErrorMessage>
                            {errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.isrc?.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                  </Grid>
                  <FieldTitleLanguages discIndex={discIndex} trackIndex={trackIndex} />
                  <Grid templateColumns="repeat(2, 45%)" gap={4}>
                    <GridItem>
                      <FormControl
                        isRequired
                        isInvalid={Boolean(errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.genre)}
                      >
                        <FormLabel fontSize="16px">Genre</FormLabel>
                        <Select
                          {...register(`discs.${discIndex}.tracks.${trackIndex}.genre`, {
                            required: 'Please select Genre',
                          })}
                          key={trackIndex}
                        >
                          <option hidden disabled value="">
                            Genre
                          </option>
                          {listOfGenre.map((gen) => (
                            <option label={gen.label} key={gen.label}>
                              {gen.label}
                            </option>
                          ))}
                        </Select>
                        {errors?.discs?.[discIndex] && errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.genre && (
                          <FormErrorMessage>
                            {errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.genre?.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={Boolean(errors?.discs?.[discIndex]?.tracks?.[trackIndex]?.subGenre)}>
                        <FormLabel fontSize="16px">Sub-Genre</FormLabel>
                        <Select
                          {...register(`discs.${discIndex}.tracks.${trackIndex}.subGenre`)}
                          key={trackIndex}
                          placeholder="K-pop,grunge,etc"
                        >
                          {listofSubGenre.map((subgenre) => (
                            <option value={subgenre.text} key={subgenre.text}>
                              {subgenre.text}
                            </option>
                          ))}
                        </Select>
                        {genreValue && listofSubGenre.length === 0 && (
                          <FormHelperText>No Sub-Genre available for - {genreValue}</FormHelperText>
                        )}
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box>
              </TrackSection>
              <TrackSection>
                <StepTitle value="Rights & Credits" />
                <Grid mt={3} templateColumns="repeat(3,1fr)" gap={4}>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontSize="16px">P Line Year</FormLabel>
                      <Input
                        placeholder="Year that recording was copy-written"
                        {...register(`discs.${discIndex}.tracks.${trackIndex}.pLine`)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontSize="16px">P Line Owner</FormLabel>
                      <Input
                        placeholder="Who owns the recording?"
                        {...register(`discs.${discIndex}.tracks.${trackIndex}.pLineOwner`, {
                          maxLength: {
                            value: 1000,
                            message: 'PLine owner should not be greater than 1000 characters',
                          },
                        })}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontSize="16px">Percentage</FormLabel>
                      <Input
                        placeholder="What percentage do they own?"
                        {...register(`discs.${discIndex}.tracks.${trackIndex}.pLinePercentage`)}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel fontSize="16px">Ownership Type</FormLabel>
                      <Select
                        {...register(`discs.${discIndex}.tracks.${trackIndex}.ownershipType`)}
                        key={trackIndex}
                        placeholder="Owned,licensed,successor in title"
                      >
                        <option value="Owned">Owned</option>
                        <option value="Licensed">Licensed</option>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={4}>
                    <FormControl>
                      <Text fontSize="16px">Territories</Text>
                      <MultiTerritorySelect
                        territories={territoriesAvailable}
                        territoryToCountry={territoryToCountry}
                        countriesIdsSelected={territoriesSelected}
                        setCountriesIdsSelected={setTerritoriesSelected}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </TrackSection>
              <TrackSection>
                <StepTitle value="Artists" />
                <FieldArtist discIndex={discIndex} trackIndex={trackIndex} />
              </TrackSection>
              <TrackSection display="flex" pt={10} pb={10} pr={14} justifyContent="flex-end">
                <Button
                  colorScheme="blue.300"
                  color="blue.300"
                  variant="outline"
                  onClick={async () => {
                    await trigger();
                    if (isValid) {
                      onToggle();
                    }
                  }}
                  className="save-track-btn"
                >
                  Save Track
                </Button>
              </TrackSection>
            </>
          );
        }}
      />
    </Box>
  );
};
