import { Genre } from '../types';

export const genres: Array<Genre> = [
  {
    id: 1,
    alternativeId: 0,
    boolVal: false,
    grouping: 'African',
  },
  {
    id: 2,
    alternativeId: 0,
    boolVal: false,
    text: 'African Dancehall',
    grouping: 'African',
  },
  {
    id: 3,
    alternativeId: 0,
    boolVal: false,
    text: 'African Reggae',
    grouping: 'African',
  },
  {
    id: 4,
    alternativeId: 0,
    boolVal: false,
    text: 'Afrikaans',
    grouping: 'African',
  },
  {
    id: 5,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro House',
    grouping: 'African',
  },
  {
    id: 6,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro Soul',
    grouping: 'African',
  },
  {
    id: 7,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro-Beat',
    grouping: 'African',
  },
  {
    id: 8,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro-folk',
    grouping: 'African',
  },
  {
    id: 9,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro-fusion',
    grouping: 'African',
  },
  {
    id: 10,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro-Pop',
    grouping: 'African',
  },
  {
    id: 11,
    alternativeId: 0,
    boolVal: false,
    text: 'Afrobeats',
    grouping: 'African',
  },
  {
    id: 12,
    alternativeId: 0,
    boolVal: false,
    text: 'Alte',
    grouping: 'African',
  },
  {
    id: 13,
    alternativeId: 0,
    boolVal: false,
    text: 'Amapiano',
    grouping: 'African',
  },
  {
    id: 14,
    alternativeId: 0,
    boolVal: false,
    text: 'Benga',
    grouping: 'African',
  },
  {
    id: 15,
    alternativeId: 0,
    boolVal: false,
    text: 'Bongo-Flava',
    grouping: 'African',
  },
  {
    id: 16,
    alternativeId: 0,
    boolVal: false,
    text: 'Coupé-Décalé',
    grouping: 'African',
  },
  {
    id: 17,
    alternativeId: 0,
    boolVal: false,
    text: 'Gqom',
    grouping: 'African',
  },
  {
    id: 18,
    alternativeId: 0,
    boolVal: false,
    text: 'Highlife',
    grouping: 'African',
  },
  {
    id: 19,
    alternativeId: 0,
    boolVal: false,
    text: 'Kizomba',
    grouping: 'African',
  },
  {
    id: 20,
    alternativeId: 0,
    boolVal: false,
    text: 'Kuduro',
    grouping: 'African',
  },
  {
    id: 21,
    alternativeId: 0,
    boolVal: false,
    text: 'Kwaito',
    grouping: 'African',
  },
  {
    id: 22,
    alternativeId: 0,
    boolVal: false,
    text: 'Maskandi',
    grouping: 'African',
  },
  {
    id: 23,
    alternativeId: 0,
    boolVal: false,
    text: 'Mbalax',
    grouping: 'African',
  },
  {
    id: 24,
    alternativeId: 0,
    boolVal: false,
    text: 'Ndombolo',
    grouping: 'African',
  },
  {
    id: 25,
    alternativeId: 0,
    boolVal: false,
    text: 'Shangaan Electro',
    grouping: 'African',
  },
  {
    id: 26,
    alternativeId: 0,
    boolVal: false,
    text: 'Soukous',
    grouping: 'African',
  },
  {
    id: 27,
    alternativeId: 0,
    boolVal: false,
    text: 'Taarab',
    grouping: 'African',
  },
  {
    id: 28,
    alternativeId: 0,
    boolVal: false,
    text: 'Zouglou',
    grouping: 'African',
  },
  {
    id: 29,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Alternative',
  },
  {
    id: 30,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Alt',
    grouping: 'Alternative',
  },
  {
    id: 31,
    alternativeId: 0,
    boolVal: false,
    text: 'College Rock',
    grouping: 'Alternative',
  },
  {
    id: 32,
    alternativeId: 0,
    boolVal: false,
    text: 'EMO',
    grouping: 'Alternative',
  },
  {
    id: 33,
    alternativeId: 0,
    boolVal: false,
    text: 'Goth Rock',
    grouping: 'Alternative',
  },
  {
    id: 34,
    alternativeId: 0,
    boolVal: false,
    text: 'Grunge',
    grouping: 'Alternative',
  },
  {
    id: 35,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Egyptian',
    grouping: 'Alternative',
  },
  {
    id: 36,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Levant',
    grouping: 'Alternative',
  },
  {
    id: 37,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Maghreb',
    grouping: 'Alternative',
  },
  {
    id: 38,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Pop',
    grouping: 'Alternative',
  },
  {
    id: 39,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Rock',
    grouping: 'Alternative',
  },
  {
    id: 40,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Indie',
    grouping: 'Alternative',
  },
  {
    id: 41,
    alternativeId: 0,
    boolVal: false,
    text: 'New Wave',
    grouping: 'Alternative',
  },
  {
    id: 42,
    alternativeId: 0,
    boolVal: false,
    text: 'Pop Punk',
    grouping: 'Alternative',
  },
  {
    id: 43,
    alternativeId: 0,
    boolVal: false,
    text: 'Punk',
    grouping: 'Alternative',
  },
  {
    id: 44,
    alternativeId: 0,
    boolVal: false,
    text: 'Turkish Alternative',
    grouping: 'Alternative',
  },
  {
    id: 45,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Anime',
  },
  {
    id: 46,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Arabic',
  },
  {
    id: 47,
    alternativeId: 0,
    boolVal: false,
    text: 'Arabic Pop',
    grouping: 'Arabic',
  },
  {
    id: 48,
    alternativeId: 0,
    boolVal: false,
    text: 'Islamic',
    grouping: 'Arabic',
  },
  {
    id: 49,
    alternativeId: 0,
    boolVal: false,
    text: 'Khaleeji',
    grouping: 'Arabic',
  },
  {
    id: 50,
    alternativeId: 0,
    boolVal: false,
    text: 'Levant',
    grouping: 'Arabic',
  },
  {
    id: 51,
    alternativeId: 0,
    boolVal: false,
    text: 'Maghreb Rai',
    grouping: 'Arabic',
  },
  {
    id: 52,
    alternativeId: 0,
    boolVal: false,
    text: 'North African',
    grouping: 'Arabic',
  },
  {
    id: 53,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Blues',
  },
  {
    id: 54,
    alternativeId: 0,
    boolVal: false,
    text: 'Acoustic Blues',
    grouping: 'Blues',
  },
  {
    id: 55,
    alternativeId: 0,
    boolVal: false,
    text: 'Chicago Blues',
    grouping: 'Blues',
  },
  {
    id: 56,
    alternativeId: 0,
    boolVal: false,
    text: 'Classic Blues',
    grouping: 'Blues',
  },
  {
    id: 57,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Blues',
    grouping: 'Blues',
  },
  {
    id: 58,
    alternativeId: 0,
    boolVal: false,
    text: 'Country Blues',
    grouping: 'Blues',
  },
  {
    id: 59,
    alternativeId: 0,
    boolVal: false,
    text: 'Delta Blues',
    grouping: 'Blues',
  },
  {
    id: 60,
    alternativeId: 0,
    boolVal: false,
    text: 'Electric Blues',
    grouping: 'Blues',
  },
  {
    id: 61,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Brazilian',
  },
  {
    id: 62,
    alternativeId: 0,
    boolVal: false,
    text: 'Axé',
    grouping: 'Brazilian',
  },
  {
    id: 63,
    alternativeId: 0,
    boolVal: false,
    text: 'Baile Funk',
    grouping: 'Brazilian',
  },
  {
    id: 64,
    alternativeId: 0,
    boolVal: false,
    text: 'Bossa Nova',
    grouping: 'Brazilian',
  },
  {
    id: 65,
    alternativeId: 0,
    boolVal: false,
    text: 'Choro',
    grouping: 'Brazilian',
  },
  {
    id: 66,
    alternativeId: 0,
    boolVal: false,
    text: 'Forró',
    grouping: 'Brazilian',
  },
  {
    id: 67,
    alternativeId: 0,
    boolVal: false,
    text: 'Frevo',
    grouping: 'Brazilian',
  },
  {
    id: 68,
    alternativeId: 0,
    boolVal: false,
    text: 'MPB',
    grouping: 'Brazilian',
  },
  {
    id: 69,
    alternativeId: 0,
    boolVal: false,
    text: 'Pagode',
    grouping: 'Brazilian',
  },
  {
    id: 70,
    alternativeId: 0,
    boolVal: false,
    text: 'Samba',
    grouping: 'Brazilian',
  },
  {
    id: 71,
    alternativeId: 0,
    boolVal: false,
    text: 'Sertanejo',
    grouping: 'Brazilian',
  },
  {
    id: 72,
    alternativeId: 0,
    boolVal: false,
    grouping: "Children's Music",
  },
  {
    id: 73,
    alternativeId: 0,
    boolVal: false,
    text: 'Lullabies',
    grouping: "Children's Music",
  },
  {
    id: 74,
    alternativeId: 0,
    boolVal: false,
    text: 'Sing-Along',
    grouping: "Children's Music",
  },
  {
    id: 75,
    alternativeId: 0,
    boolVal: false,
    text: 'Stories',
    grouping: "Children's Music",
  },
  {
    id: 76,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Chinese',
  },
  {
    id: 77,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Classical',
    grouping: 'Chinese',
  },
  {
    id: 78,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Flute',
    grouping: 'Chinese',
  },
  {
    id: 79,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Opera',
    grouping: 'Chinese',
  },
  {
    id: 80,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Orchestral',
    grouping: 'Chinese',
  },
  {
    id: 81,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Regional Folk',
    grouping: 'Chinese',
  },
  {
    id: 82,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Strings',
    grouping: 'Chinese',
  },
  {
    id: 83,
    alternativeId: 0,
    boolVal: false,
    text: 'Taiwanese Folk',
    grouping: 'Chinese',
  },
  {
    id: 84,
    alternativeId: 0,
    boolVal: false,
    text: 'Tibetan Native Music',
    grouping: 'Chinese',
  },
  {
    id: 85,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Christian & Gospel',
  },
  {
    id: 86,
    alternativeId: 0,
    boolVal: false,
    text: 'CCM',
    grouping: 'Christian & Gospel',
  },
  {
    id: 87,
    alternativeId: 0,
    boolVal: false,
    text: 'Christian Metal',
    grouping: 'Christian & Gospel',
  },
  {
    id: 88,
    alternativeId: 0,
    boolVal: false,
    text: 'Christian Pop',
    grouping: 'Christian & Gospel',
  },
  {
    id: 89,
    alternativeId: 0,
    boolVal: false,
    text: 'Christian Rap',
    grouping: 'Christian & Gospel',
  },
  {
    id: 90,
    alternativeId: 0,
    boolVal: false,
    text: 'Christian Rock',
    grouping: 'Christian & Gospel',
  },
  {
    id: 91,
    alternativeId: 0,
    boolVal: false,
    text: 'Classic Christian',
    grouping: 'Christian & Gospel',
  },
  {
    id: 92,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Gospel',
    grouping: 'Christian & Gospel',
  },
  {
    id: 93,
    alternativeId: 0,
    boolVal: false,
    text: 'Gospel',
    grouping: 'Christian & Gospel',
  },
  {
    id: 94,
    alternativeId: 0,
    boolVal: false,
    text: 'Inspirational',
    grouping: 'Christian & Gospel',
  },
  {
    id: 95,
    alternativeId: 0,
    boolVal: false,
    text: 'Praise & Worship',
    grouping: 'Christian & Gospel',
  },
  {
    id: 96,
    alternativeId: 0,
    boolVal: false,
    text: 'Southern Gospel',
    grouping: 'Christian & Gospel',
  },
  {
    id: 97,
    alternativeId: 0,
    boolVal: false,
    text: 'Traditional Gospel',
    grouping: 'Christian & Gospel',
  },
  {
    id: 98,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Classical',
  },
  {
    id: 99,
    alternativeId: 0,
    boolVal: false,
    text: 'Art Song',
    grouping: 'Classical',
  },
  {
    id: 100,
    alternativeId: 0,
    boolVal: false,
    text: 'Avant-Garde',
    grouping: 'Classical',
  },
  {
    id: 101,
    alternativeId: 0,
    boolVal: false,
    text: 'Baroque Era',
    grouping: 'Classical',
  },
  {
    id: 102,
    alternativeId: 0,
    boolVal: false,
    text: 'Brass & Woodwinds',
    grouping: 'Classical',
  },
  {
    id: 103,
    alternativeId: 0,
    boolVal: false,
    text: 'Cantata',
    grouping: 'Classical',
  },
  {
    id: 104,
    alternativeId: 0,
    boolVal: false,
    text: 'Cello',
    grouping: 'Classical',
  },
  {
    id: 105,
    alternativeId: 0,
    boolVal: false,
    text: 'Chamber Music',
    grouping: 'Classical',
  },
  {
    id: 106,
    alternativeId: 0,
    boolVal: false,
    text: 'Chant',
    grouping: 'Classical',
  },
  {
    id: 107,
    alternativeId: 0,
    boolVal: false,
    text: 'Choral',
    grouping: 'Classical',
  },
  {
    id: 108,
    alternativeId: 0,
    boolVal: false,
    text: 'Classical Crossover',
    grouping: 'Classical',
  },
  {
    id: 109,
    alternativeId: 0,
    boolVal: false,
    text: 'Classical Era',
    grouping: 'Classical',
  },
  {
    id: 110,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Era',
    grouping: 'Classical',
  },
  {
    id: 111,
    alternativeId: 0,
    boolVal: false,
    text: 'Early Music',
    grouping: 'Classical',
  },
  {
    id: 112,
    alternativeId: 0,
    boolVal: false,
    text: 'Electronic',
    grouping: 'Classical',
  },
  {
    id: 113,
    alternativeId: 0,
    boolVal: false,
    text: 'Guitar',
    grouping: 'Classical',
  },
  {
    id: 114,
    alternativeId: 0,
    boolVal: false,
    text: 'Impressionist',
    grouping: 'Classical',
  },
  {
    id: 115,
    alternativeId: 0,
    boolVal: false,
    text: 'Medieval Era',
    grouping: 'Classical',
  },
  {
    id: 116,
    alternativeId: 0,
    boolVal: false,
    text: 'Minimalism',
    grouping: 'Classical',
  },
  {
    id: 117,
    alternativeId: 0,
    boolVal: false,
    text: 'Modern Era',
    grouping: 'Classical',
  },
  {
    id: 118,
    alternativeId: 0,
    boolVal: false,
    text: 'Opera',
    grouping: 'Classical',
  },
  {
    id: 119,
    alternativeId: 0,
    boolVal: false,
    text: 'Oratorio',
    grouping: 'Classical',
  },
  {
    id: 120,
    alternativeId: 0,
    boolVal: false,
    text: 'Orchestral',
    grouping: 'Classical',
  },
  {
    id: 121,
    alternativeId: 0,
    boolVal: false,
    text: 'Percussion',
    grouping: 'Classical',
  },
  {
    id: 122,
    alternativeId: 0,
    boolVal: false,
    text: 'Piano',
    grouping: 'Classical',
  },
  {
    id: 123,
    alternativeId: 0,
    boolVal: false,
    text: 'Renaissance',
    grouping: 'Classical',
  },
  {
    id: 124,
    alternativeId: 0,
    boolVal: false,
    text: 'Romantic Era',
    grouping: 'Classical',
  },
  {
    id: 125,
    alternativeId: 0,
    boolVal: false,
    text: 'Sacred',
    grouping: 'Classical',
  },
  {
    id: 126,
    alternativeId: 0,
    boolVal: false,
    text: 'Solo Instrumental',
    grouping: 'Classical',
  },
  {
    id: 127,
    alternativeId: 0,
    boolVal: false,
    text: 'Violin',
    grouping: 'Classical',
  },
  {
    id: 128,
    alternativeId: 0,
    boolVal: false,
    text: 'Wedding Music',
    grouping: 'Classical',
  },
  {
    id: 129,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Comedy',
  },
  {
    id: 130,
    alternativeId: 0,
    boolVal: false,
    text: 'Novelty',
    grouping: 'Comedy',
  },
  {
    id: 131,
    alternativeId: 0,
    boolVal: false,
    text: 'Standup Comedy',
    grouping: 'Comedy',
  },
  {
    id: 132,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Country',
  },
  {
    id: 133,
    alternativeId: 0,
    boolVal: false,
    text: 'Alternative Country',
    grouping: 'Country',
  },
  {
    id: 134,
    alternativeId: 0,
    boolVal: false,
    text: 'Americana',
    grouping: 'Country',
  },
  {
    id: 135,
    alternativeId: 0,
    boolVal: false,
    text: 'Bluegrass',
    grouping: 'Country',
  },
  {
    id: 136,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Bluegrass',
    grouping: 'Country',
  },
  {
    id: 137,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Country',
    grouping: 'Country',
  },
  {
    id: 138,
    alternativeId: 0,
    boolVal: false,
    text: 'Country Gospel',
    grouping: 'Country',
  },
  {
    id: 139,
    alternativeId: 0,
    boolVal: false,
    text: 'Honky Tonk',
    grouping: 'Country',
  },
  {
    id: 140,
    alternativeId: 0,
    boolVal: false,
    text: 'Outlaw Country',
    grouping: 'Country',
  },
  {
    id: 141,
    alternativeId: 0,
    boolVal: false,
    text: 'Thai Country',
    grouping: 'Country',
  },
  {
    id: 142,
    alternativeId: 0,
    boolVal: false,
    text: 'Traditional Bluegrass',
    grouping: 'Country',
  },
  {
    id: 143,
    alternativeId: 0,
    boolVal: false,
    text: 'Traditional Country',
    grouping: 'Country',
  },
  {
    id: 144,
    alternativeId: 0,
    boolVal: false,
    text: 'Urban Cowboy',
    grouping: 'Country',
  },
  {
    id: 145,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Cuban',
  },
  {
    id: 146,
    alternativeId: 0,
    boolVal: false,
    text: 'Bolero',
    grouping: 'Cuban',
  },
  {
    id: 147,
    alternativeId: 0,
    boolVal: false,
    text: 'Chachacha',
    grouping: 'Cuban',
  },
  {
    id: 148,
    alternativeId: 0,
    boolVal: false,
    text: 'Guajira',
    grouping: 'Cuban',
  },
  {
    id: 149,
    alternativeId: 0,
    boolVal: false,
    text: 'Guaracha',
    grouping: 'Cuban',
  },
  {
    id: 150,
    alternativeId: 0,
    boolVal: false,
    text: 'Mambo',
    grouping: 'Cuban',
  },
  {
    id: 151,
    alternativeId: 0,
    boolVal: false,
    text: 'Son',
    grouping: 'Cuban',
  },
  {
    id: 152,
    alternativeId: 0,
    boolVal: false,
    text: 'Timba',
    grouping: 'Cuban',
  },
  {
    id: 153,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Dance',
  },
  {
    id: 154,
    alternativeId: 0,
    boolVal: false,
    text: 'Breakbeat',
    grouping: 'Dance',
  },
  {
    id: 155,
    alternativeId: 0,
    boolVal: false,
    text: 'Exercise',
    grouping: 'Dance',
  },
  {
    id: 156,
    alternativeId: 0,
    boolVal: false,
    text: 'Garage',
    grouping: 'Dance',
  },
  {
    id: 157,
    alternativeId: 0,
    boolVal: false,
    text: 'Hardcore',
    grouping: 'Dance',
  },
  {
    id: 158,
    alternativeId: 0,
    boolVal: false,
    text: 'House',
    grouping: 'Dance',
  },
  {
    id: 159,
    alternativeId: 0,
    boolVal: false,
    text: "Jungle/Drum'n'bass",
    grouping: 'Dance',
  },
  {
    id: 160,
    alternativeId: 0,
    boolVal: false,
    text: 'Maghreb Dance',
    grouping: 'Dance',
  },
  {
    id: 161,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno',
    grouping: 'Dance',
  },
  {
    id: 162,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance',
    grouping: 'Dance',
  },
  {
    id: 163,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Disney',
  },
  {
    id: 164,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Easy Listening',
  },
  {
    id: 165,
    alternativeId: 0,
    boolVal: false,
    text: 'Lounge',
    grouping: 'Easy Listening',
  },
  {
    id: 166,
    alternativeId: 0,
    boolVal: false,
    text: 'Swing',
    grouping: 'Easy Listening',
  },
  {
    id: 167,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Electronic',
  },
  {
    id: 168,
    alternativeId: 0,
    boolVal: false,
    text: 'Ambient',
    grouping: 'Electronic',
  },
  {
    id: 169,
    alternativeId: 0,
    boolVal: false,
    text: 'Bass',
    grouping: 'Electronic',
  },
  {
    id: 170,
    alternativeId: 0,
    boolVal: false,
    text: 'Downtempo',
    grouping: 'Electronic',
  },
  {
    id: 171,
    alternativeId: 0,
    boolVal: false,
    text: 'Dubstep',
    grouping: 'Electronic',
  },
  {
    id: 172,
    alternativeId: 0,
    boolVal: false,
    text: "Electro-Cha'abi",
    grouping: 'Electronic',
  },
  {
    id: 173,
    alternativeId: 0,
    boolVal: false,
    text: 'Electronica',
    grouping: 'Electronic',
  },
  {
    id: 174,
    alternativeId: 0,
    boolVal: false,
    text: 'IDM/Experimental',
    grouping: 'Electronic',
  },
  {
    id: 175,
    alternativeId: 0,
    boolVal: false,
    text: 'Industrial',
    grouping: 'Electronic',
  },
  {
    id: 176,
    alternativeId: 0,
    boolVal: false,
    text: 'Levant Electronic',
    grouping: 'Electronic',
  },
  {
    id: 177,
    alternativeId: 0,
    boolVal: false,
    text: 'Maghreb Electronic',
    grouping: 'Electronic',
  },
  {
    id: 178,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Enka',
  },
  {
    id: 179,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Fitness & Workout',
  },
  {
    id: 180,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Folk',
  },
  {
    id: 181,
    alternativeId: 0,
    boolVal: false,
    text: 'Iraqi Folk',
    grouping: 'Folk',
  },
  {
    id: 182,
    alternativeId: 0,
    boolVal: false,
    text: 'Khaleeji Folk',
    grouping: 'Folk',
  },
  {
    id: 183,
    alternativeId: 0,
    boolVal: false,
    grouping: 'French Pop',
  },
  {
    id: 184,
    alternativeId: 0,
    boolVal: false,
    grouping: 'German Folk',
  },
  {
    id: 185,
    alternativeId: 0,
    boolVal: false,
    grouping: 'German Pop',
  },
  {
    id: 186,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 187,
    alternativeId: 0,
    boolVal: false,
    text: 'Alternative Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 188,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 189,
    alternativeId: 0,
    boolVal: false,
    text: 'Dirty South',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 190,
    alternativeId: 0,
    boolVal: false,
    text: 'East Coast Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 191,
    alternativeId: 0,
    boolVal: false,
    text: 'Egyptian Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 192,
    alternativeId: 0,
    boolVal: false,
    text: 'Gangsta Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 193,
    alternativeId: 0,
    boolVal: false,
    text: 'Hardcore Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 194,
    alternativeId: 0,
    boolVal: false,
    text: 'Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 195,
    alternativeId: 0,
    boolVal: false,
    text: 'Khaleeji Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 196,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 197,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 198,
    alternativeId: 0,
    boolVal: false,
    text: 'Levant Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 199,
    alternativeId: 0,
    boolVal: false,
    text: 'Maghreb Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 200,
    alternativeId: 0,
    boolVal: false,
    text: 'Old School Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 201,
    alternativeId: 0,
    boolVal: false,
    text: 'Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 202,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian Hip-Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 203,
    alternativeId: 0,
    boolVal: false,
    text: 'Turkish Hip-Hop/Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 204,
    alternativeId: 0,
    boolVal: false,
    text: 'UK Hip Hop',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 205,
    alternativeId: 0,
    boolVal: false,
    text: 'Underground Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 206,
    alternativeId: 0,
    boolVal: false,
    text: 'West Coast Rap',
    grouping: 'Hip Hop/Rap',
  },
  {
    id: 207,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Holiday',
  },
  {
    id: 208,
    alternativeId: 0,
    boolVal: false,
    text: 'Chanukah',
    grouping: 'Holiday',
  },
  {
    id: 209,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas',
    grouping: 'Holiday',
  },
  {
    id: 210,
    alternativeId: 0,
    boolVal: false,
    text: "Christmas: Children's",
    grouping: 'Holiday',
  },
  {
    id: 211,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Classic',
    grouping: 'Holiday',
  },
  {
    id: 212,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Classical',
    grouping: 'Holiday',
  },
  {
    id: 213,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Jazz',
    grouping: 'Holiday',
  },
  {
    id: 214,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Modern',
    grouping: 'Holiday',
  },
  {
    id: 215,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Pop',
    grouping: 'Holiday',
  },
  {
    id: 216,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: R&B',
    grouping: 'Holiday',
  },
  {
    id: 217,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Religious',
    grouping: 'Holiday',
  },
  {
    id: 218,
    alternativeId: 0,
    boolVal: false,
    text: 'Christmas: Rock',
    grouping: 'Holiday',
  },
  {
    id: 219,
    alternativeId: 0,
    boolVal: false,
    text: 'Easter',
    grouping: 'Holiday',
  },
  {
    id: 220,
    alternativeId: 0,
    boolVal: false,
    text: 'Halloween',
    grouping: 'Holiday',
  },
  {
    id: 221,
    alternativeId: 0,
    boolVal: false,
    text: 'Holiday: Other',
    grouping: 'Holiday',
  },
  {
    id: 222,
    alternativeId: 0,
    boolVal: false,
    text: 'Thanksgiving',
    grouping: 'Holiday',
  },
  {
    id: 223,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Indian',
  },
  {
    id: 224,
    alternativeId: 0,
    boolVal: false,
    text: 'Bollywood',
    grouping: 'Indian',
  },
  {
    id: 225,
    alternativeId: 0,
    boolVal: false,
    text: 'Devotional & Spiritual',
    grouping: 'Indian',
  },
  {
    id: 226,
    alternativeId: 0,
    boolVal: false,
    text: 'Ghazals',
    grouping: 'Indian',
  },
  {
    id: 227,
    alternativeId: 0,
    boolVal: false,
    text: 'Indian Classical',
    grouping: 'Indian',
  },
  {
    id: 228,
    alternativeId: 0,
    boolVal: false,
    text: 'Indian Folk',
    grouping: 'Indian',
  },
  {
    id: 229,
    alternativeId: 0,
    boolVal: false,
    text: 'Indian Pop',
    grouping: 'Indian',
  },
  {
    id: 230,
    alternativeId: 0,
    boolVal: false,
    text: 'Regional Indian',
    grouping: 'Indian',
  },
  {
    id: 231,
    alternativeId: 0,
    boolVal: false,
    text: 'Sufi',
    grouping: 'Indian',
  },
  {
    id: 232,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Inspirational',
  },
  {
    id: 233,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Instrumental',
  },
  {
    id: 234,
    alternativeId: 0,
    boolVal: false,
    grouping: 'J-Pop',
  },
  {
    id: 235,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Jazz',
  },
  {
    id: 236,
    alternativeId: 0,
    boolVal: false,
    text: 'Avant-Garde Jazz',
    grouping: 'Jazz',
  },
  {
    id: 237,
    alternativeId: 0,
    boolVal: false,
    text: 'Bebop',
    grouping: 'Jazz',
  },
  {
    id: 238,
    alternativeId: 0,
    boolVal: false,
    text: 'Big Band',
    grouping: 'Jazz',
  },
  {
    id: 239,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Jazz',
    grouping: 'Jazz',
  },
  {
    id: 240,
    alternativeId: 0,
    boolVal: false,
    text: 'Cool Jazz',
    grouping: 'Jazz',
  },
  {
    id: 241,
    alternativeId: 0,
    boolVal: false,
    text: 'Crossover Jazz',
    grouping: 'Jazz',
  },
  {
    id: 242,
    alternativeId: 0,
    boolVal: false,
    text: 'Dixieland',
    grouping: 'Jazz',
  },
  {
    id: 243,
    alternativeId: 0,
    boolVal: false,
    text: 'Fusion',
    grouping: 'Jazz',
  },
  {
    id: 244,
    alternativeId: 0,
    boolVal: false,
    text: 'Hard Bop',
    grouping: 'Jazz',
  },
  {
    id: 245,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin Jazz',
    grouping: 'Jazz',
  },
  {
    id: 246,
    alternativeId: 0,
    boolVal: false,
    text: 'Mainstream Jazz',
    grouping: 'Jazz',
  },
  {
    id: 247,
    alternativeId: 0,
    boolVal: false,
    text: 'Ragtime',
    grouping: 'Jazz',
  },
  {
    id: 248,
    alternativeId: 0,
    boolVal: false,
    text: 'Smooth Jazz',
    grouping: 'Jazz',
  },
  {
    id: 249,
    alternativeId: 0,
    boolVal: false,
    text: 'Trad Jazz',
    grouping: 'Jazz',
  },
  {
    id: 250,
    alternativeId: 0,
    boolVal: false,
    text: 'Vocal Jazz',
    grouping: 'Jazz',
  },
  {
    id: 251,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Karaoke',
  },
  {
    id: 252,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Kayokyoku',
  },
  {
    id: 253,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Korean',
  },
  {
    id: 254,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Trad Instrumental',
    grouping: 'Korean',
  },
  {
    id: 255,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Trad Song',
    grouping: 'Korean',
  },
  {
    id: 256,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Trad Theater',
    grouping: 'Korean',
  },
  {
    id: 257,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Traditional',
    grouping: 'Korean',
  },
  {
    id: 258,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Latin',
  },
  {
    id: 259,
    alternativeId: 0,
    boolVal: false,
    text: 'Alternative & Rock in Spanish',
    grouping: 'Latin',
  },
  {
    id: 260,
    alternativeId: 0,
    boolVal: false,
    text: 'Baladas y Boleros',
    grouping: 'Latin',
  },
  {
    id: 261,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Latin',
    grouping: 'Latin',
  },
  {
    id: 262,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin Jazz',
    grouping: 'Latin',
  },
  {
    id: 263,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin Urban',
    grouping: 'Latin',
  },
  {
    id: 264,
    alternativeId: 0,
    boolVal: false,
    text: 'Pop in Spanish',
    grouping: 'Latin',
  },
  {
    id: 265,
    alternativeId: 0,
    boolVal: false,
    text: 'Raices',
    grouping: 'Latin',
  },
  {
    id: 266,
    alternativeId: 0,
    boolVal: false,
    text: 'Regional Mexicano',
    grouping: 'Latin',
  },
  {
    id: 267,
    alternativeId: 0,
    boolVal: false,
    text: 'Salsa y Tropical',
    grouping: 'Latin',
  },
  {
    id: 268,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Marching Bands',
  },
  {
    id: 269,
    alternativeId: 0,
    boolVal: false,
    grouping: 'New Age',
  },
  {
    id: 270,
    alternativeId: 0,
    boolVal: false,
    text: 'Environmental',
    grouping: 'New Age',
  },
  {
    id: 271,
    alternativeId: 0,
    boolVal: false,
    text: 'Healing',
    grouping: 'New Age',
  },
  {
    id: 272,
    alternativeId: 0,
    boolVal: false,
    text: 'Meditation',
    grouping: 'New Age',
  },
  {
    id: 273,
    alternativeId: 0,
    boolVal: false,
    text: 'Nature',
    grouping: 'New Age',
  },
  {
    id: 274,
    alternativeId: 0,
    boolVal: false,
    text: 'Relaxation',
    grouping: 'New Age',
  },
  {
    id: 275,
    alternativeId: 0,
    boolVal: false,
    text: 'Travel',
    grouping: 'New Age',
  },
  {
    id: 276,
    alternativeId: 0,
    boolVal: false,
    text: 'Yoga',
    grouping: 'New Age',
  },
  {
    id: 277,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Orchestral',
  },
  {
    id: 278,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Podcasts',
  },
  {
    id: 279,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Pop',
  },
  {
    id: 280,
    alternativeId: 0,
    boolVal: false,
    text: 'Adult Contemporary',
    grouping: 'Pop',
  },
  {
    id: 281,
    alternativeId: 0,
    boolVal: false,
    text: 'Britpop',
    grouping: 'Pop',
  },
  {
    id: 282,
    alternativeId: 0,
    boolVal: false,
    text: 'C-Pop',
    grouping: 'Pop',
  },
  {
    id: 283,
    alternativeId: 0,
    boolVal: false,
    text: 'Cantopop',
    grouping: 'Pop',
  },
  {
    id: 284,
    alternativeId: 0,
    boolVal: false,
    text: 'Egyptian Pop',
    grouping: 'Pop',
  },
  {
    id: 285,
    alternativeId: 0,
    boolVal: false,
    text: 'Indo Pop',
    grouping: 'Pop',
  },
  {
    id: 286,
    alternativeId: 0,
    boolVal: false,
    text: 'Iraqi Pop',
    grouping: 'Pop',
  },
  {
    id: 287,
    alternativeId: 0,
    boolVal: false,
    text: 'K-Pop',
    grouping: 'Pop',
  },
  {
    id: 288,
    alternativeId: 0,
    boolVal: false,
    text: 'Khaleeji Pop',
    grouping: 'Pop',
  },
  {
    id: 289,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Folk-Pop',
    grouping: 'Pop',
  },
  {
    id: 290,
    alternativeId: 0,
    boolVal: false,
    text: 'Levant Pop',
    grouping: 'Pop',
  },
  {
    id: 291,
    alternativeId: 0,
    boolVal: false,
    text: 'Maghreb Pop',
    grouping: 'Pop',
  },
  {
    id: 292,
    alternativeId: 0,
    boolVal: false,
    text: 'Malaysian Pop',
    grouping: 'Pop',
  },
  {
    id: 293,
    alternativeId: 0,
    boolVal: false,
    text: 'Mandopop',
    grouping: 'Pop',
  },
  {
    id: 294,
    alternativeId: 0,
    boolVal: false,
    text: 'Manilla Sound',
    grouping: 'Pop',
  },
  {
    id: 295,
    alternativeId: 0,
    boolVal: false,
    text: 'Oldies',
    grouping: 'Pop',
  },
  {
    id: 296,
    alternativeId: 0,
    boolVal: false,
    text: 'Original Pilipino Music',
    grouping: 'Pop',
  },
  {
    id: 297,
    alternativeId: 0,
    boolVal: false,
    text: 'Pinoy Pop',
    grouping: 'Pop',
  },
  {
    id: 298,
    alternativeId: 0,
    boolVal: false,
    text: 'Pop/Rock',
    grouping: 'Pop',
  },
  {
    id: 299,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian Pop',
    grouping: 'Pop',
  },
  {
    id: 300,
    alternativeId: 0,
    boolVal: false,
    text: 'Shows',
    grouping: 'Pop',
  },
  {
    id: 301,
    alternativeId: 0,
    boolVal: false,
    text: 'Soft Rock',
    grouping: 'Pop',
  },
  {
    id: 302,
    alternativeId: 0,
    boolVal: false,
    text: 'Tai-Pop',
    grouping: 'Pop',
  },
  {
    id: 303,
    alternativeId: 0,
    boolVal: false,
    text: 'Teen Pop',
    grouping: 'Pop',
  },
  {
    id: 304,
    alternativeId: 0,
    boolVal: false,
    text: 'Thai Pop',
    grouping: 'Pop',
  },
  {
    id: 305,
    alternativeId: 0,
    boolVal: false,
    text: 'Turkish Pop',
    grouping: 'Pop',
  },
  {
    id: 306,
    alternativeId: 0,
    boolVal: false,
    grouping: 'R&B/Soul',
  },
  {
    id: 307,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary R&B',
    grouping: 'R&B/Soul',
  },
  {
    id: 308,
    alternativeId: 0,
    boolVal: false,
    text: 'Disco',
    grouping: 'R&B/Soul',
  },
  {
    id: 309,
    alternativeId: 0,
    boolVal: false,
    text: 'Doo Wop',
    grouping: 'R&B/Soul',
  },
  {
    id: 310,
    alternativeId: 0,
    boolVal: false,
    text: 'Funk',
    grouping: 'R&B/Soul',
  },
  {
    id: 311,
    alternativeId: 0,
    boolVal: false,
    text: 'Motown',
    grouping: 'R&B/Soul',
  },
  {
    id: 312,
    alternativeId: 0,
    boolVal: false,
    text: 'Neo-Soul',
    grouping: 'R&B/Soul',
  },
  {
    id: 313,
    alternativeId: 0,
    boolVal: false,
    text: 'Quiet Storm',
    grouping: 'R&B/Soul',
  },
  {
    id: 314,
    alternativeId: 0,
    boolVal: false,
    text: 'Soul',
    grouping: 'R&B/Soul',
  },
  {
    id: 315,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Reggae',
  },
  {
    id: 316,
    alternativeId: 0,
    boolVal: false,
    text: 'Dub',
    grouping: 'Reggae',
  },
  {
    id: 317,
    alternativeId: 0,
    boolVal: false,
    text: 'Lovers Rock',
    grouping: 'Reggae',
  },
  {
    id: 318,
    alternativeId: 0,
    boolVal: false,
    text: 'Modern Dancehall',
    grouping: 'Reggae',
  },
  {
    id: 319,
    alternativeId: 0,
    boolVal: false,
    text: 'Roots Reggae',
    grouping: 'Reggae',
  },
  {
    id: 320,
    alternativeId: 0,
    boolVal: false,
    text: 'Ska',
    grouping: 'Reggae',
  },
  {
    id: 321,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Rock',
  },
  {
    id: 322,
    alternativeId: 0,
    boolVal: false,
    text: 'Adult Alternative',
    grouping: 'Rock',
  },
  {
    id: 323,
    alternativeId: 0,
    boolVal: false,
    text: 'American Trad Rock',
    grouping: 'Rock',
  },
  {
    id: 324,
    alternativeId: 0,
    boolVal: false,
    text: 'Arena Rock',
    grouping: 'Rock',
  },
  {
    id: 325,
    alternativeId: 0,
    boolVal: false,
    text: 'Blues-Rock',
    grouping: 'Rock',
  },
  {
    id: 326,
    alternativeId: 0,
    boolVal: false,
    text: 'British Invasion',
    grouping: 'Rock',
  },
  {
    id: 327,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Rock',
    grouping: 'Rock',
  },
  {
    id: 328,
    alternativeId: 0,
    boolVal: false,
    text: 'Death Metal/Black Metal',
    grouping: 'Rock',
  },
  {
    id: 329,
    alternativeId: 0,
    boolVal: false,
    text: 'Glam Rock',
    grouping: 'Rock',
  },
  {
    id: 330,
    alternativeId: 0,
    boolVal: false,
    text: 'Hair Metal',
    grouping: 'Rock',
  },
  {
    id: 331,
    alternativeId: 0,
    boolVal: false,
    text: 'Hard Rock',
    grouping: 'Rock',
  },
  {
    id: 332,
    alternativeId: 0,
    boolVal: false,
    text: 'Heavy Metal',
    grouping: 'Rock',
  },
  {
    id: 333,
    alternativeId: 0,
    boolVal: false,
    text: 'Jam Bands',
    grouping: 'Rock',
  },
  {
    id: 334,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Rock',
    grouping: 'Rock',
  },
  {
    id: 335,
    alternativeId: 0,
    boolVal: false,
    text: 'Prog-Rock/Art Rock',
    grouping: 'Rock',
  },
  {
    id: 336,
    alternativeId: 0,
    boolVal: false,
    text: 'Psychedelic',
    grouping: 'Rock',
  },
  {
    id: 337,
    alternativeId: 0,
    boolVal: false,
    text: 'Rock & Roll',
    grouping: 'Rock',
  },
  {
    id: 338,
    alternativeId: 0,
    boolVal: false,
    text: 'Rockabilly',
    grouping: 'Rock',
  },
  {
    id: 339,
    alternativeId: 0,
    boolVal: false,
    text: 'Roots Rock',
    grouping: 'Rock',
  },
  {
    id: 340,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian Rock',
    grouping: 'Rock',
  },
  {
    id: 341,
    alternativeId: 0,
    boolVal: false,
    text: 'Singer/Songwriter',
    grouping: 'Rock',
  },
  {
    id: 342,
    alternativeId: 0,
    boolVal: false,
    text: 'Southern Rock',
    grouping: 'Rock',
  },
  {
    id: 343,
    alternativeId: 0,
    boolVal: false,
    text: 'Surf',
    grouping: 'Rock',
  },
  {
    id: 344,
    alternativeId: 0,
    boolVal: false,
    text: 'Tex-Mex',
    grouping: 'Rock',
  },
  {
    id: 345,
    alternativeId: 0,
    boolVal: false,
    text: 'Turkish Rock',
    grouping: 'Rock',
  },
  {
    id: 346,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Russian',
  },
  {
    id: 347,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian Bard',
    grouping: 'Russian',
  },
  {
    id: 348,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian Chanson',
    grouping: 'Russian',
  },
  {
    id: 349,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian Romance',
    grouping: 'Russian',
  },
  {
    id: 350,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Singer/Songwriter',
  },
  {
    id: 351,
    alternativeId: 0,
    boolVal: false,
    text: 'Alternative Folk',
    grouping: 'Singer/Songwriter',
  },
  {
    id: 352,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Folk',
    grouping: 'Singer/Songwriter',
  },
  {
    id: 353,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Singer/Songwriter',
    grouping: 'Singer/Songwriter',
  },
  {
    id: 354,
    alternativeId: 0,
    boolVal: false,
    text: 'Folk-Rock',
    grouping: 'Singer/Songwriter',
  },
  {
    id: 355,
    alternativeId: 0,
    boolVal: false,
    text: 'New Acoustic',
    grouping: 'Singer/Songwriter',
  },
  {
    id: 356,
    alternativeId: 0,
    boolVal: false,
    text: 'Traditional Folk',
    grouping: 'Singer/Songwriter',
  },
  {
    id: 357,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Soundtrack',
  },
  {
    id: 358,
    alternativeId: 0,
    boolVal: false,
    text: 'Foreign Cinema',
    grouping: 'Soundtrack',
  },
  {
    id: 359,
    alternativeId: 0,
    boolVal: false,
    text: 'Musicals',
    grouping: 'Soundtrack',
  },
  {
    id: 360,
    alternativeId: 0,
    boolVal: false,
    text: 'Original Score',
    grouping: 'Soundtrack',
  },
  {
    id: 361,
    alternativeId: 0,
    boolVal: false,
    text: 'Sound Effects',
    grouping: 'Soundtrack',
  },
  {
    id: 362,
    alternativeId: 0,
    boolVal: false,
    text: 'Soundtrack',
    grouping: 'Soundtrack',
  },
  {
    id: 363,
    alternativeId: 0,
    boolVal: false,
    text: 'TV Soundtrack',
    grouping: 'Soundtrack',
  },
  {
    id: 364,
    alternativeId: 0,
    boolVal: false,
    text: 'Video Game',
    grouping: 'Soundtrack',
  },
  {
    id: 365,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Spoken Word',
  },
  {
    id: 366,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Tarab',
  },
  {
    id: 367,
    alternativeId: 0,
    boolVal: false,
    text: 'Egyptian Tarab',
    grouping: 'Tarab',
  },
  {
    id: 368,
    alternativeId: 0,
    boolVal: false,
    text: 'Iraqi Tarab',
    grouping: 'Tarab',
  },
  {
    id: 369,
    alternativeId: 0,
    boolVal: false,
    text: 'Khaleeji Tarab',
    grouping: 'Tarab',
  },
  {
    id: 370,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Turkish',
  },
  {
    id: 371,
    alternativeId: 0,
    boolVal: false,
    text: 'Arabesk',
    grouping: 'Turkish',
  },
  {
    id: 372,
    alternativeId: 0,
    boolVal: false,
    text: 'Fantezi',
    grouping: 'Turkish',
  },
  {
    id: 373,
    alternativeId: 0,
    boolVal: false,
    text: 'Halk',
    grouping: 'Turkish',
  },
  {
    id: 374,
    alternativeId: 0,
    boolVal: false,
    text: 'Özgün',
    grouping: 'Turkish',
  },
  {
    id: 375,
    alternativeId: 0,
    boolVal: false,
    text: 'Religious',
    grouping: 'Turkish',
  },
  {
    id: 376,
    alternativeId: 0,
    boolVal: false,
    text: 'Sanat',
    grouping: 'Turkish',
  },
  {
    id: 377,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Vocal',
  },
  {
    id: 378,
    alternativeId: 0,
    boolVal: false,
    text: 'Standards',
    grouping: 'Vocal',
  },
  {
    id: 379,
    alternativeId: 0,
    boolVal: false,
    text: 'Traditional Pop',
    grouping: 'Vocal',
  },
  {
    id: 380,
    alternativeId: 0,
    boolVal: false,
    text: 'Trot',
    grouping: 'Vocal',
  },
  {
    id: 381,
    alternativeId: 0,
    boolVal: false,
    text: 'Vocal Pop',
    grouping: 'Vocal',
  },
  {
    id: 382,
    alternativeId: 0,
    boolVal: false,
    grouping: 'World',
  },
  {
    id: 383,
    alternativeId: 0,
    boolVal: false,
    text: 'Asia',
    grouping: 'World',
  },
  {
    id: 384,
    alternativeId: 0,
    boolVal: false,
    text: 'Australia',
    grouping: 'World',
  },
  {
    id: 385,
    alternativeId: 0,
    boolVal: false,
    text: 'Cajun',
    grouping: 'World',
  },
  {
    id: 386,
    alternativeId: 0,
    boolVal: false,
    text: 'Calypso',
    grouping: 'World',
  },
  {
    id: 387,
    alternativeId: 0,
    boolVal: false,
    text: 'Caribbean',
    grouping: 'World',
  },
  {
    id: 388,
    alternativeId: 0,
    boolVal: false,
    text: 'Celtic',
    grouping: 'World',
  },
  {
    id: 389,
    alternativeId: 0,
    boolVal: false,
    text: 'Celtic Folk',
    grouping: 'World',
  },
  {
    id: 390,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Celtic',
    grouping: 'World',
  },
  {
    id: 391,
    alternativeId: 0,
    boolVal: false,
    text: 'Dangdut',
    grouping: 'World',
  },
  {
    id: 392,
    alternativeId: 0,
    boolVal: false,
    text: 'Dini',
    grouping: 'World',
  },
  {
    id: 393,
    alternativeId: 0,
    boolVal: false,
    text: 'Drinking Songs',
    grouping: 'World',
  },
  {
    id: 394,
    alternativeId: 0,
    boolVal: false,
    text: 'Europe',
    grouping: 'World',
  },
  {
    id: 395,
    alternativeId: 0,
    boolVal: false,
    text: 'Fado',
    grouping: 'World',
  },
  {
    id: 396,
    alternativeId: 0,
    boolVal: false,
    text: 'Farsi',
    grouping: 'World',
  },
  {
    id: 397,
    alternativeId: 0,
    boolVal: false,
    text: 'Flamenco',
    grouping: 'World',
  },
  {
    id: 398,
    alternativeId: 0,
    boolVal: false,
    text: 'France',
    grouping: 'World',
  },
  {
    id: 399,
    alternativeId: 0,
    boolVal: false,
    text: 'Hawaii',
    grouping: 'World',
  },
  {
    id: 400,
    alternativeId: 0,
    boolVal: false,
    text: 'Iberia',
    grouping: 'World',
  },
  {
    id: 401,
    alternativeId: 0,
    boolVal: false,
    text: 'Indonesian Religious',
    grouping: 'World',
  },
  {
    id: 402,
    alternativeId: 0,
    boolVal: false,
    text: 'Israeli',
    grouping: 'World',
  },
  {
    id: 403,
    alternativeId: 0,
    boolVal: false,
    text: 'Japan',
    grouping: 'World',
  },
  {
    id: 404,
    alternativeId: 0,
    boolVal: false,
    text: 'Japanese Pop',
    grouping: 'World',
  },
  {
    id: 405,
    alternativeId: 0,
    boolVal: false,
    text: 'Klezmer',
    grouping: 'World',
  },
  {
    id: 406,
    alternativeId: 0,
    boolVal: false,
    text: 'North America',
    grouping: 'World',
  },
  {
    id: 407,
    alternativeId: 0,
    boolVal: false,
    text: 'Polka',
    grouping: 'World',
  },
  {
    id: 408,
    alternativeId: 0,
    boolVal: false,
    text: 'Soca',
    grouping: 'World',
  },
  {
    id: 409,
    alternativeId: 0,
    boolVal: false,
    text: 'South Africa',
    grouping: 'World',
  },
  {
    id: 410,
    alternativeId: 0,
    boolVal: false,
    text: 'South America',
    grouping: 'World',
  },
  {
    id: 411,
    alternativeId: 0,
    boolVal: false,
    text: 'Tango',
    grouping: 'World',
  },
  {
    id: 412,
    alternativeId: 0,
    boolVal: false,
    text: 'Traditional Celtic',
    grouping: 'World',
  },
  {
    id: 413,
    alternativeId: 0,
    boolVal: false,
    text: 'Worldbeat',
    grouping: 'World',
  },
  {
    id: 414,
    alternativeId: 0,
    boolVal: false,
    text: 'Zydeco',
    grouping: 'World',
  },
  {
    id: 415,
    alternativeId: 0,
    boolVal: false,
    text: 'Modern Composition',
    grouping: 'Classical',
  },
  {
    id: 416,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks',
    grouping: 'Dance',
  },
  {
    id: 417,
    alternativeId: 0,
    boolVal: false,
    text: 'Chill Out',
    grouping: 'Dance',
  },
  {
    id: 418,
    alternativeId: 0,
    boolVal: false,
    text: 'Deep House',
    grouping: 'Dance',
  },
  {
    id: 419,
    alternativeId: 0,
    boolVal: false,
    text: 'DJ Tools',
    grouping: 'Dance',
  },
  {
    id: 420,
    alternativeId: 0,
    boolVal: false,
    text: 'Dubstep',
    grouping: 'Dance',
  },
  {
    id: 421,
    alternativeId: 0,
    boolVal: false,
    text: 'Electro House',
    grouping: 'Dance',
  },
  {
    id: 422,
    alternativeId: 0,
    boolVal: false,
    text: 'Glitch Hop',
    grouping: 'Dance',
  },
  {
    id: 423,
    alternativeId: 0,
    boolVal: false,
    text: 'Hard Dance',
    grouping: 'Dance',
  },
  {
    id: 424,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Disco / Nu Disco',
    grouping: 'Dance',
  },
  {
    id: 425,
    alternativeId: 0,
    boolVal: false,
    text: "Jungle/Drum'n'bass",
    grouping: 'Dance',
  },
  {
    id: 426,
    alternativeId: 0,
    boolVal: false,
    text: 'Minimal',
    grouping: 'Dance',
  },
  {
    id: 427,
    alternativeId: 0,
    boolVal: false,
    text: 'Progressive House',
    grouping: 'Dance',
  },
  {
    id: 428,
    alternativeId: 0,
    boolVal: false,
    text: 'Psy-Trance',
    grouping: 'Dance',
  },
  {
    id: 429,
    alternativeId: 0,
    boolVal: false,
    text: 'Tech House',
    grouping: 'Dance',
  },
  {
    id: 430,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / 2 Step',
    grouping: 'Dance',
  },
  {
    id: 431,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Acid',
    grouping: 'Dance',
  },
  {
    id: 432,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Big Beat',
    grouping: 'Dance',
  },
  {
    id: 433,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Electro',
    grouping: 'Dance',
  },
  {
    id: 434,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Funky Breaks',
    grouping: 'Dance',
  },
  {
    id: 435,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Hardcore Breaks',
    grouping: 'Dance',
  },
  {
    id: 436,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Nu Skool',
    grouping: 'Dance',
  },
  {
    id: 437,
    alternativeId: 0,
    boolVal: false,
    text: 'Breaks / Progressive',
    grouping: 'Dance',
  },
  {
    id: 438,
    alternativeId: 0,
    boolVal: false,
    text: 'Chill Out / Acid Jazz',
    grouping: 'Dance',
  },
  {
    id: 439,
    alternativeId: 0,
    boolVal: false,
    text: 'Chill Out / Nu-Jazz',
    grouping: 'Dance',
  },
  {
    id: 440,
    alternativeId: 0,
    boolVal: false,
    text: 'Chill Out / Trip-Hop',
    grouping: 'Dance',
  },
  {
    id: 441,
    alternativeId: 0,
    boolVal: false,
    text: 'DJ Tools / Acapellas',
    grouping: 'Dance',
  },
  {
    id: 442,
    alternativeId: 0,
    boolVal: false,
    text: 'DJ Tools / loops',
    grouping: 'Dance',
  },
  {
    id: 443,
    alternativeId: 0,
    boolVal: false,
    text: 'DJ Tools / Reprise',
    grouping: 'Dance',
  },
  {
    id: 444,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass',
    grouping: 'Dance',
  },
  {
    id: 445,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Breakcore',
    grouping: 'Dance',
  },
  {
    id: 446,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Dark Step',
    grouping: 'Dance',
  },
  {
    id: 447,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Drumstep',
    grouping: 'Dance',
  },
  {
    id: 448,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Electro Step',
    grouping: 'Dance',
  },
  {
    id: 449,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Experimental',
    grouping: 'Dance',
  },
  {
    id: 450,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Hardstep',
    grouping: 'Dance',
  },
  {
    id: 451,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Intelligent',
    grouping: 'Dance',
  },
  {
    id: 452,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Jump Up',
    grouping: 'Dance',
  },
  {
    id: 453,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Liquid',
    grouping: 'Dance',
  },
  {
    id: 454,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Old School',
    grouping: 'Dance',
  },
  {
    id: 455,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Ragga',
    grouping: 'Dance',
  },
  {
    id: 456,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Techstep / Electrostep',
    grouping: 'Dance',
  },
  {
    id: 457,
    alternativeId: 0,
    boolVal: false,
    text: 'Drum & Bass / Vocal',
    grouping: 'Dance',
  },
  {
    id: 458,
    alternativeId: 0,
    boolVal: false,
    text: 'Electronica / Bass Music',
    grouping: 'Dance',
  },
  {
    id: 459,
    alternativeId: 0,
    boolVal: false,
    text: 'Electronica / Indie',
    grouping: 'Dance',
  },
  {
    id: 460,
    alternativeId: 0,
    boolVal: false,
    text: 'Electronica / Juke',
    grouping: 'Dance',
  },
  {
    id: 461,
    alternativeId: 0,
    boolVal: false,
    text: 'Electronica / LeftField',
    grouping: 'Dance',
  },
  {
    id: 462,
    alternativeId: 0,
    boolVal: false,
    text: 'Funk / R&B / Jazz',
    grouping: 'Dance',
  },
  {
    id: 463,
    alternativeId: 0,
    boolVal: false,
    text: 'Funk / R&B / World',
    grouping: 'Dance',
  },
  {
    id: 464,
    alternativeId: 0,
    boolVal: false,
    text: 'Hard Dance / Nu-NRG',
    grouping: 'Dance',
  },
  {
    id: 465,
    alternativeId: 0,
    boolVal: false,
    text: 'Hard Dance / Hard Style',
    grouping: 'Dance',
  },
  {
    id: 466,
    alternativeId: 0,
    boolVal: false,
    text: 'Hardcore / Hard Techno / Gabber',
    grouping: 'Dance',
  },
  {
    id: 467,
    alternativeId: 0,
    boolVal: false,
    text: 'Hardcore / Hard Techno / Happy',
    grouping: 'Dance',
  },
  {
    id: 468,
    alternativeId: 0,
    boolVal: false,
    text: 'Hip-Hop / Grime',
    grouping: 'Dance',
  },
  {
    id: 469,
    alternativeId: 0,
    boolVal: false,
    text: 'Hip-Hop / Trap',
    grouping: 'Dance',
  },
  {
    id: 470,
    alternativeId: 0,
    boolVal: false,
    text: 'House / Disco',
    grouping: 'Dance',
  },
  {
    id: 471,
    alternativeId: 0,
    boolVal: false,
    text: 'House / Tribal',
    grouping: 'Dance',
  },
  {
    id: 472,
    alternativeId: 0,
    boolVal: false,
    text: 'House / Vocal House',
    grouping: 'Dance',
  },
  {
    id: 473,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie DanceNu Disco',
    grouping: 'Dance',
  },
  {
    id: 474,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Dance / Nu Disco / Indie Dance',
    grouping: 'Dance',
  },
  {
    id: 475,
    alternativeId: 0,
    boolVal: false,
    text: 'Indie Dance / Nu Disco / Nu Disco',
    grouping: 'Dance',
  },
  {
    id: 476,
    alternativeId: 0,
    boolVal: false,
    text: 'Progressive House / Progno',
    grouping: 'Dance',
  },
  {
    id: 477,
    alternativeId: 0,
    boolVal: false,
    text: 'Tech House / Acid',
    grouping: 'Dance',
  },
  {
    id: 478,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno / Acid',
    grouping: 'Dance',
  },
  {
    id: 479,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno / Detroit Techno',
    grouping: 'Dance',
  },
  {
    id: 480,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno / Dub Techno',
    grouping: 'Dance',
  },
  {
    id: 481,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno / Ghetto-Tek',
    grouping: 'Dance',
  },
  {
    id: 482,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno / Hard Techno',
    grouping: 'Dance',
  },
  {
    id: 483,
    alternativeId: 0,
    boolVal: false,
    text: 'Techno / Melodic',
    grouping: 'Dance',
  },
  {
    id: 484,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance / Acid',
    grouping: 'Dance',
  },
  {
    id: 485,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance / Epic',
    grouping: 'Dance',
  },
  {
    id: 486,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance / Hard',
    grouping: 'Dance',
  },
  {
    id: 487,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance / Progressive',
    grouping: 'Dance',
  },
  {
    id: 488,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance / Tech',
    grouping: 'Dance',
  },
  {
    id: 489,
    alternativeId: 0,
    boolVal: false,
    text: 'Trance / Tribal',
    grouping: 'Dance',
  },
  {
    id: 490,
    alternativeId: 0,
    boolVal: false,
    text: 'Africa',
    grouping: 'Hip Hop',
  },
  {
    id: 491,
    alternativeId: 0,
    boolVal: false,
    text: 'Alternative Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 492,
    alternativeId: 0,
    boolVal: false,
    text: 'Asia',
    grouping: 'Hip Hop',
  },
  {
    id: 493,
    alternativeId: 0,
    boolVal: false,
    text: 'Australia',
    grouping: 'Hip Hop',
  },
  {
    id: 494,
    alternativeId: 0,
    boolVal: false,
    text: 'Caribbean',
    grouping: 'Hip Hop',
  },
  {
    id: 495,
    alternativeId: 0,
    boolVal: false,
    text: 'Dirty South',
    grouping: 'Hip Hop',
  },
  {
    id: 496,
    alternativeId: 0,
    boolVal: false,
    text: 'East Coast Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 497,
    alternativeId: 0,
    boolVal: false,
    text: 'Europe',
    grouping: 'Hip Hop',
  },
  {
    id: 498,
    alternativeId: 0,
    boolVal: false,
    text: 'France',
    grouping: 'Hip Hop',
  },
  {
    id: 499,
    alternativeId: 0,
    boolVal: false,
    text: 'Gangsta Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 500,
    alternativeId: 0,
    boolVal: false,
    text: 'Hardcore Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 501,
    alternativeId: 0,
    boolVal: false,
    text: 'Hawaii',
    grouping: 'Hip Hop',
  },
  {
    id: 502,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Hip Hop',
  },
  {
    id: 503,
    alternativeId: 0,
    boolVal: false,
    text: 'Japan',
    grouping: 'Hip Hop',
  },
  {
    id: 504,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 505,
    alternativeId: 0,
    boolVal: false,
    text: 'Middle East',
    grouping: 'Hip Hop',
  },
  {
    id: 506,
    alternativeId: 0,
    boolVal: false,
    text: 'North America',
    grouping: 'Hip Hop',
  },
  {
    id: 507,
    alternativeId: 0,
    boolVal: false,
    text: 'Old School Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 508,
    alternativeId: 0,
    boolVal: false,
    text: 'Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 509,
    alternativeId: 0,
    boolVal: false,
    text: 'South Africa',
    grouping: 'Hip Hop',
  },
  {
    id: 510,
    alternativeId: 0,
    boolVal: false,
    text: 'South America',
    grouping: 'Hip Hop',
  },
  {
    id: 511,
    alternativeId: 0,
    boolVal: false,
    text: 'Underground Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 512,
    alternativeId: 0,
    boolVal: false,
    text: 'West Coast Rap',
    grouping: 'Hip Hop',
  },
  {
    id: 513,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese Hip-Hop',
    grouping: 'Hip Hop',
  },
  {
    id: 514,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean Hip-Hop',
    grouping: 'Hip Hop',
  },
  {
    id: 515,
    alternativeId: 0,
    boolVal: false,
    text: 'Grime',
    grouping: 'Hip Hop',
  },
  {
    id: 516,
    alternativeId: 0,
    boolVal: false,
    text: "Christmas: Children's",
    grouping: 'Holiday',
  },
  {
    id: 517,
    alternativeId: 0,
    boolVal: false,
    text: 'Cool',
    grouping: 'Jazz',
  },
  {
    id: 518,
    alternativeId: 0,
    boolVal: false,
    grouping: 'K-Pop',
  },
  {
    id: 519,
    alternativeId: 0,
    boolVal: false,
    text: 'Alternativo & Rock Latino',
    grouping: 'Latino',
  },
  {
    id: 520,
    alternativeId: 0,
    boolVal: false,
    text: 'Baladas y Boleros',
    grouping: 'Latino',
  },
  {
    id: 521,
    alternativeId: 0,
    boolVal: false,
    text: 'Contemporary Latin',
    grouping: 'Latino',
  },
  {
    id: 522,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin Jazz',
    grouping: 'Latino',
  },
  {
    id: 523,
    alternativeId: 0,
    boolVal: false,
    text: 'Pop Latino',
    grouping: 'Latino',
  },
  {
    id: 524,
    alternativeId: 0,
    boolVal: false,
    text: 'Raíces',
    grouping: 'Latino',
  },
  {
    id: 525,
    alternativeId: 0,
    boolVal: false,
    text: 'Reggaeton y Hip-Hop',
    grouping: 'Latino',
  },
  {
    id: 526,
    alternativeId: 0,
    boolVal: false,
    text: 'Regional Mexicano',
    grouping: 'Latino',
  },
  {
    id: 527,
    alternativeId: 0,
    boolVal: false,
    text: 'Salsa y Tropical',
    grouping: 'Latino',
  },
  {
    id: 528,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Latino',
  },
  {
    id: 529,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Opera',
  },
  {
    id: 530,
    alternativeId: 0,
    boolVal: false,
    text: 'Cantopop/HK-Pop',
    grouping: 'Pop',
  },
  {
    id: 531,
    alternativeId: 0,
    boolVal: false,
    grouping: 'Ram Drum & Bass',
  },
  {
    id: 532,
    alternativeId: 0,
    boolVal: false,
    text: 'Dancehall',
    grouping: 'Reggae',
  },
  {
    id: 533,
    alternativeId: 0,
    boolVal: false,
    text: 'Vocal Jazz',
    grouping: 'Vocal',
  },
  {
    id: 534,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro-Beat',
    grouping: 'World',
  },
  {
    id: 535,
    alternativeId: 0,
    boolVal: false,
    text: 'Afro-Pop',
    grouping: 'World',
  },
  {
    id: 536,
    alternativeId: 0,
    boolVal: false,
    text: 'Arabesque',
    grouping: 'World',
  },
  {
    id: 537,
    alternativeId: 0,
    boolVal: false,
    text: 'Turkish',
    grouping: 'World',
  },
];
