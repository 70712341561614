import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { AuthenticationProvider, BuffProvider, DatadogProvider, EnvVarsProvider } from './app/providers';
import CatalogProvider from './app/providers/CatalogProvider/CatalogProvider';
import TenantProvider from './app/providers/TenantProvider/TenantProvider';
import { AppRoutes } from './app/routes';

import 'remixicon/fonts/remixicon.css';
import '@utopia-internal/ds-fonts/apk-protocol/index.css';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <EnvVarsProvider>
        <BuffProvider>
          <AuthenticationProvider>
            <DatadogProvider isEnabled={import.meta.env.PROD}>
              <TenantProvider>
                <CatalogProvider>
                  <AppRoutes />
                </CatalogProvider>
              </TenantProvider>
            </DatadogProvider>
          </AuthenticationProvider>
        </BuffProvider>
      </EnvVarsProvider>
    </StrictMode>
  );
}
