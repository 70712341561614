import { ReactNode, useCallback, useContext, useState } from 'react';

import {
  Avatar,
  Box,
  Button,
  HStack,
  MenuItemOption,
  MenuOptionGroup,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { AppNav } from '@utopia-internal/ds-chakra';
import { NavLink, useLocation } from 'react-router-dom';

import { Tenant } from '../../../types';
import { EnvVarsContext, EnvVarsData, useAuth, useTenantContext } from '../../providers';
import { isDevOrLocalEnv } from '../../utils/isDevOrLocalEnv';
import { ApplicationMenu, ApplicationMenuItem } from '../ApplicationMenu';

const APP_NAME = 'Repertoire';

type RouteKey = 'track' | 'release';

type AppNavLink = {
  label: string;
  route: string;
  key: RouteKey;
};

const getLinks = (repertoireEnvironment: string): AppNavLink[] => {
  const links: AppNavLink[] = [{ label: 'Tracks', route: 'tracks', key: 'track' }];

  // Releases are not ready on the BE-side so we hide it for now
  // See more https://utopia-music.atlassian.net/browse/RMS-372
  if (isDevOrLocalEnv(repertoireEnvironment)) {
    links.push({ label: 'Releases', route: 'releases', key: 'release' });
  }

  return links;
};

const isSelected = (key: RouteKey, pathName: string): boolean =>
  pathName.includes(key) || (pathName === '/tracks' && key === 'track');

const applicationName = ({ musicMonitoringUrl }: { musicMonitoringUrl: string }): ReactNode => {
  if (musicMonitoringUrl) {
    const items: ApplicationMenuItem[] = [
      {
        label: 'Music Monitoring',
        action: () => {
          if (musicMonitoringUrl) {
            window.location.href = musicMonitoringUrl;
          }
        },
      },
      {
        label: APP_NAME,
        action: () => {},
        selected: true,
      },
    ];
    return <ApplicationMenu applicationName={APP_NAME} menuItems={items} />;
  }
  return APP_NAME;
};

export const AppNavigation = () => {
  const auth = useAuth();
  const location = useLocation();
  const {
    variables: { musicMonitoringUrl, repertoireEnvironment, authMode },
  } = useContext<EnvVarsData>(EnvVarsContext);

  const [links] = useState(getLinks(repertoireEnvironment));
  const disabledColor = useColorModeValue('gray.200', 'gray.500');
  const { tenantName, setTenantName, tenantDisplayName, setTenantDisplayName, tenants } = useTenantContext();

  const onLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const onSelectedTenant = useCallback(
    (currentSelectedTenant: Tenant) => () => {
      if (authMode === 'auth0') {
        // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
        //
        // For auth0 we need to login again to get the new token for a different organization / tenant. For auth0 the
        // user will always have exactly an org_id / tenant so we don't need to use local storage for saving it.
        auth.login(currentSelectedTenant.auth0Organization);
      } else {
        localStorage.setItem('tenantName', currentSelectedTenant.name);
        localStorage.setItem('tenantDisplayName', currentSelectedTenant.displayName);
        setTenantName(currentSelectedTenant.name);
        setTenantDisplayName(currentSelectedTenant.displayName);
      }
    },
    [auth, authMode, setTenantDisplayName, setTenantName]
  );

  return (
    <AppNav
      applicationName={applicationName({ musicMonitoringUrl })}
      userMenu={{
        name: auth.user?.name,
        context: tenantDisplayName,
        onLogout,
        items: (
          <MenuOptionGroup type="radio" title="Tenants">
            <Box maxHeight="17rem" overflowY="auto">
              {tenants
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((tenantItem) => {
                  const isChecked = tenantName === tenantItem.name;
                  return (
                    <MenuItemOption
                      id={tenantItem.name}
                      key={tenantItem.name}
                      onClick={onSelectedTenant(tenantItem)}
                      isChecked={isChecked}
                    >
                      <HStack>
                        <Avatar
                          bg={isChecked ? 'cyan.400' : disabledColor}
                          color="black"
                          size="xs"
                          name={tenantItem.displayName}
                        />

                        <Text>{tenantItem.displayName}</Text>
                      </HStack>
                    </MenuItemOption>
                  );
                })}
            </Box>
          </MenuOptionGroup>
        ),
      }}
    >
      <Stack spacing="1">
        {links.map((link) => (
          <Button
            justifyContent="flex-start"
            sx={{ fontWeight: isSelected(link.key, location.pathname) ? 'bold' : 'normal' }}
            key={link.route}
            as={NavLink}
            to={link.route}
            variant="nav"
            size="sm"
          >
            {link.label}
          </Button>
        ))}
      </Stack>
    </AppNav>
  );
};
