import { useCallback } from 'react';

import { GridItem, Grid, Button, useColorModeValue } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CancelWithConfirmation } from '../CancelWithConfirmation';

type ReleaseFooterProps = {
  goToStep: React.Dispatch<React.SetStateAction<number>>;
  isDirty: boolean;
  isFirstPage?: boolean;
  handleBack?: () => void;
};

export const ReleaseFooter = ({ goToStep, isDirty, isFirstPage = false, handleBack }: ReleaseFooterProps) => {
  const navigate = useNavigate();
  const buttonBgColor = useColorModeValue('white', 'black');

  const handleReview = useCallback(() => {
    goToStep(3);
    navigate({
      search: '',
    });
  }, [goToStep, navigate]);

  const [searchParams] = useSearchParams();
  const isUpdateMode = Boolean(searchParams.get('updateMode'));
  return (
    <Grid templateColumns="repeat(5,1fr)" gap={4} marginTop="20px">
      {isUpdateMode ? (
        <>
          <GridItem colSpan={2} h="10">
            <Button bgColor="neutral.600" onClick={handleReview}>
              Back to Review
            </Button>
          </GridItem>
          <GridItem colStart={6} colEnd={8}>
            <Button bgColor="brand.300" isDisabled={!isDirty} type="submit">
              Update
            </Button>
          </GridItem>
        </>
      ) : (
        <>
          <GridItem colSpan={2} h="10">
            <CancelWithConfirmation variant="release" />
          </GridItem>
          {!isFirstPage && (
            <GridItem colStart={6}>
              <Button
                border="1px"
                borderColor="brand.300"
                borderStyle="solid"
                bgColor={buttonBgColor}
                color="brand.300"
                onClick={handleBack}
                className="back-btn"
              >
                Back
              </Button>
            </GridItem>
          )}
          <GridItem colEnd={8}>
            <Button bgColor="brand.300" type="submit" className="next-btn">
              Next
            </Button>
          </GridItem>
        </>
      )}
    </Grid>
  );
};
