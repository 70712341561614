import { useContext, useEffect, useState } from 'react';

import { ListTenantsResponse } from '@buf/utopia_repertoire.bufbuild_es/utopia/tenant/v1alpha/tenant_service_pb';

import { Tenant } from '../../../types';
import { useAuth } from '../../providers/AuthenticationProvider';
import { BuffContext, BuffContextValue } from '../../providers/BuffProvider';
import { useErrorHandler } from '../useErrorHandler';

export const useListTenants = () => {
  const auth = useAuth();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [hasError, setHasError] = useState(false);
  const { tenantClient } = useContext<BuffContextValue>(BuffContext);
  const { handleInternalServerError } = useErrorHandler();

  useEffect(() => {
    let ignore = false;
    const listTenantRequest = { orderBy: 'display_name' };
    (async () => {
      const headers = new Headers();
      // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
      if (auth.legacyGetActiveAccessToken) {
        const token = await auth.legacyGetActiveAccessToken();
        headers.set('Authorization', `Bearer ${token}`);
      }
      // NOTE this is here because this hook is triggered when we log out (for some reason) and there is no point in
      // getting the tenants if we are logged out.
      if (!auth.isAuthenticated()) {
        return;
      }
      const tenantsData: ListTenantsResponse | undefined = await tenantClient?.listTenants(listTenantRequest, {
        headers,
      });
      const tenantsNew: Tenant[] =
        tenantsData?.tenants.map((e) => ({
          displayName: e.displayName,
          name: e.name,
          auth0Organization: e.auth0Organization,
        })) || [];
      if (!ignore) {
        setTenants(tenantsNew);
      }
    })().catch((err) => {
      setHasError(true);
      handleInternalServerError(err, 'Something went wrong', 'Failed to fetch tenants', listTenantRequest);
    });

    return () => {
      ignore = true;
    };
  }, [auth, tenantClient, handleInternalServerError]);

  return { tenants, hasError };
};
