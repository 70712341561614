import { Button, Grid, GridItem, Heading, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const Release = () => {
  const navigate = useNavigate();
  const handleNewRelease = () => {
    navigate('/releases/add');
  };
  return (
    <>
      <Heading>Releases</Heading>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <GridItem>
          <Spacer />
        </GridItem>
        <GridItem justifyContent="flex-end" display="flex">
          <Button onClick={handleNewRelease} backgroundColor="brand.300" className="add-release-btn">
            Add Release
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};
