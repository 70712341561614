/**
 * Checks for fresh data over a given interval by periodically making API requests to a server
 *
 * @param apiRequest The API request that is supposed to be executed over a given interval
 * @param validate The function that matches the data to end the poll. Return 'true' to end the poll. Return 'false' to keep polling
 * @param interval Time in milliseconds to wait between polls
 * @param maxAttempts Max number of poll attempts
 * @returns A poll function result
 */
export const poll = async <T>(
  apiRequest: (...args: Array<unknown>) => Promise<T | undefined>,
  validate: (apiRequestResult: T | undefined) => boolean,
  maxAttempts: number | undefined,
  interval = 1000 // 1 second
) => {
  let attempts = 0;

  // eslint-disable-next-line consistent-return
  const executePoll = async (resolve: (arg: unknown) => void, reject: (arg: Error) => void) => {
    // Execute the API request
    const result: T | undefined = await apiRequest();
    // Increment the counter
    attempts += 1;

    // Validate the result
    if (validate(result)) {
      // Resolve the API response result
      return resolve(result);
    }
    // Inspect the counter
    if (maxAttempts && attempts === maxAttempts) {
      // Reject the result
      return reject(new Error('Exceeded max attempts'));
    }
    // If the result didn't pass the validation function and it didn't reach max amount of attempts, use execute the function again in given interval
    setTimeout(executePoll, interval, resolve, reject);
  };

  // Return the poll function wrapped into a promise
  return new Promise(executePoll);
};
