import { Text, HStack, Button, useColorModeValue, VStack } from '@chakra-ui/react';

import { UtopiaDiscSvg } from '../components';

export const NoTenantFound = () => {
  const iconTextColor = useColorModeValue('white', 'neutral.900');
  return (
    <HStack>
      <VStack alignItems="start">
        <Text fontSize="4xl" fontWeight="bold">
          Looks like you have not signed up for this service yet.
        </Text>
        <Text fontSize="2xl" fontWeight="bold">
          Get started with Repertoire and Music Mointoring by contacting sales.
        </Text>
        {window.zE && (
          <Button
            backgroundColor="brand.300"
            textColor={iconTextColor}
            onClick={() => {
              window.zE?.activate();
            }}
          >
            Contact Us
          </Button>
        )}
      </VStack>
      <UtopiaDiscSvg height="500" width="500" />
    </HStack>
  );
};
