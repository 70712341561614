import { ListTracksResponse } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/track_service_pb';
import { TrackService } from '@buf/utopia_repertoire.connectrpc_es/utopia/repertoire/v1alpha/track_service_connect';
import { PromiseClient } from '@connectrpc/connect';

import { GetTracksData, GetTracksOptions, TracksData } from '../../../types';
import { tracksMapper } from '../../mappers';

export const getTracks = async (
  token: string,
  tenantName: string,
  trackClient?: PromiseClient<typeof TrackService>,
  options: GetTracksOptions = {}
): Promise<GetTracksData> => {
  const { pageToken, pageSize, filter, orderBy, sortOrder } = options;
  // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
  const headers = new Headers();
  if (token && token !== '') headers.set('Authorization', `Bearer ${token}`);
  const tracksData: ListTracksResponse | undefined = await trackClient?.listTracks(
    {
      parent: tenantName,
      pageToken,
      pageSize,
      filter,
      orderBy: orderBy ? `${orderBy} ${sortOrder}` : '',
    },
    { headers }
  );
  const { tracks: tracksNew = [], nextPageToken = '' } = tracksData ?? {};
  const tracks: Array<TracksData> = tracksMapper(tracksNew);

  return {
    tracks,
    nextPageToken,
  };
};
