import { Catalog } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/catalog_pb';

const DEFAULT_CATALOG_FLAG = 'Default';

export const getDefaultCatalog = (catalogs: Array<Catalog>): Catalog => {
  const defaultCatalog: Catalog | undefined = catalogs.find(({ displayName }) => DEFAULT_CATALOG_FLAG === displayName);
  return (
    defaultCatalog ||
    ({
      name: '',
      displayName: '',
    } as Catalog)
  );
};
