import { Heading, useColorModeValue } from '@chakra-ui/react';

type HeadingProps = {
  text: string;
};

export const StepHeading = ({ text }: HeadingProps) => {
  const headingColor = useColorModeValue('neutral.800', 'neutral.100');
  return (
    <Heading as="h1" size="3xl" color={headingColor}>
      {text}
    </Heading>
  );
};
