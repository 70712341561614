import { ComponentProps } from 'react';

import { Box, Button, IconButton, SystemStyleObject } from '@chakra-ui/react';

export interface AlbumArtProps {
  src: string;
  onClose: ComponentProps<typeof IconButton>['onClick'];
  width?: number;
  height?: number;
  imageSx?: SystemStyleObject;
  closeBtnSx?: SystemStyleObject;
}

export const AlbumArt = (props: AlbumArtProps) => {
  const { src, onClose, width = 200, height = 200, imageSx = {}, closeBtnSx = {} } = props;
  return (
    <>
      {/* Because of opacity usage the following black color div covers the parent component content */}
      <Box
        width={width}
        height={height}
        backgroundColor="black"
        sx={{
          position: 'absolute',
          marginTop: '0 !important',
        }}
      />
      <Box
        borderRadius={6}
        as="img"
        width={width}
        height={height}
        src={src}
        sx={{
          ...imageSx,
          // We need to override the default (Chakra) styles
          marginTop: '0 !important',
          opacity: 0.6,
        }}
      />
      <Button
        onClick={onClose}
        sx={{
          ...closeBtnSx,
          position: 'absolute',
          border: '1px solid black',
          backgroundColor: 'transparent',
          color: 'white',
          '&:hover': {
            backgroundColor: 'white',
            color: 'black',
            borderColor: 'white',
          },
        }}
      >
        Remove Image
      </Button>
    </>
  );
};
