import { useContext, useMemo } from 'react';

import { Spinner, Text } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import {
  MockedAuthenticationProvider,
  PublicAuthenticationProvider,
  UtopiaSsoUser,
  useAuth as useKeycloakAuth,
} from '@utopia-internal/iam-auth-provider';

import {
  Auth,
  Auth0AuthenticationProvider,
  MockedAuth0AuthenticationProvider,
  useAuth0,
  useMockedAuth0,
} from '../Auth0AuthenticationProvider';
import { EnvVarsContext, EnvVarsData } from '../EnvVarsProvider';

export const login = async (authInstance: UtopiaSsoUser) => {
  await authInstance?.login({ redirectUri: window.location.origin });
};

export const logout = async (authInstance: UtopiaSsoUser) => {
  await authInstance?.logout({ redirectUri: window.location.origin });
};

const renderLoadingComponent = (error?: string) => {
  if (error) {
    datadogRum.addError(error);
  }

  return (
    <div>
      {error ? (
        <Text>Failed to authenticate due to: {error}</Text>
      ) : (
        // SOMEDAY: We need the better way for rendering the loading state
        <Spinner color="secondary" />
      )}
    </div>
  );
};

const getKeyCloakContext = (auth: UtopiaSsoUser): Auth => {
  const profile = auth.getProfile();
  return {
    isAuthReady: true,
    isAuthenticated: () => auth.isAuthenticated(),
    user: profile
      ? {
          name: profile.fullName,
          email: profile.email,
          org_id: 'legacy',
          id: profile.id,
        }
      : undefined,
    orgs: [],
    login: () => auth.login({ redirectUri: window.location.origin }),
    logout: () => auth.logout({ redirectUri: window.location.origin }),
    legacyGetActiveAccessToken: () => auth.getActiveAccessToken(),
    legacyHasRole: (role: string) => auth.hasRealmRole(role),
  };
};

export interface AuthenticationProviderProps {
  children: React.ReactNode;
}

// TODO https://utopia-music.atlassian.net/browse/RMS-728 clean up this component once we've removed support for
// keycloak.
export const useAuth = (): Auth => {
  const { variables } = useContext<EnvVarsData>(EnvVarsContext);
  let kcAuth: UtopiaSsoUser | null = null;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    kcAuth = useKeycloakAuth();
  } catch (e) {
    // sssshhhh
  }
  // This is supposed to be called "APP_VITE_FE_CYPRESS",
  // however, we don't have pipeline support for that at the moment
  // https://utopia-music.atlassian.net/browse/RMS-180
  //
  // This is a temporary workaround to allow us to run Cypress tests. It should be possible to clean this up after we've
  // removed support for keycloak
  //
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const auth0 = import.meta.env['VITE_FE_CYPRESS'] === 'true' ? useMockedAuth0() : useAuth0();
  const authState = useMemo<Auth>(
    () => (variables.authMode !== 'auth0' && kcAuth ? getKeyCloakContext(kcAuth) : auth0),
    [variables.authMode, kcAuth, auth0]
  );

  if (variables.authMode === 'auth0') {
    return auth0;
  }
  return authState;
};

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { children, ...other } = props;
  const { variables } = useContext<EnvVarsData>(EnvVarsContext);
  const { clientId, realm, authUrl } = variables;

  // This is supposed to be called "APP_VITE_FE_CYPRESS",
  // however, we don't have pipeline support for that at the moment
  // https://utopia-music.atlassian.net/browse/RMS-180
  if (import.meta.env['VITE_FE_CYPRESS'] === 'true') {
    if (variables.authMode === 'auth0') {
      return (
        <MockedAuth0AuthenticationProvider
          mockedUser={{
            email: 'utopia.test@gmail.com',
            name: 'Utopia Test',
            org_id: 'org_test',
          }}
        >
          {children}
        </MockedAuth0AuthenticationProvider>
      );
    }

    return (
      <MockedAuthenticationProvider
        data={{
          isAuthenticated: true,
          email: 'utopia.test@gmail.com',
        }}
      >
        {children}
      </MockedAuthenticationProvider>
    );
  }

  if (variables.authMode === 'auth0') {
    return <Auth0AuthenticationProvider>{children}</Auth0AuthenticationProvider>;
  }

  return (
    <PublicAuthenticationProvider
      clientId={clientId}
      realm={realm}
      authUrl={authUrl}
      renderLoadingComponent={renderLoadingComponent}
      {...other}
    >
      {children}
    </PublicAuthenticationProvider>
  );
};
