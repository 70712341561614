import { Box, useSteps } from '@chakra-ui/react';

import { StepTitle } from '../components/StepTitle';
import { ReleaseStepper } from '../components/Stepper';
import { ReleaseWizardProvider } from '../context/ReleaseWizardContext';

import { Step1 } from './releases/Step1';
import { Step2 } from './releases/Step2';
import { Step3 } from './releases/Step3';
import { Step4 } from './releases/Step4';

const steps = [
  { description: 'Basic' },
  { description: 'Release' },
  { description: 'Tracks' },
  { description: 'Review' },
];

export const AddRelease = () => {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const currentStep = (step: number) => {
    switch (step) {
      case 0:
        return <Step1 goToStep={setActiveStep} />;
      case 1:
        return <Step2 goToStep={setActiveStep} />;
      case 2:
        return <Step3 goToStep={setActiveStep} />;
      case 3:
        return <Step4 goToStep={setActiveStep} />;
      default:
        return <Step1 goToStep={setActiveStep} />;
    }
  };

  return (
    <ReleaseWizardProvider>
      <ReleaseStepper activeStep={activeStep} steps={steps} />
      <Box pt={16} pl={20} pr={20}>
        <StepTitle value="Add a release" />
        {currentStep(activeStep)}
      </Box>
    </ReleaseWizardProvider>
  );
};
