import { useEffect, useContext } from 'react';

import { EnvVarsContext, EnvVarsData, useAuth } from '../../providers';
import { isValidHttpUrl } from '../../utils';

export const ZendeskWidget = () => {
  const { user } = useAuth();

  const {
    variables: { zendeskWidgetUrl },
  } = useContext<EnvVarsData>(EnvVarsContext);

  useEffect(() => {
    // we need to set zendeskWidgetUrl to false for dev & sandbox
    // for prod we set actual zendesk widget url
    const isEnabled = zendeskWidgetUrl !== 'false' && isValidHttpUrl(zendeskWidgetUrl);
    const script = document.createElement('script');
    const onScriptLoad = () => {
      if (window.zE) {
        window.zESettings = {
          webWidget: {
            contactForm: {
              fields: [
                { id: 'name', prefill: { '*': user?.name } },
                {
                  id: 'email',
                  prefill: { '*': user?.email },
                },
              ],
            },
          },
        };
      }
    };

    if (isEnabled) {
      script.type = 'text/javascript';
      script.addEventListener('load', onScriptLoad);

      script.id = 'ze-snippet';
      script.src = zendeskWidgetUrl;
      document.body.appendChild(script);
    }

    return () => {
      if (isEnabled) {
        script.removeEventListener('load', onScriptLoad);
        document.body.removeChild(script);
      }
    };
  }, [user?.email, user?.name, zendeskWidgetUrl]);

  return null;
};
