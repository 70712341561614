const VITE_ENV_PREFIX = 'VITE_';
const VITE_ENV_PREFIX_LEN = VITE_ENV_PREFIX.length;

/**
 * Removes `VITE_APP_` prefix from the environment variables
 */
export const readConfigFromMeta = (config: Record<string, string>): Record<string, string> => {
  return Object.keys(config).reduce((acc, key) => {
    if (key.startsWith(VITE_ENV_PREFIX)) {
      acc[key.substring(VITE_ENV_PREFIX_LEN)] = config[key] ?? '';
    }
    return acc;
  }, {} as Record<string, string>);
};
