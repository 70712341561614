import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';

import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  FormErrorMessage,
  Select,
  Button,
  Switch,
  HStack,
  Tooltip,
  IconButton,
  Icon,
  Text,
  FormHelperText,
  useColorModeValue,
} from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as AddIcon } from 'remixicon/icons/System/add-line.svg';
import { ReactComponent as CloseLine } from 'remixicon/icons/System/close-line.svg';
import { ReactComponent as AlertIcon } from 'remixicon/icons/System/error-warning-fill.svg';

import { AlbumArt } from '../../components/AlbumArt';
import { FileUpload } from '../../components/FileUpload';
import { ReleaseFooter } from '../../components/ReleaseFooter';
import { StepHeading } from '../../components/StepHeading';
import { StepTitle } from '../../components/StepTitle';
import { useReleaseForm } from '../../context/ReleaseWizardContext';
import { getGenre, getSubGenre } from '../../utils/getGenre';
import { selectOptionsLanguage } from '../../utils/selectedOptionsLanguage';

import { ReleaseBasicData } from './types';

const MAX_LIMIT_FOR_ARTIST = 20;

const UploadBox = ({
  albumArtSrc,
  onClose,
}: {
  albumArtSrc: string;
  onClose: ComponentProps<typeof IconButton>['onClick'];
}) => {
  return (
    <Box
      mt={3}
      sx={{
        position: 'relative',
        width: '200px',
        height: '200px',
      }}
    >
      <AlbumArt
        src={albumArtSrc}
        onClose={onClose}
        closeBtnSx={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: 'auto',
          // The "Remove Image" button width
          width: '143px',
        }}
      />
    </Box>
  );
};

export const Step1 = ({ goToStep }: { goToStep: React.Dispatch<React.SetStateAction<number>> }) => {
  const { step1 } = useReleaseForm();
  const {
    register,
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
    getValues,
  } = useForm<ReleaseBasicData>({ defaultValues: { ...step1 }, mode: 'onBlur' });
  const navigate = useNavigate();
  const { fields, append, remove } = useFieldArray({ name: 'artistDetails', control });

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const audioFileRegister = register('audioFile');

  const [searchParams] = useSearchParams();
  const isUpdateMode = Boolean(searchParams.get('updateMode'));

  const { albumArtSrc: albumArtSrcDefault = '' } = getValues();
  const [albumArtSrc, setAlbumArtSrc] = useState<string>(albumArtSrcDefault);

  const { saveStepInformation } = useReleaseForm();
  const onSubmit = (data: ReleaseBasicData) => {
    saveStepInformation('step1', {
      ...data,
      albumArtSrc,
    });

    // when update mode then empty query params
    if (isUpdateMode) {
      navigate({
        search: '',
      });
    }

    goToStep(isUpdateMode ? 3 : 1);
  };

  const onDrop = useCallback(
    (fileList: FileList) => {
      // SOMEDAY: Maybe there is a better solution for that 🤔💭
      const albumArtSrcNew: string = URL.createObjectURL(fileList[0] as Blob);
      setAlbumArtSrc(albumArtSrcNew);
      setValue('audioFile', fileList);
    },
    [setValue]
  );

  const languageOptions = useMemo(() => selectOptionsLanguage(), []);

  const listOfGenre = useMemo(() => getGenre(), []);
  const genreValue = watch('genre');

  const listofSubGenre = useMemo(() => getSubGenre(genreValue), [genreValue]);
  const buttonBgColor = useColorModeValue('blue.300', 'blue.600');

  return (
    <>
      <StepHeading text="Basic" />
      <Box mt="20px">
        {/* eslint-disable-next-line no-void */}
        <form onSubmit={(event) => void handleSubmit(onSubmit)(event)} noValidate>
          <Box display="flex" flexDirection="column">
            <Grid templateColumns="repeat(2, 50%)" gap={3}>
              <GridItem>
                <FormControl isRequired isInvalid={Boolean(errors?.releaseTitle)}>
                  <FormLabel fontSize="16px">Release Title</FormLabel>
                  <Input
                    placeholder="Release Title"
                    {...register('releaseTitle', {
                      required: 'Please enter release title',
                      maxLength: { value: 1000, message: 'Release title should not be greater than 1000 characters' },
                    })}
                  />
                  {errors.releaseTitle && <FormErrorMessage>{errors.releaseTitle.message}</FormErrorMessage>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Title Version</FormLabel>
                  <Input
                    placeholder="Live, Accoustic, etc"
                    {...register('titleVersion', {
                      maxLength: { value: 1000, message: 'Title version should not be greater than 1000 characters' },
                    })}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={Boolean(errors?.genre)}>
                  <FormLabel>Genre</FormLabel>
                  <Select
                    {...register('genre', {
                      required: 'Please select Genre',
                    })}
                  >
                    <option hidden disabled value="">
                      Genre
                    </option>
                    {listOfGenre.map((gen) => (
                      <option label={gen.label} key={gen.label}>
                        {gen.label}
                      </option>
                    ))}
                  </Select>
                  {errors.genre && <FormErrorMessage>{errors.genre.message}</FormErrorMessage>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Sub-Genre</FormLabel>
                  <Select {...register('subGenre')} placeholder="Sub-Genre">
                    {listofSubGenre.map((subgenre) => (
                      <option value={subgenre.text} key={subgenre.text}>
                        {subgenre.text}
                      </option>
                    ))}
                  </Select>
                  {genreValue && listofSubGenre.length === 0 && (
                    <FormHelperText>No Sub-Genre available for - {genreValue}</FormHelperText>
                  )}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>Language</FormLabel>
                  <Select {...register('language')} placeholder="Language">
                    {languageOptions.map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </Grid>
            <Grid templateColumns="repeat(2,1 fr)" gap={4}>
              <GridItem>
                <StepTitle value="Artist" />
              </GridItem>
              <GridItem>
                <HStack spacing="6px">
                  <Box marginBottom="10px">
                    <Switch id="various-artists" {...register('variousArtist')} colorScheme="blue" />
                  </Box>
                  <Box>
                    <FormLabel htmlFor="various-artists">Various Artists</FormLabel>
                  </Box>
                  <Box>
                    <Tooltip label="Toggling on hides artists and will mark artist as 'various' ">
                      <IconButton
                        aria-label="alert"
                        variant="unstyled"
                        icon={<AlertIcon fill="currentcolor" height="24px" width="24px" />}
                      />
                    </Tooltip>
                  </Box>
                </HStack>
              </GridItem>
            </Grid>
            {!watch('variousArtist') ? (
              <>
                <Box gap={2}>
                  <FormControl width="47%" isRequired isInvalid={Boolean(errors?.primaryArtist)}>
                    <FormLabel fontSize="16px">Primary Artist</FormLabel>
                    <Input
                      placeholder="Primary Artist"
                      {...register('primaryArtist', {
                        required: 'Please enter primary artist',
                        maxLength: { value: 1000, message: 'Artist name should not be greater than 1000 characters' },
                      })}
                    />
                    {errors.primaryArtist && <FormErrorMessage>{errors.primaryArtist.message}</FormErrorMessage>}
                  </FormControl>
                  {fields.map((field, index) => {
                    return (
                      <Grid key={field.id} templateColumns="1fr 1fr 4%" gap={3} marginTop="16px">
                        <GridItem>
                          <FormControl isRequired isInvalid={Boolean(errors?.artistDetails?.[index]?.artist)}>
                            <Input
                              placeholder="Artist"
                              {...register(`artistDetails.${index}.artist`, {
                                required: 'Please enter artist name or remove the field',
                                maxLength: {
                                  value: 1000,
                                  message: 'Artist name should not be greater than 1000 characters',
                                },
                              })}
                            />
                            {errors?.artistDetails?.[index] && (
                              <FormErrorMessage>{errors?.artistDetails?.[index]?.artist?.message}</FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl isRequired isInvalid={Boolean(errors?.artistDetails?.[index]?.artistRole)}>
                            <Select
                              {...register(`artistDetails.${index}.artistRole`, {
                                required: 'Please enter artist role or remove the field',
                              })}
                              key={index}
                            >
                              <option value="MAIN_ARTIST">Main Artist</option>
                              <option value="FEATURED">Featured</option>
                              <option value="SOLOIST">Soloist</option>
                              <option value="WITH">With</option>
                            </Select>
                            {errors?.artistDetails?.[index] && (
                              <FormErrorMessage>{errors?.artistDetails?.[index]?.artistRole?.message}</FormErrorMessage>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <IconButton
                              aria-label="removeArtist"
                              icon={<CloseLine height={24} width={24} />}
                              fill="neutral.400"
                              onClick={() => remove(index)}
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    );
                  })}
                </Box>
                <Box display="flex" alignItems="center">
                  <Grid>
                    <GridItem>
                      {fields.length <= MAX_LIMIT_FOR_ARTIST ? (
                        <Button
                          variant="ghost"
                          onClick={() =>
                            append({
                              artist: '',
                              artistRole: '',
                            })
                          }
                          className="add-artist-btn"
                        >
                          <Icon as={AddIcon} fill="brand.300" margin="10px" />
                          <Text color="brand.300">Add an artist</Text>
                        </Button>
                      ) : (
                        <Text color="brand.300">{`You have reached a max limit of ${MAX_LIMIT_FOR_ARTIST}`}</Text>
                      )}
                    </GridItem>
                  </Grid>
                </Box>
              </>
            ) : null}

            <Grid>
              <GridItem>
                <StepTitle value="cover art" />
              </GridItem>
              <GridItem>
                {((watch('audioFile') && watch('audioFile')?.length) ?? 0) > 0 ? (
                  <UploadBox
                    albumArtSrc={albumArtSrc}
                    onClose={() => {
                      setAlbumArtSrc('');
                      setValue('audioFile', undefined);
                    }}
                  />
                ) : (
                  <DndProvider backend={HTML5Backend}>
                    <FileUpload
                      onDrop={onDrop}
                      width="200px"
                      height="200px"
                      instructionsText="Drag and drop .jpeg, .png"
                      allowedTypes={['jpeg', 'png']}
                    >
                      {albumArtSrc ? (
                        <AlbumArt
                          src={albumArtSrc}
                          onClose={() => {
                            setAlbumArtSrc('');
                            // Reset the input upload element
                            if (fileInputRef?.current) {
                              fileInputRef.current.value = '';
                            }
                          }}
                          imageSx={{
                            position: 'absolute',
                          }}
                        />
                      ) : (
                        <Button
                          bgColor={buttonBgColor}
                          color="black"
                          onClick={() => {
                            fileInputRef.current?.click();
                          }}
                          sx={{
                            '&:hover': {
                              backgroundColor: 'white',
                            },
                          }}
                        >
                          Choose File
                        </Button>
                      )}
                      <Input
                        type="file"
                        {...audioFileRegister}
                        ref={(instanceRef) => {
                          audioFileRegister.ref(instanceRef);
                          fileInputRef.current = instanceRef;
                        }}
                        accept=".jpeg,.png"
                        display="none"
                        onChange={(event) => {
                          // SOMEDAY: Find out maybe there is the better solution 🤔💭
                          const src: string = URL.createObjectURL(event.target.files?.[0] as Blob);
                          setAlbumArtSrc(src);
                        }}
                      />
                    </FileUpload>
                  </DndProvider>
                )}
              </GridItem>
            </Grid>
            <ReleaseFooter isDirty={isDirty} goToStep={goToStep} isFirstPage />
          </Box>
        </form>
      </Box>
    </>
  );
};
