import { VStack, Text, useColorModeValue } from '@chakra-ui/react';

import { UtopiaDiscSvg } from '../components/UtopiaDiscSvg';

export const PageNotFound = () => {
  const textColor = useColorModeValue('neutral.850', 'neutral.100');
  return (
    <VStack mt={20}>
      <Text fontSize="5xl" fontWeight="bold" color={textColor}>
        404
      </Text>
      <UtopiaDiscSvg />
      <Text fontSize="xl" fontWeight="bold" color={textColor}>
        The page you are looking for could not be found
      </Text>
      <Text fontSize="l" color={textColor}>
        Please go back to the homepage.
      </Text>
    </VStack>
  );
};
