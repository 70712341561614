import { useCallback } from 'react';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  Checkbox,
} from '@chakra-ui/react';

import { Territories, TerritoryGroup } from '../../../types';
import { AccordionPanelReview } from '../AccordionPanelReview';

export interface MultiTerritorySelectProps {
  territories: Territories;
  countriesIdsSelected: Set<string>;
  setCountriesIdsSelected: (art: Set<string>) => void;
  territoryToCountry?: Record<TerritoryGroup, Array<string>>;
  review?: boolean;
}

// TODO: Optimize performance for this component https://utopia-music.atlassian.net/browse/RMS-262
export const MultiTerritorySelect = (props: MultiTerritorySelectProps) => {
  const { territories, countriesIdsSelected, territoryToCountry, setCountriesIdsSelected, review = false } = props;

  const handleTerritoryChange = useCallback(
    (ter: TerritoryGroup) => {
      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        const countriesIdsSelectedCopy = new Set(countriesIdsSelected);
        territoryToCountry?.[ter].forEach((t) => {
          if (checked) {
            countriesIdsSelectedCopy.add(t);
          } else {
            countriesIdsSelectedCopy.delete(t);
          }
        });
        setCountriesIdsSelected(countriesIdsSelectedCopy);
      };
      return (e: React.ChangeEvent<HTMLInputElement>) => onChange(e);
    },
    [countriesIdsSelected, setCountriesIdsSelected, territoryToCountry]
  );

  const handleCountryChange = useCallback(
    (label: string) => {
      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        const countriesIdsSelectedCopy = new Set(countriesIdsSelected);
        if (checked && !countriesIdsSelectedCopy.has(label)) {
          countriesIdsSelectedCopy.add(label);
        }
        if (!checked && countriesIdsSelectedCopy.has(label)) {
          countriesIdsSelectedCopy.delete(label);
        }
        setCountriesIdsSelected(countriesIdsSelectedCopy);
      };
      return (e: React.ChangeEvent<HTMLInputElement>) => onChange(e);
    },
    [countriesIdsSelected, setCountriesIdsSelected]
  );

  return (
    <Box display="flex" flexDirection="column">
      {!review && (
        <Checkbox
          onChange={handleTerritoryChange('Worldwide')}
          isChecked={countriesIdsSelected.size === territories?.Worldwide?.length}
          isIndeterminate={
            countriesIdsSelected.size !== 0 && countriesIdsSelected.size !== territories?.Worldwide?.length
          }
          alignSelf="flex-end"
          m="2"
          className="worldwide-checkbox"
        >
          Worldwide
        </Checkbox>
      )}
      <Accordion allowMultiple>
        {Object.keys(territories)
          .filter((ter) => {
            // We don't render "Worldwide" in the main list but render it as a checkbox above the list
            return ter !== 'Worldwide';
          })
          .map((ter) => {
            // TODO: Memoize the calculations here
            const isTerritoryChecked: boolean =
              territoryToCountry?.[ter as TerritoryGroup]?.every((t) => {
                return countriesIdsSelected.has(t);
              }) ?? false;
            const isTerritoryIndeterminate: boolean =
              territoryToCountry?.[ter as TerritoryGroup]?.some((t) => {
                return countriesIdsSelected.has(t);
              }) ?? false;
            // FIXME: Sometimes it doesn't calculate right
            const totalAmount: number = territoryToCountry?.[ter as TerritoryGroup]?.length ?? 0;
            const selectedAmount: number = territories[ter as TerritoryGroup].reduce((selected, country) => {
              const { text: label } = country;
              return countriesIdsSelected.has(label) ? selected + 1 : selected;
            }, 0);

            return (
              <AccordionItem key={ter}>
                <AccordionButton display="flex" justifyContent="space-between">
                  {review ? (
                    <Text>{ter}</Text>
                  ) : (
                    <Checkbox
                      isChecked={isTerritoryChecked}
                      isIndeterminate={!isTerritoryChecked && isTerritoryIndeterminate}
                      onChange={handleTerritoryChange(ter as TerritoryGroup)}
                    >
                      {ter}
                    </Checkbox>
                  )}
                  <Box fontStyle="italic">
                    {selectedAmount !== totalAmount ? `${selectedAmount} / ${totalAmount}` : 'All selected'}
                    <AccordionIcon ml="4" />
                  </Box>
                </AccordionButton>
                {review ? (
                  <AccordionPanelReview
                    territories={territories[ter as TerritoryGroup]}
                    countriesIdsSelected={countriesIdsSelected}
                  />
                ) : (
                  <AccordionPanel display="grid" gap={1} gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))">
                    {territories[ter as TerritoryGroup].map((country) => {
                      const { text: label, id } = country;
                      return (
                        <Checkbox
                          key={id}
                          isChecked={countriesIdsSelected.has(label)}
                          onChange={handleCountryChange(label)}
                        >
                          {label}
                        </Checkbox>
                      );
                    })}
                  </AccordionPanel>
                )}
              </AccordionItem>
            );
          })}
      </Accordion>
    </Box>
  );
};
