import { useEffect } from 'react';

import { Box, Button, Flex, CircularProgress, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { AppNavigation } from './components/AppNavigation';
import { ZendeskWidget } from './components/ZendeskWidget';
import { NoTenantFound } from './pages/NoTenantFound';
import { useTenantContext } from './providers';
import { useAuth } from './providers/AuthenticationProvider';

export const App = () => {
  const auth = useAuth();
  const { hasError: hasErrorWhileLoadingTenants } = useTenantContext();

  useEffect(() => {
    if (auth.isAuthReady && !auth.isAuthenticated()) {
      auth.login();
    }
  }, [auth]);

  if (!auth.isAuthenticated()) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        <CircularProgress size={24} isIndeterminate thickness={6} color="pink.400" />
      </Box>
    );
  }

  return (
    <>
      <ZendeskWidget />
      <Flex flexDirection="row" position="relative">
        <AppNavigation />
        <Box padding={8} width="100%">
          {hasErrorWhileLoadingTenants && (
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="240px"
            >
              <AlertIcon boxSize="50px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="xl">
                Oops, something went wrong...
              </AlertTitle>
              <AlertDescription maxWidth="lg">
                Please try to refresh page and if the issue persists, contact support.
              </AlertDescription>
              {window.zE && (
                <Button
                  mt={2}
                  bgColor="brand.300"
                  onClick={() => {
                    window.zE?.activate();
                  }}
                >
                  Contact
                </Button>
              )}
            </Alert>
          )}
          {!auth.user?.org_id && <NoTenantFound />}
          {auth.user?.org_id && !hasErrorWhileLoadingTenants && <Outlet />}
        </Box>
      </Flex>
    </>
  );
};

export default App;
