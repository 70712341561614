import { Text, useColorModeValue } from '@chakra-ui/react';

type StepTitleProps = {
  value: string;
};

export const StepTitle = ({ value }: StepTitleProps) => {
  const textColor = useColorModeValue('neutral.700', 'neutral.400');

  return (
    <Text casing="uppercase" as="h2" fontSize="xs" marginTop={8} color={textColor}>
      {value}
    </Text>
  );
};
