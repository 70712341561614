import { useRef, ComponentProps, useState, useCallback, useContext } from 'react';

import { Track } from '@buf/utopia_repertoire.bufbuild_es/utopia/repertoire/v1alpha/track_pb';
import {
  Heading,
  Icon,
  Text,
  Box,
  Button,
  Divider,
  FormLabel,
  FormControl,
  Input,
  Select,
  Grid,
  GridItem,
  FormErrorMessage,
  VStack,
  HStack,
  Progress,
  IconButton,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'remixicon/icons/Arrows/arrow-left-line.svg';
import { ReactComponent as HeadphoneIcon } from 'remixicon/icons/Media/headphone-fill.svg';
import { ReactComponent as AddIcon } from 'remixicon/icons/System/add-line.svg';
import { ReactComponent as CloseIcon } from 'remixicon/icons/System/close-fill.svg';
import { ReactComponent as CloseLine } from 'remixicon/icons/System/close-line.svg';

import { TrackMetadata } from '../../types';
import { CancelWithConfirmation, FileUpload } from '../components';
import { useErrorHandler, useIsrcMask, useTrack } from '../hooks';
import { BuffContext, BuffContextValue, CatalogContext, useAuth, useTenantContext } from '../providers';
import { createTrack, createCatalogItem, uploadAudioTrack } from '../services';
import { completeTrack } from '../services/completeTrack';
import { getTrack, validateTrackAudio } from '../services/getTrack';
import { poll } from '../utils';
import { validateFileName } from '../utils/validate/validateFileName';
import { validateISRC, validateIsrcAsync } from '../utils/validateISRC';

const MAX_LIMIT_FOR_ARTIST = 20;
const INVALID_FILE_NAME = 'Invalid file name.';
const REQUIRED_FILE_NAME = 'Please upload audio file for this track.';

const UploadBox = ({
  file,
  onClose,
  isLoading,
}: {
  file?: File;
  onClose: ComponentProps<typeof IconButton>['onClick'];
  isLoading: boolean;
}) => {
  return (
    <HStack width="50%" borderColor="neutral.600" border="1px" borderRadius="6px" px="4" py="3">
      <Icon as={HeadphoneIcon} fill="currentcolor" height="24px" width="24px" />
      <Box width="100%">
        <VStack display="flex" justifyContent="flex-start" alignItems="flex-start">
          <Box fontSize="sm">{file?.name || 'no file name'}</Box>
          {isLoading ? (
            <Progress hasStripe value={64} width="100%" height="10px" colorScheme="blue" borderRadius={0} />
          ) : (
            <Progress value={100} width="100%" height="10px" colorScheme="green" borderRadius={0} />
          )}
        </VStack>
      </Box>
      <IconButton
        aria-label="close"
        variant="unstyled"
        icon={<CloseIcon fill="currentcolor" height="24px" width="24px" />}
        onClick={onClose}
      />
    </HStack>
  );
};

export const AddTrack = () => {
  const buttonBgColor = useColorModeValue('neutral.300', 'neutral.600');
  const buttonColor = useColorModeValue('neutral.900', 'neutral.200');

  const { trackClient, catalogClient } = useContext<BuffContextValue>(BuffContext);
  const { handleInternalServerError } = useErrorHandler();

  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    resetField,
    formState: { errors, isSubmitting },
    trigger,
  } = useForm<TrackMetadata>({
    defaultValues: {
      artistDetails: [],
    },
    mode: 'onTouched',
  });
  const { fields, append, remove } = useFieldArray({ name: 'artistDetails', control });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const audioFileRegister = register('audioFile', {
    validate: {
      validateRequireFile: (files: FileList | undefined) => (files && files.length > 0) || REQUIRED_FILE_NAME,
      validateFileName: (files: FileList | undefined) => {
        if (files) {
          return validateFileName(files[0]?.name) || INVALID_FILE_NAME;
        }
        return INVALID_FILE_NAME;
      },
    },
  });
  // TODO: need to discuss about loader to designers how to show loading state.
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuth();
  const { tenantName } = useTenantContext();
  const { id: catalogId } = useContext(CatalogContext);
  const toast = useToast();
  const { ref: isrcRef } = useIsrcMask();

  const { deleteTrack } = useTrack();

  const isrcRegister = register('isrc', {
    required: 'Please enter ISRC',
    validate: {
      format: validateISRC,
      duplicate: async (isrc = '') => {
        if (trackClient) {
          // TODO https://utopia-music.atlassian.net/browse/RMS-728 Clean up once we remove support for keycloak.
          let token = '';
          if (auth.legacyGetActiveAccessToken) {
            token = await auth.legacyGetActiveAccessToken();
          }
          const filterQuery = `isrcs:${isrc.replaceAll('-', '')}`;
          return validateIsrcAsync(token, tenantName, trackClient, filterQuery);
        }
        return 'Unknown error';
      },
    },
  });

  // TODO https://utopia-music.atlassian.net/browse/RMS-808 this code can now be refactored and much simplified by using
  // the backend support for draft tracks and the audio track upload state instead of polling and trying to rollback.

  /*
    We have a chain of operations here:
    a. Create track
    b. Upload audio
      * if upload audio fails then delete track
      * if upload audio passes then Get track
    c. Get track
      * if get track polling fails then delete track
      * if get track polling passes then track has been created with audio file..
    d. Mark track as complete.
  */
  const onSubmit: SubmitHandler<TrackMetadata> = async (data) => {
    const isrcWithoutDash = data.isrc.replace(/-/g, '');
    setIsLoading(true);
    if (tenantName && catalogId) {
      let token = '';
      if (auth.legacyGetActiveAccessToken) {
        token = await auth.legacyGetActiveAccessToken();
      }
      const createTrackRequest = {
        ...data,
        isrc: isrcWithoutDash,
        trackTitle: data.trackTitle.trim(),
        primaryArtist: data.primaryArtist.trim(),
        artistDetails: data.artistDetails.map((ad) => ({
          ...ad,
          artist: ad.artist.trim(),
        })),
      };
      const trackName = await createTrack(token, createTrackRequest, tenantName, trackClient).catch((error) => {
        handleInternalServerError(error, 'Something went wrong', 'Track has not been created', createTrackRequest);
      });
      if (trackName) {
        try {
          if (data.audioFile && data.audioFile.length > 0) {
            const fileToUpload = data.audioFile[0];
            await uploadAudioTrack(
              token,
              { file: fileToUpload.name, track: trackName },
              fileToUpload,
              trackClient
            ).catch(async (error: Error) => {
              deleteTrack({ token, trackName });
              handleInternalServerError(
                error,
                'Audio upload track failed',
                'Something went wrong during track upload. Please try again.'
              );
              throw error;
            });

            await poll<Track>(() => getTrack(token, trackName, trackClient), validateTrackAudio, 10).catch((error) => {
              deleteTrack({ token, trackName });
              handleInternalServerError(
                error,
                'Something went wrong with the audio file',
                'Please try creating track again, If issue persists then contact customer support.'
              );
              throw error;
            });

            await createCatalogItem(token, { catalogId, trackName }, catalogClient).catch((error) => {
              deleteTrack({ token, trackName });
              handleInternalServerError(error, 'Something went wrong', 'Failed to create catalog item');
              throw error;
            });

            await completeTrack(token, trackName, trackClient).catch((error) => {
              deleteTrack({ token, trackName });
              handleInternalServerError(error, 'Something went wrong', 'Failed to complete track');
              throw error;
            });

            toast({
              title: 'Your track has been added!',
              description:
                'If played on the radio, it could take 72 hours before you see results in the music monitoring dashboard',
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          }
        } catch (error) {
          datadogRum.addError(error);
        } finally {
          reset();
        }
      }
    } else {
      let errorMessage = 'Something went wrong';
      errorMessage = !tenantName ? 'Tenant does not exist' : errorMessage;
      errorMessage = !catalogId ? 'Catalog does not exist' : errorMessage;

      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const onDrop = useCallback(
    async (fileList: FileList) => {
      setValue('audioFile', fileList);
      await trigger('audioFile');
    },
    [setValue, trigger]
  );

  return (
    <>
      <Button onClick={() => navigate(-1)} variant="link" className="back-to-tracks-btn">
        <Box display="flex" alignItems="center" gap={2}>
          <Icon as={BackIcon} border="1px" borderRadius="50%" boxSize={8} fill="currentcolor" />
          <Text>Back to Tracks</Text>
        </Box>
      </Button>
      <Box pt={8} pl={20} pr={32}>
        <Heading marginBottom={8}>Add a Track</Heading>
        {/* eslint-disable-next-line no-void */}
        <form onSubmit={(event) => void handleSubmit(onSubmit)(event)} noValidate>
          <FormControl isInvalid={Boolean(errors?.audioFile)}>
            <FormLabel fontSize="xl">Audio File</FormLabel>
            {((watch('audioFile') && watch('audioFile')?.length) ?? 0) > 0 ? (
              <UploadBox
                file={watch('audioFile')?.[0]}
                onClose={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setValue('audioFile', '');
                  resetField('audioFile');
                }}
                isLoading={false}
              />
            ) : (
              <DndProvider backend={HTML5Backend}>
                <FileUpload
                  onDrop={onDrop}
                  height={180}
                  instructionsText="Drag and drop .wav, .mp3, .flac"
                  allowedTypes={['wav', 'mp3', 'flac']}
                >
                  <Input
                    type="file"
                    {...audioFileRegister}
                    ref={(instanceRef) => {
                      audioFileRegister.ref(instanceRef);
                      fileInputRef.current = instanceRef;
                    }}
                    accept=".mp3,.wav,.flac"
                    display="none"
                  />
                  <Button
                    bgColor={buttonBgColor}
                    color={buttonColor}
                    borderRadius="20px"
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                  >
                    Choose File
                  </Button>
                  <Text color="neutral.900">Max file size: 100 MB</Text>
                </FileUpload>
              </DndProvider>
            )}
            {errors?.audioFile && <FormErrorMessage>{errors?.audioFile?.message}</FormErrorMessage>}
          </FormControl>
          <Divider my={10} />

          <Text fontSize="12px" paddingBottom={2} textTransform="uppercase">
            Metadata
          </Text>
          <Box display="flex" flexDirection="column" gap={2}>
            <Grid templateColumns="1fr 1fr 4%" gap={3}>
              <GridItem>
                <FormControl isRequired isInvalid={Boolean(errors?.isrc)}>
                  <FormLabel fontSize="16px">ISRC</FormLabel>
                  <Input
                    placeholder="CC-XXX-YY-NNNNN (ex:USABC1234501)"
                    {...isrcRegister}
                    ref={(instanceRef) => {
                      isrcRegister.ref(instanceRef);
                      isrcRef.current = instanceRef;
                    }}
                  />
                  {errors.isrc && <FormErrorMessage>{errors.isrc.message}</FormErrorMessage>}
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl isRequired isInvalid={Boolean(errors?.trackTitle)}>
                  <FormLabel fontSize="16px">Track Title</FormLabel>
                  <Input
                    placeholder="Name of the track"
                    {...register('trackTitle', {
                      required: 'Please enter track title',
                      maxLength: { value: 1000, message: 'Track title should not be greater than 1000 characters' },
                      validate: (value) => {
                        return value.trim().length > 0 || 'White spaces not allowed';
                      },
                    })}
                  />
                  {errors.trackTitle && <FormErrorMessage>{errors?.trackTitle?.message}</FormErrorMessage>}
                </FormControl>
              </GridItem>
            </Grid>
            <Text fontSize="12px" textTransform="uppercase" pb={4} pt={4}>
              Artist
            </Text>
            <Box gap={2}>
              <FormControl isRequired isInvalid={Boolean(errors?.primaryArtist)} width="47%">
                <FormLabel fontSize="16px">Primary Artist</FormLabel>
                <Input
                  placeholder="Primary Artist"
                  {...register('primaryArtist', {
                    required: 'Please enter primary artist',
                    maxLength: { value: 1000, message: 'Artist name should not be greater than 1000 characters' },
                    validate: (value) => {
                      return value.trim().length > 0 || 'White spaces not allowed';
                    },
                  })}
                />
                {errors.primaryArtist && <FormErrorMessage>{errors.primaryArtist.message}</FormErrorMessage>}
              </FormControl>
              {fields.map((field, index) => {
                return (
                  <Grid key={field.id} templateColumns="1fr 1fr 4%" gap={3} marginTop="16px">
                    <GridItem>
                      <FormControl isRequired isInvalid={Boolean(errors?.artistDetails?.[index]?.artist)}>
                        <Input
                          placeholder="Artist"
                          {...register(`artistDetails.${index}.artist`, {
                            required: 'Please enter artist',
                            maxLength: {
                              value: 1000,
                              message: 'Artist name should not be greater than 1000 characters',
                            },
                            validate: (value) => {
                              return value.trim().length > 0 || 'White spaces not allowed';
                            },
                          })}
                        />
                        {errors.artistDetails && errors.artistDetails[index] && (
                          <FormErrorMessage>{errors.artistDetails[index]?.artist?.message}</FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isRequired isInvalid={Boolean(errors?.artistDetails?.[index]?.artistRole)}>
                        <Select
                          {...register(`artistDetails.${index}.artistRole`, {
                            required: 'Please select artist role',
                          })}
                          key={index}
                        >
                          <option value="MAIN_ARTIST">Main Artist</option>
                          <option value="FEATURED">Featured</option>
                          <option value="SOLOIST">Soloist</option>
                          <option value="WITH">With</option>
                        </Select>
                        {errors.artistDetails && errors.artistDetails[index] && (
                          <FormErrorMessage>{errors.artistDetails[index]?.artistRole?.message}</FormErrorMessage>
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <Button variant="ghost" onClick={() => remove(index)}>
                          <Icon as={CloseLine} fill="neutral.400" />
                        </Button>
                      </FormControl>
                    </GridItem>
                  </Grid>
                );
              })}
            </Box>

            <Box display="flex" alignItems="center">
              <Grid>
                <GridItem>
                  {fields.length <= MAX_LIMIT_FOR_ARTIST ? (
                    <Button
                      variant="ghost"
                      onClick={() =>
                        append({
                          artist: '',
                          artistRole: '',
                        })
                      }
                    >
                      <Icon as={AddIcon} fill="brand.300" margin="10px" />
                      <Text color="brand.300">Add an artist</Text>
                    </Button>
                  ) : (
                    <Text color="brand.300">{`You have reached a max limit of ${MAX_LIMIT_FOR_ARTIST}`}</Text>
                  )}
                </GridItem>
              </Grid>
            </Box>
          </Box>
          <Divider my={8} />
          <Box display="flex" justifyContent="flex-end" gap={3}>
            {/* <Button onClick={() => navigate(-1)} isDisabled={isSubmitting}>
              Cancel
            </Button> */}
            <CancelWithConfirmation variant="track" />
            <Button bgColor="brand.300" type="submit" isDisabled={isSubmitting} isLoading={isLoading}>
              Create Track
            </Button>
          </Box>
          <br />
        </form>
      </Box>
    </>
  );
};
