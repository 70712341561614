import { useContext } from 'react';

import { ColorMode } from '@chakra-ui/react';
import Cookies from 'universal-cookie';

import { EnvVarsContext, EnvVarsData } from '../../providers/EnvVarsProvider';

type MaybeColorMode = ColorMode | undefined;
interface StorageManager {
  type: 'cookie' | 'localStorage';
  ssr?: boolean;
  get(init?: ColorMode): MaybeColorMode;
  set(value: ColorMode | 'system'): void;
}

const cookies = new Cookies();

const COLOR_MODE = 'app_color_mode';

export const useColorModeStorage = (): StorageManager => {
  const {
    variables: { baseDomain },
  } = useContext<EnvVarsData>(EnvVarsContext);

  return {
    type: 'localStorage',
    get: (init?: ColorMode) => {
      const val = cookies.get(COLOR_MODE) || init;
      return val;
    },
    set: (value: ColorMode | 'system') => {
      if (value !== 'system') {
        cookies.set(COLOR_MODE, value, { domain: baseDomain, sameSite: 'lax', path: '/' });
      }
    },
  };
};
