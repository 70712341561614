import { useIMask } from 'react-imask';

export const useIsrcMask = () => {
  const maskField = useIMask({
    // '`' prevents symbols shift back. Reference: https://imask.js.org/guide.html#masked-pattern
    mask: 'aa-###-`00-00000',
    definitions: {
      '#': /[A-Za-z0-9]/,
    },
    prepare: (str) => str.toUpperCase(),
  });

  return maskField;
};
