import { ColorMode } from '@chakra-ui/react';

// Unfortunately, we don't have 100% support for light and dark modes.
// We use this function for those cases, where we need to set the color manually.
// TODO: Find out the proper return type for this fn
export const activeColor = (mode: ColorMode): string => {
  if (mode === 'dark') {
    return 'gray.400';
  }
  return 'brand.300';
};

export const activeColorBtn = (active: boolean): string => {
  if (active) {
    return 'brand.300';
  }
  return '';
};
