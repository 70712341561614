import { useMemo, useState } from 'react';

import { Icon, Text, Box, VStack, HStack } from '@chakra-ui/react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { ReactComponent as AddCircleLine } from 'remixicon/icons/System/add-circle-line.svg';
import { ReactComponent as UploadFill } from 'remixicon/icons/System/upload-fill.svg';

type Item = {
  dataTransfer: DataTransfer;
  files: File[];
  items: DataTransferItemList;
};

export const FileUpload = ({
  children,
  onDrop,
  height,
  width,
  instructionsText,
  allowedTypes,
}: {
  children: React.ReactNode;
  onDrop: (fileList: FileList) => Promise<void> | void;
  height: React.ComponentProps<typeof Box>['height'];
  width?: React.ComponentProps<typeof Box>['width'];
  instructionsText: string;
  allowedTypes: string[];
}) => {
  const [isValid, setIsValid] = useState(true);
  const allowedFileExtensions = useMemo(() => allowedTypes.map((t) => `.${t}`).join(', '), [allowedTypes]);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      async drop(item: Item) {
        if (item.dataTransfer.files.length === 1) {
          await onDrop(item.dataTransfer.files);
        }
      },
      canDrop(item: Item, monitor: DropTargetMonitor<Item, Promise<void>>) {
        const filename = monitor.getItem().files[0]?.name;
        if (filename) {
          const extensionIndex = filename.lastIndexOf('.');
          const fileExtension = extensionIndex === -1 ? '' : filename.substring(extensionIndex + 1);
          const isValidExtension = allowedTypes.includes(fileExtension);
          setIsValid(isValidExtension);
          return isValidExtension;
        }
        setIsValid(true);
        return true;
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const isActive = canDrop && isOver;
  return (
    <>
      <Box
        ref={drop}
        bgColor={isActive ? 'blue.300' : 'neutral.500'}
        display="flex"
        justifyContent="center"
        borderRadius={6}
        mt={3}
        height={height}
        width={width}
        sx={{
          position: 'relative',
        }}
      >
        {!isActive ? (
          <VStack spacing={3} justifyContent="center">
            <Icon as={UploadFill} />
            <Text color="neutral.900">{instructionsText}</Text>
            <Text color="neutral.900"> or </Text>
            {children}
          </VStack>
        ) : (
          <Box paddingTop={10} paddingBottom={4} h="100px" justifyContent="center" mt={3}>
            <HStack spacing={2}>
              <Icon as={AddCircleLine} />
              <Text color="neutral.900">Drag a file here...</Text>
            </HStack>
          </Box>
        )}
      </Box>
      {!isValid && <Text color="#f74a3f">Please drag and drop files with {allowedFileExtensions} extension.</Text>}
    </>
  );
};
