import { Icon, VStack, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as AlbumFill } from 'remixicon/icons/Media/album-fill.svg';

export const NoTracks = () => {
  const iconColor = useColorModeValue('neutral.300', 'neutral.600');
  const messageColor = useColorModeValue('neutral.900', 'neutral.100');

  return (
    <VStack mt={20}>
      <Icon as={AlbumFill} width="225px" height="225px" fill={iconColor} />
      <Text fontSize="18px" fontWeight="400" textColor={messageColor}>
        Add your first track by clicking on &quot;Add Track&quot; button.
      </Text>
    </VStack>
  );
};
