import { Territories, Territory, TerritoryDto } from '../../types';

export const toTerritoryDto = (territory: Territory): TerritoryDto => {
  const { id, text, grouping } = territory;
  return { id, text, grouping };
};

export const toTerritories = (territories: Territories, item: TerritoryDto): Territories => {
  const { grouping } = item;
  return {
    ...territories,
    Worldwide: [...(territories.Worldwide ?? []), item],
    [grouping]: [...(territories[grouping] ?? []), item],
  } as Territories;
};
