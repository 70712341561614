import { useCallback } from 'react';

import { useToast, UseToastOptions } from '@chakra-ui/react';
import { Code, ConnectError } from '@connectrpc/connect';
import { datadogRum } from '@datadog/browser-rum';

export const useErrorHandler = () => {
  const toast = useToast();
  const handleInternalServerError = useCallback(
    (
      error: unknown,
      title: string,
      description: string,
      requestBody: unknown = undefined,
      status: UseToastOptions['status'] = 'warning'
    ) => {
      const toastMessage = {
        title: '',
        description: '',
      };
      if (error instanceof ConnectError) {
        if ([Code.Internal, Code.Unknown].includes(error.code)) {
          toastMessage.title = title;
          toastMessage.description = description;
        } else if (error.code === Code.PermissionDenied) {
          toastMessage.title = 'Permission Denied';
          toastMessage.description = 'You dont seem to have permissions to do this';
        } else if ([Code.Unimplemented, Code.ResourceExhausted, Code.Aborted, Code.Canceled].includes(error.code)) {
          toastMessage.title = 'Please try again';
          toastMessage.description = 'Something went wrong';
        } else {
          toastMessage.title = 'Please try again';
          toastMessage.description = 'Oops, Something went wrong';
        }

        datadogRum.addError(error, {
          title,
          description,
          requestBody,
        });

        toast({
          ...toastMessage,
          status,
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  return {
    handleInternalServerError,
  };
};
