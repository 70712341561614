import { useEffect, useRef, useState } from 'react';

import { Text, Tooltip } from '@chakra-ui/react';

export const TruncatedText = ({
  children,
  ...props
}: { children: React.ReactNode } & React.ComponentProps<typeof Text>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;
      setIsOverflown(element.scrollWidth > element.clientWidth);
    }
  }, []);

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Text position="relative" isTruncated ref={ref} {...props}>
        {children}
      </Text>
    </Tooltip>
  );
};
