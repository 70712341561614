import { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Spacer,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDown } from 'remixicon/icons/Arrows/arrow-down-s-line.svg';
import { ReactComponent as ArrowUp } from 'remixicon/icons/Arrows/arrow-up-s-line.svg';
import { ReactComponent as EditBox } from 'remixicon/icons/Design/edit-box-line.svg';

import { Territories, TerritoryGroup } from '../../../types';
import { Accordion, AlbumArt, CancelWithConfirmation, MultiTerritorySelect } from '../../components';
import { StepHeading } from '../../components/StepHeading';
import { StepTitle } from '../../components/StepTitle';
import { TrackSection } from '../../components/TrackSection';
import { useReleaseForm } from '../../context/ReleaseWizardContext';
import { getTerritories } from '../../services';

import { ReleaseBasicData, ReleaseInformation, ReleaseTracks } from './types';

type HeadingText = {
  text: string;
  step: number;
  goToStep: React.Dispatch<React.SetStateAction<number>>;
};

const SectionHeader = ({ text, step, goToStep }: HeadingText) => {
  const navigate = useNavigate();
  const onUpdate = useCallback(() => {
    goToStep(step);
    navigate({
      search: '?updateMode=true',
    });
  }, [goToStep, navigate, step]);

  return (
    <Flex align="center" marginTop={8}>
      <Heading as="h3" fontSize={30}>
        {text}
      </Heading>
      <Spacer />
      <Flex>
        <Button
          rightIcon={<EditBox fill="currentcolor" height="24px" width="24px" aria-label="Update" />}
          variant="unstyled"
          display="flex"
          onClick={onUpdate}
        >
          Update {text} Info
        </Button>
      </Flex>
    </Flex>
  );
};

const Basic = ({
  releaseTitle,
  titleVersion,
  genre,
  subGenre,
  language,
  primaryArtist,
  variousArtist,
  artistDetails,
  albumArtSrc = '',
}: ReleaseBasicData) => {
  return (
    <Flex>
      <Box width={200} marginTop={8} marginRight={3}>
        {albumArtSrc ? (
          <AlbumArt
            src={albumArtSrc}
            onClose={() => {}}
            closeBtnSx={{
              display: 'none',
            }}
          />
        ) : (
          <Text>No album art</Text>
        )}
        {/* SOMEDAY: Show a file name here. See the sketch in Figma */}
      </Box>
      <Box w={200}>
        <StepTitle value="Release Title" />
        {releaseTitle}
        <StepTitle value="Artist" />
        {variousArtist ? 'Various Artists' : primaryArtist}
        {!variousArtist &&
          artistDetails.map((artistDetail, index) => {
            const postfix: string = index !== artistDetails.length - 1 ? ', ' : '';
            return (
              <p key={index}>
                {artistDetail.artist}
                {artistDetail.artistRole && <span> ({artistDetail.artistRole?.toLowerCase()})</span>}
                {postfix}
              </p>
            );
          })}
        <StepTitle value="Language" />
        {language || '-'}
      </Box>
      <Box w={200}>
        <StepTitle value="Title Version" />
        {titleVersion || '-'}
        <StepTitle value="Genre" />
        {genre} {subGenre && <span>/ {subGenre}</span>}
      </Box>
    </Flex>
  );
};

const Release = ({
  releaseDate,
  originalReleaseDate,
  pLine,
  territories = [],
  cLine,
  rerelease,
}: ReleaseInformation) => {
  const [territoriesAvailable, setTerritoriesAvailable] = useState<Territories>({});
  const [territoryToCountry, setTerritoryToCountry] = useState<Record<TerritoryGroup, Array<string>>>();
  const fetchTerritories = useCallback(async () => {
    const territoriesNew = await getTerritories();
    setTerritoriesAvailable(territoriesNew);
  }, []);

  useEffect(() => {
    fetchTerritories().catch((error: Error) => {
      datadogRum.addError(error);
    });
  }, [fetchTerritories]);

  useEffect(() => {
    const territoryToCountryNew = Object.keys(territoriesAvailable).reduce((acc, ter) => {
      const countriesLabels = territoriesAvailable[ter as TerritoryGroup].map((count) => {
        return count.text;
      });
      return {
        ...acc,
        [ter]: [...countriesLabels],
      };
    }, {});
    setTerritoryToCountry(territoryToCountryNew as Record<TerritoryGroup, Array<string>>);
  }, [territoriesAvailable]);
  return (
    <>
      <Flex>
        <Box w={200}>
          <StepTitle value="Release Date" />
          {releaseDate}
          <StepTitle value="P Line" />
          {pLine || '-'}
        </Box>
        <Box w={200}>
          {rerelease && <StepTitle value="Original Release Date" />}
          {originalReleaseDate}
          <StepTitle value="C Line" />
          {cLine || '-'}
        </Box>
      </Flex>
      <StepTitle value="Territories" />
      <Box my={2}>
        <MultiTerritorySelect
          territories={territoriesAvailable}
          countriesIdsSelected={new Set<string>(territories)}
          territoryToCountry={territoryToCountry}
          setCountriesIdsSelected={() => {}}
          review
        />
      </Box>
    </>
  );
};

const Tracks = ({ discs }: ReleaseTracks) => {
  return (
    <Box>
      {discs?.map((disc, index) => (
        <Box key={index}>
          <Text casing="uppercase" as="h2" fontSize="xs" mb={2} mt={10}>
            Disc {index + 1}
          </Text>
          {disc.tracks.map((track, trackIndex) => (
            <Accordion
              defaultIsOpen={false}
              key={`${track.isrc}-${track.genre}-${track.subGenre}`}
              renderHeader={(isOpen, onToggle) => {
                return (
                  <Grid
                    mt={2}
                    templateColumns="repeat(1,20% 40% 30% 5%)"
                    gap={2}
                    pl={4}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <GridItem fontSize="60px">{trackIndex + 1}</GridItem>
                    <GridItem>
                      <Box display="flex" flexDirection="column">
                        <Text fontSize="20px">
                          {track.titleLanguages.map((titleLanguage) => titleLanguage.trackTitle || '-')}
                        </Text>
                        <Text fontSize="16px">{track.pLineOwner || '-'}</Text>
                      </Box>
                    </GridItem>
                    <GridItem>
                      <Box display="flex" flexDirection="column">
                        <Text fontSize="12px">ISRC</Text>
                        <Text fontSize="16px">{track.isrc || '-'}</Text>
                      </Box>
                    </GridItem>
                    <GridItem justifySelf="flex-end">
                      <IconButton
                        aria-label="alert"
                        variant="unstyled"
                        icon={
                          isOpen ? (
                            <ArrowUp fill="currentcolor" height="24px" width="24px" />
                          ) : (
                            <ArrowDown fill="currentcolor" height="24px" width="24px" />
                          )
                        }
                        onClick={onToggle}
                      />
                    </GridItem>
                  </Grid>
                );
              }}
              renderBody={() => {
                return (
                  <TrackSection>
                    <Flex>
                      <Box w={400}>
                        <StepTitle value="P Line" />
                        {track.pLine || '-'}
                        <StepTitle value="Artists" />
                        {track.artistDetails.map((artistDetail, artTrackIndex) => {
                          const postfix: string = artTrackIndex !== track.artistDetails.length - 1 ? ', ' : '';
                          return (
                            <p key={index}>
                              {artistDetail.artist}
                              {postfix}
                            </p>
                          );
                        })}
                      </Box>
                      <Box w={400}>
                        <StepTitle value="Genre " />
                        {track.genre || '-'}
                        <StepTitle value="Sub Genre" />
                        {track.subGenre || '-'}
                        <Box display="flex" flexDirection="row" marginTop={4}>
                          {track.reRecord === true ? (
                            <Tag size="lg" variant="solid" bgColor="blue.200" marginRight="2">
                              <TagLabel color="blue.800">Owned</TagLabel>
                            </Tag>
                          ) : null}
                          {track.instrumental === true ? (
                            <Tag size="lg" variant="solid" bgColor="blue.200">
                              <TagLabel color="blue.800">Instrumental</TagLabel>
                            </Tag>
                          ) : null}
                        </Box>
                      </Box>
                    </Flex>
                  </TrackSection>
                );
              }}
            />
          ))}
          {disc.tracks.length === 0 && 'No tracks found'}
        </Box>
      ))}
    </Box>
  );
};

export const Step4 = ({ goToStep }: { goToStep: React.Dispatch<React.SetStateAction<number>> }) => {
  const { step1, step2, step3 } = useReleaseForm();
  const buttonBgColor = useColorModeValue('white', 'black');

  return (
    <>
      <StepHeading text="Review" />
      <Divider marginTop={8} />
      <SectionHeader step={0} text="Basic" goToStep={goToStep} />
      <Basic {...step1} />
      <Divider marginTop={8} />
      <SectionHeader step={1} text="Release" goToStep={goToStep} />
      <Release {...step2} />
      <Divider marginTop={8} />
      <SectionHeader step={2} text="Tracks" goToStep={goToStep} />
      <Tracks {...step3} />
      <Flex align="center" marginTop={16}>
        <Flex flexGrow={1}>
          <CancelWithConfirmation variant="release" />
        </Flex>
        <Flex>
          <Button
            border="1px"
            borderColor="brand.300"
            borderStyle="solid"
            bgColor={buttonBgColor}
            color="brand.300"
            onClick={() => goToStep(2)}
            className="back-btn"
          >
            Back
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
