import { Box, Button, Icon } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ReactComponent as AddIcon } from 'remixicon/icons/System/add-line.svg';

import { useReleaseForm } from '../../context/ReleaseWizardContext';

import { FieldTrack } from './FieldTrack';
import { ReleaseTracks } from './types';

export const FieldTracks = ({ discIndex }: { discIndex: number }) => {
  const {
    step1: {
      genre: genreFromStep1,
      subGenre: subGenreFromStep1,
      language: languageFromStep1,
      artistDetails: artistDetailFromStep1,
      primaryArtist: primaryArtistFromStep1,
      variousArtist: variousArtistFromStep1,
    },
    step2: { territories = [] },
  } = useReleaseForm();
  const { control } = useFormContext<ReleaseTracks>();
  const {
    fields,
    append: addTrack,
    remove: removeTrack,
  } = useFieldArray({
    control,
    name: `discs.${discIndex}.tracks`,
  });

  return (
    <>
      {fields.map((track, trackIndex) => {
        return <FieldTrack discIndex={discIndex} removeTrack={removeTrack} trackIndex={trackIndex} key={track.id} />;
      })}
      <Box display="flex" justifyContent="center" pt={4} pb={3}>
        <Button
          leftIcon={<Icon as={AddIcon} fill="currentcolor" />}
          colorScheme="blue"
          size="sm"
          onClick={() =>
            addTrack({
              instrumental: false,
              reRecord: false,
              titleLanguages: [{ language: languageFromStep1 || '', titleVersion: '', trackTitle: '' }],
              genre: genreFromStep1 || '',
              subGenre: subGenreFromStep1 || '',
              pLine: '',
              pLineOwner: '',
              pLinePercentage: '',
              ownershipType: '',
              territories,
              isrc: '',
              artistDetails: variousArtistFromStep1 ? [] : [...artistDetailFromStep1],
              primaryArtist: variousArtistFromStep1 ? '' : primaryArtistFromStep1,
              primaryArtistRole: 'MAIN_ARTIST',
            })
          }
        >
          Add Track
        </Button>
      </Box>
    </>
  );
};
