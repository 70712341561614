import { Language } from '../types';

export const languages: Array<Language> = [
  {
    id: 1,
    alternativeId: 0,
    boolVal: false,
    text: 'Arabic',
    type: 'Language',
    isoCode: 'ara',
  },
  {
    id: 2,
    alternativeId: 0,
    boolVal: false,
    text: 'Cantonese',
    type: 'Language',
    isoCode: 'chi',
  },
  {
    id: 3,
    alternativeId: 0,
    boolVal: false,
    text: 'Catalan',
    type: 'Language',
    isoCode: 'cat',
  },
  {
    id: 4,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese (Simplified) ',
    type: 'Language',
    isoCode: 'chi',
  },
  {
    id: 5,
    alternativeId: 0,
    boolVal: false,
    text: 'Chinese (Traditional)',
    type: 'Language',
    isoCode: 'chi',
  },
  {
    id: 6,
    alternativeId: 0,
    boolVal: false,
    text: 'Croatian',
    type: 'Language',
    isoCode: 'hrv',
  },
  {
    id: 7,
    alternativeId: 0,
    boolVal: false,
    text: 'Czech',
    type: 'Language',
    isoCode: 'cze',
  },
  {
    id: 8,
    alternativeId: 0,
    boolVal: false,
    text: 'Danish',
    type: 'Language',
    isoCode: 'dan',
  },
  {
    id: 9,
    alternativeId: 0,
    boolVal: false,
    text: 'Dutch',
    type: 'Language',
    isoCode: 'dut',
  },
  {
    id: 10,
    alternativeId: 0,
    boolVal: false,
    text: 'English',
    type: 'Language',
    isoCode: 'eng',
  },
  {
    id: 11,
    alternativeId: 0,
    boolVal: false,
    text: 'Estonian',
    type: 'Language',
    isoCode: 'est',
  },
  {
    id: 12,
    alternativeId: 0,
    boolVal: false,
    text: 'Finnish',
    type: 'Language',
    isoCode: 'fin',
  },
  {
    id: 13,
    alternativeId: 0,
    boolVal: false,
    text: 'French',
    type: 'Language',
    isoCode: 'fre',
  },
  {
    id: 14,
    alternativeId: 0,
    boolVal: false,
    text: 'German',
    type: 'Language',
    isoCode: 'ger',
  },
  {
    id: 15,
    alternativeId: 0,
    boolVal: false,
    text: 'Greek',
    type: 'Language',
    isoCode: 'gre',
  },
  {
    id: 16,
    alternativeId: 0,
    boolVal: false,
    text: 'Hebrew',
    type: 'Language',
    isoCode: 'heb',
  },
  {
    id: 17,
    alternativeId: 0,
    boolVal: false,
    text: 'Hungarian',
    type: 'Language',
    isoCode: 'hun',
  },
  {
    id: 18,
    alternativeId: 0,
    boolVal: false,
    text: 'Icelandic',
    type: 'Language',
    isoCode: 'ice',
  },
  {
    id: 19,
    alternativeId: 0,
    boolVal: false,
    text: 'Indonesian',
    type: 'Language',
    isoCode: 'ind',
  },
  {
    id: 20,
    alternativeId: 0,
    boolVal: false,
    text: 'Italian',
    type: 'Language',
    isoCode: 'ita',
  },
  {
    id: 21,
    alternativeId: 0,
    boolVal: false,
    text: 'Japanese',
    type: 'Language',
    isoCode: 'jpn',
  },
  {
    id: 22,
    alternativeId: 0,
    boolVal: false,
    text: 'Korean',
    type: 'Language',
    isoCode: 'kor',
  },
  {
    id: 23,
    alternativeId: 0,
    boolVal: false,
    text: 'Lao',
    type: 'Language',
    isoCode: 'lao',
  },
  {
    id: 24,
    alternativeId: 0,
    boolVal: false,
    text: 'Latvian',
    type: 'Language',
    isoCode: 'lav',
  },
  {
    id: 25,
    alternativeId: 0,
    boolVal: false,
    text: 'Lithuanian',
    type: 'Language',
    isoCode: 'lit',
  },
  {
    id: 26,
    alternativeId: 0,
    boolVal: false,
    text: 'Malay',
    type: 'Language',
    isoCode: 'may',
  },
  {
    id: 27,
    alternativeId: 0,
    boolVal: false,
    text: 'Norwegian',
    type: 'Language',
    isoCode: 'nor',
  },
  {
    id: 28,
    alternativeId: 0,
    boolVal: false,
    text: 'Polish',
    type: 'Language',
    isoCode: 'pol',
  },
  {
    id: 29,
    alternativeId: 0,
    boolVal: false,
    text: 'Portuguese',
    type: 'Language',
    isoCode: 'por',
  },
  {
    id: 30,
    alternativeId: 0,
    boolVal: false,
    text: 'Romanian',
    type: 'Language',
    isoCode: 'rum',
  },
  {
    id: 31,
    alternativeId: 0,
    boolVal: false,
    text: 'Russian',
    type: 'Language',
    isoCode: 'rus',
  },
  {
    id: 32,
    alternativeId: 0,
    boolVal: false,
    text: 'Slovak',
    type: 'Language',
    isoCode: 'slo',
  },
  {
    id: 33,
    alternativeId: 0,
    boolVal: false,
    text: 'Spanish',
    type: 'Language',
    isoCode: 'spa',
  },
  {
    id: 34,
    alternativeId: 0,
    boolVal: false,
    text: 'Swedish',
    type: 'Language',
    isoCode: 'swe',
  },
  {
    id: 35,
    alternativeId: 0,
    boolVal: false,
    text: 'Tagalog',
    type: 'Language',
    isoCode: 'tgl',
  },
  {
    id: 36,
    alternativeId: 0,
    boolVal: false,
    text: 'Thai',
    type: 'Language',
    isoCode: 'tha',
  },
  {
    id: 37,
    alternativeId: 0,
    boolVal: false,
    text: 'Turkish',
    type: 'Language',
    isoCode: 'tur',
  },
  {
    id: 38,
    alternativeId: 0,
    boolVal: false,
    text: 'Ukrainian',
    type: 'Language',
    isoCode: 'ukr',
  },
  {
    id: 39,
    alternativeId: 0,
    boolVal: false,
    text: 'Vietnamese',
    type: 'Language',
    isoCode: 'vie',
  },
  {
    id: 40,
    alternativeId: 0,
    boolVal: false,
    text: 'Afrikaans',
    type: 'Language',
    isoCode: 'afr',
  },
  {
    id: 41,
    alternativeId: 0,
    boolVal: false,
    text: 'Bengali',
    type: 'Language',
    isoCode: 'ben',
  },
  {
    id: 42,
    alternativeId: 0,
    boolVal: false,
    text: 'Bulgarian',
    type: 'Language',
    isoCode: 'bul',
  },
  {
    id: 43,
    alternativeId: 0,
    boolVal: false,
    text: 'Haitian',
    type: 'Language',
    isoCode: 'hat',
  },
  {
    id: 44,
    alternativeId: 0,
    boolVal: false,
    text: 'Hindi',
    type: 'Language',
    isoCode: 'hin',
  },
  {
    id: 45,
    alternativeId: 0,
    boolVal: false,
    text: 'Irish',
    type: 'Language',
    isoCode: 'gle',
  },
  {
    id: 46,
    alternativeId: 0,
    boolVal: false,
    text: 'Kazakh',
    type: 'Language',
    isoCode: 'kaz',
  },
  {
    id: 47,
    alternativeId: 0,
    boolVal: false,
    text: 'Latin',
    type: 'Language',
    isoCode: 'lat',
  },
  {
    id: 48,
    alternativeId: 0,
    boolVal: false,
    text: 'Persian',
    type: 'Language',
    isoCode: 'per',
  },
  {
    id: 49,
    alternativeId: 0,
    boolVal: false,
    text: 'Punjabi',
    type: 'Language',
    isoCode: 'pan',
  },
  {
    id: 50,
    alternativeId: 0,
    boolVal: false,
    text: 'Sanskrit',
    type: 'Language',
    isoCode: 'san',
  },
  {
    id: 51,
    alternativeId: 0,
    boolVal: false,
    text: 'Slovenian',
    type: 'Language',
    isoCode: 'slo',
  },
  {
    id: 52,
    alternativeId: 0,
    boolVal: false,
    text: 'Spanish (Latin America)*',
    type: 'Language',
    isoCode: 'spa',
  },
  {
    id: 53,
    alternativeId: 0,
    boolVal: false,
    text: 'Tamil',
    type: 'Language',
    isoCode: 'tam',
  },
  {
    id: 54,
    alternativeId: 0,
    boolVal: false,
    text: 'Telugu',
    type: 'Language',
    isoCode: 'tel',
  },
  {
    id: 55,
    alternativeId: 0,
    boolVal: false,
    text: 'Urdu',
    type: 'Language',
    isoCode: 'urd',
  },
  {
    id: 56,
    alternativeId: 0,
    boolVal: false,
    text: 'Zulu',
    type: 'Language',
    isoCode: 'zul',
  },
  {
    id: 58,
    alternativeId: 0,
    boolVal: false,
    text: 'German (Austria)',
    type: 'Language',
    isoCode: 'ger',
  },
  {
    id: 59,
    alternativeId: 0,
    boolVal: false,
    text: 'German (Switzerland)',
    type: 'Language',
    isoCode: 'ger',
  },
];
